import React from 'react';
import { format } from 'date-fns';
import styles from './HistoryItem.module.css';
import { LogItem } from '../../../../../interfaces/LogItem';
import { renderLogText } from '../../../../../utils/helpers/renderLogText';
import { useAppDispatch } from '../../../../../redux/store';
import { photoZoomActions } from '../../../../../redux/photoZoomer/slice';

type componentProps = {
  logs: LogItem[]
}

export const HistoryItem = ({ logs }: componentProps) => {
    const dispatch = useAppDispatch();
    const attachments = logs.map((i) => i.attachments).flat();

    const handleZoom = (src: string) => {
        dispatch(photoZoomActions.setPhotoInZoom(src));
    };

    return (
        <div className={styles.wrapper}>
            <span className={styles.date}>{format(Date.parse(logs[0].createdAt), 'dd.MM.yyyy HH:mm:ss')}</span>
            <span className={styles.name}>{`${logs[0].author.fullName} (${logs[0].author.position.name})`}</span>
            {
                logs.map((i) => (
                    <div key={i.id}>
                        <span className={styles.action}>
                            {renderLogText(i)}
                        </span>
                    </div>
                ))
            }
            {attachments.length > 0
                    && (
                        <ul className={styles.images}>
                            {attachments.map((a) => (
                                <li className={styles.imageItem} key={a.id}>
                                    <img
                                        onClick={() => handleZoom(a.url)}
                                        className={styles.image}
                                        src={a.url}
                                        alt={a.id}
                                    />
                                </li>
                            ))}
                        </ul>
                    )}
        </div>
    );
};
