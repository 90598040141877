import React from 'react';
import { Autocomplete, TextField } from '@mui/material';
import { AutoCompleteMenuProps, OptionType } from './AutoCompleteMenu.props';

export const AutoCompleteMenu: React.FC<AutoCompleteMenuProps> = ({
    options, setter, label, isAddress = false, value,
}) => {
    const completeProps = {
        options,
        getOptionLabel: (option: any) => (isAddress ? `${`${option.street.prefixName} ${option.street.name}`}, ${option.prefixName} ${option.name}` : option?.name || ''),
    };
    const onChange = (event: any, newValue: OptionType | null) => setter(newValue);

    return isAddress ? (
        <Autocomplete
            value={value}
            {...completeProps}
            disablePortal
            id="autocomplete-menu"
            onChange={onChange}
            renderInput={(params) => <TextField {...params} label={label} />}
        />
    ) : (
        <Autocomplete
            value={value}
            {...completeProps}
            disablePortal
            id="autocomplete-menu"
            onChange={onChange}
            renderInput={(params) => <TextField {...params} label={label} />}
        />
    );
};
