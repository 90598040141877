import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { initialStateI } from './types';
import { StatusEnum } from '../../interfaces/StatusEnum';
import {
    createRule, getRules, deleteRule,
} from './asyncThunks';

const initialState: initialStateI = {
    rule: {
        id: null,
        categoryId: null,
        departmentId: null,
    },
    rules: [],
    status: null,
    errors: [],
    formErrors: [],
    isLoading: false,
    ruleIdToDelete: null,
};

export const ruleSlice = createSlice({
    name: 'ruleSlice',
    initialState,
    reducers: {
        setIdToDelete(state, action) {
            state.ruleIdToDelete = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getRules.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(getRules.fulfilled, (state, action) => {
            state.rules = action.payload;
            state.errors = [];
            state.isLoading = false;
            state.status = null;
        });
        builder.addCase(getRules.rejected, (state) => {
            state.isLoading = false;
        });
        builder.addCase(createRule.pending, (state) => {
            state.status = StatusEnum.PENDING;
            state.isLoading = true;
        });
        builder.addCase(createRule.fulfilled, (state) => {
            state.status = StatusEnum.SUCCESS;
            state.formErrors = [];
            state.isLoading = false;
        });
        builder.addCase(createRule.rejected, (state, action: PayloadAction<any>) => {
            state.status = StatusEnum.ERROR;
            state.formErrors = action.payload.errors;
            state.isLoading = false;
        });
        builder.addCase(deleteRule.pending, (state) => {
            state.status = StatusEnum.PENDING;
        });
        builder.addCase(deleteRule.fulfilled, (state) => {
            state.status = StatusEnum.SUCCESS;
            state.errors = [];
        });
        builder.addCase(deleteRule.rejected, (state, action: PayloadAction<any>) => {
            state.status = StatusEnum.ERROR;
            state.errors = action.payload.errors;
        });
    },
});

export const { reducer: ruleReducer, actions: ruleActions } = ruleSlice;
