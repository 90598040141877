import React from 'react';
import { useNavigate } from 'react-router-dom';
import DeleteIcon from '@mui/icons-material/Delete';
import { AssignmentRuleProps } from './AssignmentRule.props';
import { useAppDispatch } from '../../../../redux/store';
import { directoryActions } from '../../../../redux/directories/slice';
import { ruleActions } from '../../../../redux/assignmentRules/slice';
import styles from '../DirectoryItem/DirectoryItem.module.css';
import { RedButton } from '../../Buttons/RedButton';

export const AssignmentRule: React.FC<AssignmentRuleProps> = ({ rule }) => {
    const dispatch = useAppDispatch();
    // const navigate = useNavigate();
    const handleDeleteButtonClick = (id?: string | null) => {
        dispatch(directoryActions.setIsModalOpen());
        dispatch(ruleActions.setIdToDelete(id));
    };

    // const handleEditButtonClick = (id? : string | null) => {
    //     if (id) {
    //         navigate(`${id}/update`);
    //     }
    // };

    return (
        <li className={styles.item} style={{ gridTemplateColumns: 'repeat(3, 1fr)' }}>
            <span className={styles.name}>{rule?.category.name || ''}</span>
            <span className={styles.name}>{rule?.department.name || ''}</span>
            <div className={styles.buttonWrapper}>
                {/* <EditButton onClick={() => handleEditButtonClick(item?.id)} /> */}
                <RedButton onClick={() => handleDeleteButtonClick(rule?.id)}>
                    <DeleteIcon fontSize="small" />
                </RedButton>
            </div>
        </li>
    );
};
