import React from 'react';
import { useNavigate } from 'react-router-dom';
import cn from 'classnames';
import { TicketProps } from './Ticket.props';
import styles from './Ticket.module.css';
import { Tag } from '../../../../components/Panel/Tag';

export const Ticket: React.FC<TicketProps> = (props) => {
    const {
        number,
        status,
        appeal,
        id,
        addressString,
        priority,
        createdAt,
        plannedFinishedAt,
        isOverdue,
    } = props;
    const navigate = useNavigate();
    const onClickNavigate = () => {
        navigate(id);
    };

    return (
        <>
            <li className={styles.ticket} onClick={onClickNavigate}>
                <div className={styles.number}>
                    {number}
                    {isOverdue && <Tag color="#CF0627" text="Просрочено" />}
                </div>
                <span className={styles.date}>{createdAt}</span>
                <span className={cn(styles.date, styles.justifyEnd)}>{plannedFinishedAt}</span>
                <span className={styles.priority}>
                    <p className={styles.statusName} style={{ backgroundColor: priority.backgroundColor, color: priority.textColor }}>{priority.name}</p>
                </span>
                <span className={styles.status}>
                    <p className={styles.statusName} style={{ backgroundColor: status.backgroundColor, color: status.textColor }}>{status.name}</p>
                </span>
                <span className={styles.appeal}>{appeal}</span>
                <span className={styles.address}>
                    {addressString}
                </span>

            </li>
        </>
    );
};
