import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { initialStateI } from './types';
import { StatusEnum } from '../../interfaces/StatusEnum';
import {
    createDepartment, deleteDepartment, getDepartmentById,
    getDepartments, updateDepartment,
} from './asyncThunks';

const initialState: initialStateI = {
    department: {
        parentId: null,
        name: null,
        ticketNumberPrefix: null,
        text: null,
        id: null,
    },
    departments: [],
    status: null,
    errors: [],
    formErrors: [],
    isLoading: false,
    departmentIdToDelete: null,
};

export const departmentSlice = createSlice({
    name: 'departmentSlice',
    initialState,
    reducers: {
        setIdToDelete(state, action) {
            state.departmentIdToDelete = action.payload;
        },
        resetDepartment(state) {
            state.department.id = null;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getDepartments.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(getDepartments.fulfilled, (state, action) => {
            state.departments = action.payload;
            state.errors = [];
            state.isLoading = false;
            state.status = null;
        });
        builder.addCase(getDepartments.rejected, (state) => {
            state.isLoading = false;
        });
        builder.addCase(createDepartment.pending, (state) => {
            state.status = StatusEnum.PENDING;
            state.isLoading = true;
        });
        builder.addCase(createDepartment.fulfilled, (state) => {
            state.status = StatusEnum.SUCCESS;
            state.formErrors = [];
            state.isLoading = false;
        });
        builder.addCase(createDepartment.rejected, (state, action: PayloadAction<any>) => {
            state.status = StatusEnum.ERROR;
            state.formErrors = action.payload.errors;
            state.isLoading = false;
        });
        builder.addCase(updateDepartment.pending, (state) => {
            state.status = StatusEnum.PENDING;
            state.isLoading = true;
        });
        builder.addCase(updateDepartment.fulfilled, (state, action) => {
            state.status = StatusEnum.SUCCESS;
            state.department = action.payload;
            state.isLoading = false;
            state.formErrors = [];
        });
        builder.addCase(updateDepartment.rejected, (state, action: PayloadAction<any>) => {
            state.status = StatusEnum.ERROR;
            state.isLoading = false;
            state.formErrors = action.payload.errors;
        });
        builder.addCase(getDepartmentById.fulfilled, (state, action: PayloadAction<any>) => {
            state.department = action.payload;
        });
        builder.addCase(getDepartmentById.rejected, (state) => {
            state.status = StatusEnum.ERROR;
        });
        builder.addCase(deleteDepartment.pending, (state) => {
            state.status = StatusEnum.PENDING;
        });
        builder.addCase(deleteDepartment.fulfilled, (state, action: PayloadAction<any>) => {
            state.status = StatusEnum.SUCCESS;
            state.department = action.payload;
            state.errors = [];
        });
        builder.addCase(deleteDepartment.rejected, (state, action: PayloadAction<any>) => {
            state.status = StatusEnum.ERROR;
            state.errors = action.payload.errors;
        });
    },
});

export const { reducer: departmentReducer, actions: departmentActions } = departmentSlice;
