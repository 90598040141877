import React, {
    FormEvent, useEffect, useLayoutEffect, useState,
} from 'react';
import { Modal, ModalClose, ModalDialog } from '@mui/joy';
import Box from '@mui/material/Box';
import { useSelector } from 'react-redux';
import { ButtonPrimary } from '../Buttons/ButtonPrimary';
import { useAppDispatch } from '../../../redux/store';
import { changeExecutor, getAvailableExecutors } from '../../../redux/tickets/asyncThunks';
import {
    getStatusSelector,
    getAvailableExecutorsSeletor, getIsLoadingSelector,
} from '../../../redux/tickets/selectors';
import { StatusEnum } from '../../../interfaces/StatusEnum';
import { DropDownMenu } from '../DropDownMenu';
import { ExecutorI } from '../../../interfaces/Executor';

type componentProps = {
    isOpen: boolean;
    onClose: () => void;
    ticketId: string;
    currentExecutor: ExecutorI | null;
}

export const ModalEditExecutor = ({
    isOpen, onClose, ticketId, currentExecutor,
}: componentProps) => {
    const dispatch = useAppDispatch();
    const availableExecutors = useSelector(getAvailableExecutorsSeletor);
    const [executorId, setExecutorId] = useState<string>();
    const status = useSelector(getStatusSelector);
    const isLoading = useSelector(getIsLoadingSelector);

    useLayoutEffect(() => {
        dispatch(getAvailableExecutors(ticketId));
        setExecutorId(currentExecutor?.id);
    }, []);

    useEffect(() => {
        if (status === StatusEnum.SUCCESS) {
            onClose();
        }
    }, [status]);

    const handleCloseModal = () => {
        onClose();
    };

    const handleSubmit = (e: FormEvent) => {
        e.preventDefault();
        if (executorId) {
            dispatch(changeExecutor({ id: ticketId, executorId }));
        }
        handleCloseModal();
    };

    const prepareDropDownData = (items: ExecutorI[]) => items.map((i) => ({ id: i.id, name: i.fullName }));

    return (
        <Modal open={isOpen} onClose={handleCloseModal}>
            <ModalDialog sx={{ backgroundColor: 'white' }}>
                <ModalClose sx={{ top: -40, right: -20 }} />
                <Box
                    component="form"
                    onSubmit={handleSubmit}
                    className="form"
                    noValidate
                >
                    <DropDownMenu
                        value={executorId ?? ''}
                        handleChange={(e) => setExecutorId(e.target.value)}
                        items={prepareDropDownData(availableExecutors)}
                        label="Исполнитель"
                    />
                    <ButtonPrimary isLoading={isLoading} isEditing />
                </Box>
            </ModalDialog>
        </Modal>
    );
};
