import React from 'react';
import { Tag } from '../../../../components/Client/ui/Tag';
import { MetersDataProps } from './MetersData.props';
import { Button } from '../../../../components/Client/ui/Button';
import { ReactComponent as MetersDataEmpty } from '../../../../assets/img/meters/metersDataEmpty.svg';
import { ReactComponent as BellIcon } from '../../../../assets/img/home/bell.svg';
import styles from './MetersData.module.css';

export const MetersData: React.FC<MetersDataProps> = ({ isSent = false }) => (
    <div className={styles.wrapper}>
        <h2 className={styles.title}>Показания за октябрь</h2>
        <div className={styles.tagsWrapper}>
            {isSent
                ? <Tag color="green">Показания отправлены</Tag>
                : <Tag color="green">Отправьте показания до 24 октября</Tag>}
        </div>
        {isSent
            ? <MetersDataEmpty className={styles.metersDataEmpty} />
            : (
                <>
                    <div className={styles.textWrapper}>
                        <div className={styles.bellIconWrapper}>
                            <BellIcon />
                        </div>
                        <p className={styles.text}>Если вы не отправите показания вовремя, вам выставят счета за следующий месяц по средним показателям</p>
                    </div>
                    <Button appearance="primary" className={styles.button}>Отправить показания</Button>
                </>
            ) }
    </div>
);
