import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from '../../../../redux/store';
import { categoryActions } from '../../../../redux/categories/slice';
import { getCategoryById } from '../../../../redux/categories/asyncThunks';
import { directoryActions } from '../../../../redux/directories/slice';
import { DirectoryItem } from '../DirectoryItem';
import { CategoryProps } from './Category.props';

export const Category: React.FC<CategoryProps> = ({ category }) => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const handleDeleteButtonClick = (id?: string | null) => {
        dispatch(directoryActions.setIsModalOpen());
        dispatch(categoryActions.setIdToDelete(id));
    };

    const handleEditButtonClick = (id?: string | null) => {
        if (id) {
            navigate(`${id}/update`);
        }
    };

    return (
        <DirectoryItem
            item={category}
            handleEditButtonClick={handleEditButtonClick}
            handleDeleteButtonClick={handleDeleteButtonClick}
        />
    );
};
