import * as React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { SyntheticEvent } from 'react';

interface MultipleAutoCompleteProps {
  items: {id: string, addressString: string}[];
  value: {id: string, addressString: string}[];
  handleChange: (_: SyntheticEvent<Element, Event>, newSelectedItems: {id: string, addressString: string}[]) => void;
  required?: boolean;
}

export const MultipleAutoComplete = ({
    items, value, handleChange, required,
}: MultipleAutoCompleteProps) => (
    <Autocomplete
        multiple
        id="checkboxes-tags-demo"
        options={items}
        disableCloseOnSelect
        getOptionLabel={(option) => option.addressString || ''}
        value={value}
        onChange={handleChange}
        renderOption={(props, option) => (
            <li {...props}>
                {option.addressString}
            </li>
        )}
        renderInput={(params) => (
            <TextField {...params} label="Адреса" required={required} />
        )}
    />
);
