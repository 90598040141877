import React, { useEffect, useState } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import { Sidebar } from '../../../components/Panel/Sidebar';
import { EmptyInfo } from '../../../components/Panel/EmptyDirectory/EmptyInfo';
import { employeeRouting } from '../../../utils/data/routing/employeeRouting';
import styles from '../../../utils/data/Directories.module.css';

export const Employee = () => {
    const location = useLocation().pathname;
    const [withoutSidebar, setWithoutSidebar] = useState(false);

    useEffect(() => {
        if (window.innerWidth < 800) setWithoutSidebar(true);
    }, []);
    return (
        <div className={styles.wrapper}>
            {!withoutSidebar && <Sidebar location="employee" />}
            <main className={styles.main}>
                { location === '/bo/employee'
                    ? <EmptyInfo item="directory" />
                    : employeeRouting.map((r) => (
                        <Routes key={r.path}>
                            <Route path={r.path} element={r.component} />
                            <Route path={`${r.path}/create`} element={r.form} />
                            <Route path={`${r.path}/:id/update`} element={r.form} />
                        </Routes>
                    ))}
            </main>
        </div>
    );
};
