export const docTypeToggleData = [
    {
        value: '100',
        label: 'Телефонограмма',
    },
    {
        value: '200',
        label: 'Информация',
    },
];
