import { createAsyncThunk } from '@reduxjs/toolkit';
import { DocumentResponseItem } from './types';
import axios from '../../axios';

type CreateDocumentData = {
  type: number,
  source: string,
  text: string,
  dateStart: any | null,
  dateEnd: any | null,
  status: number,
  houses: (string | undefined)[]
}

type Data = {
  id: string;
  body: CreateDocumentData;
}
export const getDocuments = createAsyncThunk<DocumentResponseItem[]>(
    'getDocuments',
    async () => {
        const res = await axios.get('ba/document');
        return res.data.data;
    },
);

export const createDocument = createAsyncThunk<DocumentResponseItem, CreateDocumentData>(
    'createDocument',
    async (body, { rejectWithValue }) => {
        try {
            const res = await axios.post('ba/document', body);
            return res.data;
        } catch (err: any) {
            return rejectWithValue(err.response.data);
        }
    },
);

export const updateDocument = createAsyncThunk<Document, Data>(
    'updateDocument',
    async ({ id, body }, { rejectWithValue }) => {
        try {
            const res = await axios.patch(`ba/document/${id}`, body);
            return res.data;
        } catch (err: any) {
            return rejectWithValue(err.response.data);
        }
    },
);

export const getDocumentById = createAsyncThunk<DocumentResponseItem, string>(
    'getDocumentById',
    async (id) => {
        const res = await axios.get(`ba/document/${id}`);
        return res.data.data;
    },
);

// export const deleteDocument = createAsyncThunk<string, string | null>(
//     'deleteDocument',
//     async (id, { dispatch, rejectWithValue }) => {
//         try {
//             const res = await axios.delete(`helpdesk/category/${id}`);
//             dispatch(getCategories());
//             return res.data;
//         } catch (err: any) {
//             return rejectWithValue(err.response.data);
//         }
//     },
// );
//
// export const updateDocument = createAsyncThunk<Category, Data>(
//     'updateDocument',
//     async ({ id, body }, { rejectWithValue }) => {
//         try {
//             const res = await axios.patch(`helpdesk/category/${id}`, body);
//             return await res.data.data;
//         } catch (err: any) {
//             return rejectWithValue(err.response.data);
//         }
//     },
// );
