import React, {
    ChangeEvent, useEffect, useLayoutEffect,
} from 'react';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import { useSelector } from 'react-redux';

import { useParams } from 'react-router-dom';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { useAppDispatch } from '../../../../redux/store';
import { ErrorsAlert } from '../../ErrorAlert';
import { ButtonPrimary } from '../../Buttons/ButtonPrimary';
import { useSuccessNavigate } from '../../../../utils/hooks/useSuccessNavigate';
import {
    getStatusSelector,
    getIsLoadingSelector,
    getRoleSelector,
    getChecksSelector,
    getFormErrorsSelector,
    getPermissionsSelector,
} from '../../../../redux/role/selectors';
import {
    createRole, getPermissions, getRoleById, updateRole,
} from '../../../../redux/role/asyncThunks';
import { Permission } from '../../../../interfaces/Permissions';
import { roleActions } from '../../../../redux/role/slice';
import { Preloader } from '../../../Client/ui/Preloader';

export const RoleForm = () => {
    const dispatch = useAppDispatch();
    const { id } = useParams();
    const isEditing = Boolean(id);
    const errors = useSelector(getFormErrorsSelector);
    const status = useSelector(getStatusSelector);
    const isLoading = useSelector(getIsLoadingSelector);
    const role = useSelector(getRoleSelector);
    const checks = useSelector(getChecksSelector);
    const permissions = useSelector(getPermissionsSelector);
    const permissionAll: Permission[] = permissions.map((i) => ({ ...i, isChecked: checks[i.name] }));
    useSuccessNavigate('/bo/employee/role', status);

    useLayoutEffect(() => {
        if (isEditing && id) {
            dispatch(getRoleById(id));
        }
        dispatch(getPermissions());
    }, []);

    useEffect(() => {
        role?.permissions?.forEach((p) => {
            dispatch(roleActions.setIsChecked({ i: p, isChecked: true }));
        });
        return () => {
            dispatch(roleActions.resetChecks());
        };
    }, [role]);

    const handleNameChange = (e: ChangeEvent<HTMLInputElement>) => {
        dispatch(roleActions.setRoleName(e.target.value));
    };

    const handleCheck = (e: ChangeEvent<HTMLInputElement>, i: number) => {
        dispatch(roleActions.setIsChecked({ i, isChecked: e.target.checked }));
    };

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const body = {
            permissions: Object.entries(checks).filter((i) => i[1]).map((i) => +i[0]),
            name: role?.name,
        };

        if (isEditing && id) {
            dispatch(updateRole({ id, body }));
        } else {
            dispatch(createRole(body));
        }
    };

    if (isEditing && !role.id) {
        return <Preloader />;
    }

    return (
        <Box
            component="form"
            onSubmit={handleSubmit}
            className="form"
            noValidate
            id="form"
        >
            {errors.length > 0 && <ErrorsAlert errors={errors} />}
            <TextField
                id="name"
                label="Имя"
                name="name"
                required
                variant="standard"
                value={role?.name || ''}
                onChange={handleNameChange}
            />
            {permissionAll.map((p) => (
                <FormControlLabel
                    key={p.name}
                    control={(
                        <Checkbox
                            color="primary"
                            checked={checks[p.value] || false}
                            onChange={(e) => handleCheck(e, p.value)}
                        />
                    )}
                    name={p.name}
                    label={p.name}
                />
            ))}
            <ButtonPrimary isLoading={isLoading} isEditing={isEditing} />
        </Box>
    );
};
