import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import styles from './Uploader.module.css';
import { UploaderProps } from './Uploader.props';
import PdfIcon from '../../../../assets/img/pdf-icon.png';

export const Uploader: React.FC<UploaderProps> = ({ files, setFiles }) => {
    const [previews, setPreviews] = useState<string[]>([]);
    const [pdfFiles, setPdfFiles] = useState<File[]>([]);
    const [imageFiles, setImageFiles] = useState<File[]>([]);

    useEffect(() => {
        setFiles([...imageFiles, ...pdfFiles]);
    }, [imageFiles, pdfFiles]);

    const handleUploadFile = (e: any) => {
        const file: File = e.target.files[0];
        if (file) {
            if (file.type === 'application/pdf') {
                setPdfFiles([...pdfFiles, file]);
            } else {
                const src = URL.createObjectURL(file);
                setImageFiles([...imageFiles, file]);
                setPreviews([...previews, src]);
            }
            const formData = new FormData();
            formData.append('file', file);
            setFiles([...files, file]);
        }
    };

    const handleDeleteImage = (index: number) => {
        const newFiles = imageFiles.filter((f, i) => i !== index);
        const newPreviews = previews.filter((p, i) => i !== index);
        setImageFiles(newFiles);
        setPreviews(newPreviews);
    };

    const handleDeletePdf = (index: number) => {
        const newFiles = pdfFiles.filter((f, i) => i !== index);
        setPdfFiles(newFiles);
    };

    return (
        <div>
            {files.length > 0 ? (
                <div className={styles.wrapper}>
                    <div className={styles.uploadButtonWrapper}>
                        <p className={styles.filesCapture}>Вложенные файлы</p>
                        <Button variant="text" component="label" sx={{ fontSize: '18px', color: 'var(--green)', textTransform: 'none' }}>
                            + Добавить файл
                            <input hidden accept=".jpg,.jpeg,.pdf" type="file" onChange={handleUploadFile} />
                        </Button>
                    </div>
                    <ul className={styles.previewList}>
                        {imageFiles.map((p, i) => (
                            <li key={p.name} className={styles.previewWrapper}>
                                <img className={styles.preview} src={previews[i]} alt="Вложенный файл" />
                                <button type="button" className={styles.deleteButton} onClick={() => handleDeleteImage(i)} />
                            </li>
                        ))}
                    </ul>
                    {
                        pdfFiles && (
                            <ul className={styles.pdfList}>
                                {pdfFiles.map((p, i) => (
                                    <li key={p.name} className={styles.pdfWrapper}>
                                        <img className={styles.pdfImage} src={PdfIcon} alt="иконка пдф" />
                                        <p className={styles.fileName}>{p.name}</p>
                                        <button type="button" className={styles.deletePdfButton} onClick={() => handleDeletePdf(i)} />
                                    </li>
                                ))}
                            </ul>
                        )
                    }
                </div>
            )
                : (
                    <Button variant="text" component="label" sx={{ fontSize: '18px', color: 'var(--green)', textTransform: 'none' }}>
                        + Загрузить файл
                        <input hidden accept=".jpg,.jpeg,.pdf" type="file" onChange={handleUploadFile} />
                    </Button>
                )}
        </div>
    );
};
