import React from 'react';
import styles from './Message.module.css';
import mockAvatar from '../../../assets/img/avatarMock.svg';

export const Message = () => (
    <div className={styles.wrapper}>
        <img className={styles.avatar} src={mockAvatar} alt="аватар" />
        <div className={styles.textWrapper}>
            <p className={styles.text}>
                Добрый день, уважаемый заявитель! Работа по Вашей заявке выполнена. Освещение восстановлено. Спасибо за обращение.
            </p>
            <span className={styles.author}>Диспетчер УК “название УК”</span>
        </div>
    </div>
);
