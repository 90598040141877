import React, { useCallback, useLayoutEffect } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from '../../../redux/store';
import { EmptyTable } from '../../../components/Panel/EmptyTable';
import { Preloader } from '../../../components/Client/ui/Preloader';
import { Document } from './Document/Document';
import {
    getDocumentsSelector,
    getErrorsSelector,
    getIdToDeleteSelector,
    getIsLoadingSelector, getStatusSelector,
} from '../../../redux/documents/selectors';
import { getDocuments } from '../../../redux/documents/asyncThunks';
import { DirectoryTopInfo } from '../../../components/Panel/DirectoryTopInfo/DirectoryTopInfo';
import css from './Documents.module.css';

const documentsInfo = {
    title: 'Документы',
    text: '',
};

const documentsHeadings = ['Имя', 'Действия'];

export const Documents: React.FC = () => {
    const dispatch = useAppDispatch();
    const isLoading = useSelector(getIsLoadingSelector);
    const idToDelete = useSelector(getIdToDeleteSelector);
    const documents = useSelector(getDocumentsSelector);
    const errors = useSelector(getErrorsSelector);
    const status = useSelector(getStatusSelector);

    useLayoutEffect(() => {
        dispatch(getDocuments());
    }, []);

    return (
        <main className="content">
            <DirectoryTopInfo title={documentsInfo.title} text={documentsInfo.text} />
            { isLoading
                ? <Preloader />
                : (
                    <ul className={css.cards}>
                        {documents.length > 0
                            ? documents.map((i) => <Document key={i.id} document={i} />)
                            : <EmptyTable />}
                    </ul>
                )}
        </main>
    );
};
