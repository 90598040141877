import React from 'react';
import { MeterItem } from '../MeterItem/MeterItem';
import { Reciept } from '../Receipt/Reciept';
import { MetersData } from '../MetersData/MetersData';
import { Button } from '../../../../components/Client/ui/Button';
import { AccountProps } from './Account.props';
import { ReactComponent as EnergyIcon } from '../../../../assets/img/meters/energy.svg';
import { ReactComponent as ColdWaterIcon } from '../../../../assets/img/meters/cold-water.svg';
import { ReactComponent as HotWaterIcon } from '../../../../assets/img/meters/hot-water.svg';
import { ReactComponent as WarmIcon } from '../../../../assets/img/meters/warm.svg';
import { ReactComponent as EnergyBgIcon } from '../../../../assets/img/meters/energy-bg.svg';
import { ReactComponent as ColdWaterBgIcon } from '../../../../assets/img/meters/cold-water-bg.svg';
import { ReactComponent as HotWaterBgIcon } from '../../../../assets/img/meters/hot-water-bg.svg';
import { ReactComponent as WarmBgIcon } from '../../../../assets/img/meters/warm-bg.svg';
import { ReactComponent as NewAccountIcon } from '../../../../assets/img/home/new-account.svg';
import styles from './Account.module.css';
import { Modal } from '../../../../components/Client/ui/Modal/Modal';
import { HomeModal } from '../../../../components/Client/HomeModal/HomeModal';
import { Input } from '../../../../components/Client/ui/Input';

export const Account: React.FC<AccountProps> = ({ isNewAccount = false }) => (
    <div className={styles.account}>
        {isNewAccount
            ? (
                <div className={styles.newAccount}>
                    <div className={styles.textWrapper}>
                        <h2 className={styles.newAccountTitle}>Новый лицевой счет</h2>
                        <p className={styles.newAccountText}>
                            Добавьте свой лицевой счет, чтобы передавать
                            показания и оплачивать коммунальные услуги
                        </p>
                        <Button appearance="primary">Добавить лицевой счет</Button>
                    </div>
                    <NewAccountIcon className={styles.newAccountIcon} />
                </div>
            )
            : (
                <>
                    <div className={styles.metersListWrapper}>
                        <ul className={styles.metersList}>
                            <MeterItem title="Электроэнергия" value="6221 квЧ" meterBgIcon={<EnergyBgIcon />} meterIcon={<EnergyIcon />} />
                            <MeterItem title="Холодная вода" value="123 м3" meterBgIcon={<ColdWaterBgIcon />} meterIcon={<ColdWaterIcon />} />
                            <MeterItem title="Горячая вода" value="123 м3" meterBgIcon={<HotWaterBgIcon />} meterIcon={<HotWaterIcon />} />
                            <MeterItem title="Тепло" value="124 м3" meterBgIcon={<WarmBgIcon />} meterIcon={<WarmIcon />} />
                        </ul>
                    </div>

                    <div className={styles.receiptWrapper}>
                        <Reciept />
                        <MetersData />
                    </div>
                </>
            )}
        {/* <Modal> */}
        {/*    <HomeModal title="Новый лицевой счет" text="Лицевой счет можно найти на вашей квитанции на оплату коммунальных услуг"> */}
        {/*        <Input className={styles.input} placeholder="Введите ваш лицевой счет" /> */}
        {/*        <p className={styles.inputCaption}>Номер лицевого счета может содержать от 4 до 14 цифр, указан в шапке квитанции.</p> */}
        {/*    </HomeModal> */}
        {/* </Modal> */}
        {/* <Modal> */}
        {/*    <HomeModal title="Добавление нового дома"> */}
        {/*        <div className={styles.inputWrapper}> */}
        {/*            <Input withHomeIcon className={styles.input} placeholder="Введите адрес дома" /> */}
        {/*            <Input withHomeIcon className={styles.input} placeholder="Введите квартиру" /> */}
        {/*        </div> */}
        {/*    </HomeModal> */}
        {/* </Modal> */}
    </div>
);
