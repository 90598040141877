import React from 'react';
import styles from './Reciept.module.css';
import { Tag } from '../../../../components/Client/ui/Tag';
import { Button } from '../../../../components/Client/ui/Button';
import { RecieptProps } from './Reciept.props';

export const Reciept: React.FC<RecieptProps> = ({ isPaid = false }) => (
    <div className={styles.wrapper}>
        <h2 className={styles.title}>Квитанция за сентябрь</h2>
        <div className={styles.tagsWrapper}>
            {isPaid
                ? <Tag color="green">Квитанция оплачена</Tag>
                : (
                    <>
                        <Tag color="red">Начисления</Tag>
                        <Tag color="red">Оплатите до 19 сентября</Tag>
                    </>
                )}

        </div>
        <div className={styles.categoriesWrapper}>
            <div className={styles.category}>
                <div>Перерасчет</div>
                <div>0, 00 ₽</div>
            </div>
            <div className={styles.category}>
                <div>Долг</div>
                <div>0, 00 ₽</div>
            </div>
            <div className={styles.category}>
                <div>Пени</div>
                <div>0, 00 ₽</div>
            </div>
        </div>
        <div className={styles.totalWrapper}>
            <div className={styles.category}>
                <div>Оплачено</div>
                <div>{isPaid ? '10 865, 93 ₽' : '0, 00 ₽'}</div>
            </div>
            <div className={styles.total}>
                <div className={styles.totalCapture}>Итого к оплате</div>
                <div className={styles.totalValue}>{isPaid ? '0 ₽' : '10 865, 93 ₽'}</div>
            </div>
        </div>
        <div className={styles.buttonsWrapper}>
            {!isPaid && <Button appearance="primary" className={styles.button}>Оплатить</Button>}
            <Button appearance="outlined" className={styles.button}>Скачать квитанцию</Button>
        </div>
    </div>
);
