import React from 'react';
import { TicketProps } from './Ticket.props';
import styles from './Ticket.module.css';
import { Badge } from '../ui/Badge';

export const Ticket: React.FC<TicketProps> = ({
    title, date, text, badgeText,
}) => (
    <li className={styles.item}>
        <div className={styles.titleWrapper}>
            <h3 className={styles.title}>{title}</h3>
            <span className={styles.date}>{date}</span>
        </div>
        <Badge text={badgeText} />
        <p className={styles.text}>{text}</p>
    </li>
);
