export enum TicketActionsEnum {
  ASSIGN_EXECUTOR = 100,
  CHANGE_PRIORITY = 200,
  CANCEL = 300,
  START = 400,
  END = 500,
  RETURN = 600,
  DONE = 700,
  DELAY = 800,
  RETURN_IN_WORK = 900,
  CHANGE_CATEGORY = 1000,
}
