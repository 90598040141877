import React, {
    useEffect, useState,
} from 'react';
import {
    Route, Routes, useLocation, useNavigate,
} from 'react-router-dom';

import { Header } from './components/Client/Header';
import { Home } from './pages/Client/Home/Home';
import { LoginAdmin } from './components/Panel/Login';
import { ProtectedRoute } from './components/ProtectedRoute';
import { Settings } from './pages/Panel/Settings';
import { TicketForm } from './pages/Panel/Ticket/Form/TicketForm';
import { Login } from './pages/Client/Login/Login';
import { News } from './pages/Client/News/News';
import './styles/global.css';
import { Tickets } from './pages/Client/Tickets/Tickets';
import { Readings } from './pages/Client/Readings/Readings';
import { Services } from './pages/Client/Services/Services';
import { TicketMap } from './pages/Panel/Ticket/TicketMap';
import { Geo } from './pages/Panel/Geo';
import { Employee } from './pages/Panel/Employee';
import { TicketPage } from './pages/Panel/Ticket';
import { TicketFull } from './pages/Panel/Ticket/TicketFull';
import { Dashboard } from './pages/Panel/Dashboard';
import { ImageZoomer } from './components/Panel/ImageZoomer';
import { Documents } from './pages/Panel/Documents/Documents';
import { DocumentForm } from './pages/Panel/Documents/DocumentForm';

function MainPageRedirect() {
    const isPanel = localStorage.getItem('isPanel') === 'true';
    const navigate = useNavigate();

    useEffect(() => {
        navigate(isPanel ? '/bo/dashboard/' : '/lk/');
    }, [isPanel]);

    return null;
}

function App() {
    const path = useLocation().pathname;
    const [key, setKey] = useState(0);

    const updateKey = () => {
        setKey((prevKey) => prevKey + 1);
    };
    return (
        <div className="app">
            {(path !== '/bo/auth/login' && path !== '/lk/sign-in') && <Header forceUpdate={updateKey} />}
            <ImageZoomer />
            <Routes>
                <Route
                    path="/"
                    element={(
                        <ProtectedRoute
                            Component={MainPageRedirect}
                        />
                    )}
                />
                <Route
                    path="/lk/*"
                    element={(
                        <ProtectedRoute
                            Component={Home}
                        />
                    )}
                />
                <Route
                    path="/lk/readings"
                    element={
                        <ProtectedRoute Component={Readings} />
                    }
                />
                <Route
                    path="/lk/news/*"
                    element={
                        <ProtectedRoute Component={News} />
                    }
                />
                <Route
                    path="/lk/ticket/*"
                    element={
                        <ProtectedRoute Component={Tickets} />
                    }
                />
                <Route
                    path="/lk/services/*"
                    element={
                        <ProtectedRoute Component={Services} />
                    }
                />
                <Route
                    path="/bo/dashboard/"
                    element={
                        <ProtectedRoute Component={Dashboard} />
                    }
                />

                <Route
                    path="/bo/ticket/"
                    element={
                        <ProtectedRoute Component={TicketPage} key={key} />
                    }
                />
                <Route
                    path="/bo/ticket/:id"
                    element={
                        <ProtectedRoute Component={TicketFull} />
                    }
                />
                <Route
                    path="/bo/ticket/create"
                    element={
                        <ProtectedRoute Component={TicketForm} />
                    }
                />
                <Route
                    path="/bo/document/"
                    element={
                        <ProtectedRoute Component={Documents} />
                    }
                />
                <Route
                    path="/bo/document/:id/update"
                    element={
                        <ProtectedRoute Component={DocumentForm} />
                    }
                />
                <Route
                    path="/bo/document/create"
                    element={
                        <ProtectedRoute Component={DocumentForm} />
                    }
                />
                <Route
                    path="/bo/geo/*"
                    element={
                        <ProtectedRoute Component={Geo} />
                    }
                />
                <Route
                    path="/bo/employee/*"
                    element={
                        <ProtectedRoute Component={Employee} />
                    }
                />
                <Route
                    path="/bo/settings/*"
                    element={
                        <ProtectedRoute Component={Settings} />
                    }
                />
                <Route
                    path="/bo/directories/*"
                    element={
                        <ProtectedRoute Component={Settings} />
                    }
                />
                <Route
                    path="/bo/ticket/map"
                    element={
                        <ProtectedRoute Component={TicketMap} />
                    }
                />
                <Route path="/bo/auth/login" element={<LoginAdmin />} />
                <Route path="isSuccessScreen" element={<Login />} />
            </Routes>
        </div>
    );
}

export default App;
