import React from 'react';
import styles from './Service.module.css';
import { ServiceProps } from './Service.props';
import { Badge } from '../ui/Badge';

export const Service: React.FC<ServiceProps> = ({ title }) => (
    <li className={styles.item}>
        <div className={styles.topInfo}>
            <div className={styles.textWrapper}>
                <h3 className={styles.title}>{title}</h3>
                <p className={styles.text}>Оформите онлайн заявку</p>
            </div>
            <Badge text="Быстро" isWithIcon />
        </div>
        <div className={styles.priceWrapper}>
            <span className={styles.priceCaption}>Фиксированная цена</span>
            <span className={styles.price}>от 1 500 ₽</span>
        </div>
    </li>
);
