import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../../axios';
import { CustomerDataI, CustomerI } from '../../interfaces/Tickets';

export const getCustomerByPhone = createAsyncThunk<{
  id: string,
  fullName: string,
} | null, string>(
    'getCustomerByPhone',
    async (phone, { rejectWithValue }) => {
        try {
            const res = await axios.get('ba/customer/by-phone', {
                params: {
                    phone,
                },
            });
            return res.data.data;
        } catch (err: any) {
            return rejectWithValue(err.response.data);
        }
    },
);

export const createCustomer = createAsyncThunk<CustomerI, CustomerDataI>(
    'createCustomer',
    async (body, { rejectWithValue }) => {
        try {
            const res = await axios.post('ba/customer', body);
            return res.data.data;
        } catch (err: any) {
            return rejectWithValue(err.response.data);
        }
    },
);

type UpdateCustomerData = {
  body: CustomerDataI,
  id: string,
}

export const updateCustomer = createAsyncThunk<CustomerI, UpdateCustomerData>(
    'updateCustomer',
    async ({ id, body }, { rejectWithValue }) => {
        try {
            const res = await axios.patch(`ba/customer/${id}`, body);
            return res.data.data;
        } catch (err: any) {
            return rejectWithValue(err.response.data);
        }
    },
);
