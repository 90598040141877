export const crumbRoutes = [
    {
        path: '/lk',
        breadcrumb: 'Главная',
    },
    {
        path: '/lk/news',
        breadcrumb: 'Новости',
    },
    {
        path: 'lk/news/FullNews',
        breadcrumb: 'Уведомление о проведении профилактических работ',
    },
    {
        path: '/lk/profile',
        breadcrumb: 'Профиль',
    },
    {
        path: 'lk/ticket',
        breadcrumb: 'Заявки',
    },
    {
        path: 'lk/ticket/fullTicket',
        breadcrumb: 'Уже очень долго не работает лифт в 3 подъезде',
    },
];
