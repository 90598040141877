import { createSlice } from '@reduxjs/toolkit';

import { initialStateI } from './types';

const initialState: initialStateI = {
    isModalOpen: false,
};

export const directorySlice = createSlice({
    name: 'directories',
    initialState,
    reducers: {
        setIsModalOpen(state) {
            state.isModalOpen = true;
        },
        setIsModalClose(state) {
            state.isModalOpen = false;
        },
    },
});

export const { reducer: directoryReducer, actions: directoryActions } = directorySlice;
