import React, { useState } from 'react';
import cn from 'classnames';
import { AddressItemProps } from './AddressItem.props';
import { ReactComponent as MoreIcon } from '../../../assets/img/moreIcon.svg';
import { ReactComponent as CrossIcon } from '../../../assets/img/cross-icon.svg';
import styles from './AddressItem.module.css';

export const AddressItem: React.FC<AddressItemProps> = ({ address }) => {
    const [isExtended, setIsExtended] = useState(false);

    const handleExtend = () => {
        setIsExtended((prevState) => !prevState);
    };

    return (
        <li className={styles.wrapper} onClick={handleExtend}>
            <div className={cn(styles.addressWrapper, {
                [styles.extended]: isExtended,
            })}
            >
                <div className={styles.addressItem}>
                    <p className={styles.address}>{address}</p>
                    <button>
                        <MoreIcon className={cn(styles.moreIcon, {
                            [styles.reverse]: isExtended,
                        })}
                        />
                    </button>
                </div>
                { isExtended && (
                    <div className={styles.extendedWrapper}>
                        <div>
                            <div className={styles.table}>
                                <div className={styles.tableItem}>
                                    <p className={styles.meter}>Прибор учета</p>
                                    <p className={styles.numberWrapper}>Номер счетчика</p>
                                </div>
                                <div className={styles.tableItem}>
                                    <p className={styles.meter}>Счетчик № 1</p>
                                    <div className={styles.numberWrapper}>
                                        <p className={styles.metersNumber}>1233</p>
                                        <p className={styles.metersNumber}>123123312</p>
                                    </div>
                                </div>
                                <div className={styles.tableItem}>
                                    <p className={styles.meter}>Счетчик № 2</p>
                                    <div className={styles.numberWrapper}>
                                        <p className={styles.metersNumber}>1233</p>
                                        <p className={styles.metersNumber}>123123312</p>
                                    </div>
                                </div>
                            </div>
                            <button className={styles.deleteButton}>
                                <CrossIcon className={styles.redCross} />
                                Удалить адрес
                            </button>
                        </div>
                        <div className={styles.areaWrapper}>
                            <p className={styles.areaCaption}>Площадь общая/жилая</p>
                            <p className={styles.area}>66 м2 / 22 м2</p>
                        </div>

                    </div>
                )}
            </div>
        </li>
    );
};
