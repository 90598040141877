import React, { useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import { useSelector } from 'react-redux';

import { useParams } from 'react-router-dom';
import { useAppDispatch } from '../../../../redux/store';
import { ErrorsAlert } from '../../ErrorAlert';
import { ButtonPrimary } from '../../Buttons/ButtonPrimary';
import { useSuccessNavigate } from '../../../../utils/hooks/useSuccessNavigate';
import { DropDownMenu } from '../../DropDownMenu';
import {
    getStatusSelector, getIsLoadingSelector, getRoomSelector, getFormErrorsSelector,
} from '../../../../redux/rooms/selectors';
import { getHousesSelector } from '../../../../redux/houses/selectors';
import { Room } from '../../../../redux/rooms/types';
import { createRoom, getRoomById, updateRoom } from '../../../../redux/rooms/asyncThunks';
import { Preloader } from '../../../Client/ui/Preloader';
import { getHouses } from '../../../../redux/houses/asyncThunks';

export const RoomForm = () => {
    const { id } = useParams();
    const isEditing = Boolean(id);
    const errors = useSelector(getFormErrorsSelector);
    const status = useSelector(getStatusSelector);
    const isLoading = useSelector(getIsLoadingSelector);
    const room = useSelector(getRoomSelector);
    const houses = useSelector(getHousesSelector);
    const dispatch = useAppDispatch();
    useSuccessNavigate('/bo/geo/room', status);
    const [roomValues, setRoomValues] = useState<Room>({
        houseId: null,
        name: null,
    });

    useEffect(() => {
        dispatch(getHouses());
        if (id) {
            dispatch(getRoomById(id));
        }
    }, []);

    useEffect(() => {
        if (isEditing) {
            setRoomValues(room);
        }
    }, [room]);

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const body = {
            houseId: roomValues.houseId,
            name: roomValues.name,
        };
        if (isEditing && id) {
            dispatch(updateRoom({ id, body }));
        } else {
            dispatch(createRoom(body));
        }
    };

    if (isEditing && !room.id) {
        return <Preloader />;
    }

    return (
        <Box
            component="form"
            onSubmit={handleSubmit}
            className="form"
            noValidate
        >
            {errors.length > 0 && <ErrorsAlert errors={errors} />}
            <DropDownMenu
                label="Выберите дом"
                required
                value={roomValues.houseId || ''}
                handleChange={(e) => setRoomValues({ ...roomValues, houseId: e.target.value })}
                items={houses}
            />

            <TextField
                id="name"
                label="Имя"
                name="name"
                required
                variant="standard"
                value={roomValues.name || ''}
                onChange={(e) => setRoomValues({ ...roomValues, name: e.target.value })}
            />
            <ButtonPrimary isLoading={isLoading} isEditing={isEditing} />
        </Box>
    );
};
