import React from 'react';
import styles from './Category.module.css';
import { CategoryProps } from './Category.props';

export const Category:React.FC<CategoryProps> = ({ title, text }) => (
    <li className={styles.item}>
        <h3 className={styles.title}>{title}</h3>
        <p className={styles.text}>{text}</p>
    </li>
);
