import InboxIcon from '@mui/icons-material/MoveToInbox';
import React from 'react';

export const sideBarEmployeeLinks = [
    {
        path: 'position',
        name: 'Должности',
        icon: <InboxIcon />,
    },
    {
        path: 'department',
        name: 'Департаменты',
        icon: <InboxIcon />,
    },
    {
        path: 'role',
        name: 'Роли',
        icon: <InboxIcon />,
    },
    {
        path: 'employee',
        name: 'Сотрудники',
        icon: <InboxIcon />,
    },
];
