import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { initialStateI } from './types';
import { StatusEnum } from '../../interfaces/StatusEnum';
import {
    createRole, deleteRole, getPermissions, getRoleById, getRoles, updateRole,
} from './asyncThunks';

const initialChecks: Record<string, boolean> = {};

const initialState: initialStateI = {
    role: {
        permissions: [],
        name: null,
        id: null,
    },
    roles: [],
    status: null,
    errors: [],
    formErrors: [],
    isLoading: false,
    roleIdToDelete: null,
    checks: {},
    permissions: [],
};

export const roleSlice = createSlice({
    name: 'roleSlice',
    initialState,
    reducers: {
        setIdToDelete(state, action) {
            state.roleIdToDelete = action.payload;
        },
        resetRole(state) {
            state.role.id = null;
            state.role.name = '';
            state.role.permissions = [];
        },
        setIsChecked(state, action) {
            state.checks[action.payload.i] = action.payload.isChecked;
        },
        resetChecks(state) {
            state.checks = {};
        },
        setRoleName(state, action) {
            state.role.name = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getRoles.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(getRoles.fulfilled, (state, action) => {
            state.roles = action.payload;
            state.errors = [];
            state.isLoading = false;
            state.status = null;
        });
        builder.addCase(getRoles.rejected, (state) => {
            state.isLoading = false;
        });
        builder.addCase(createRole.pending, (state) => {
            state.status = StatusEnum.PENDING;
            state.isLoading = true;
        });
        builder.addCase(createRole.fulfilled, (state) => {
            state.status = StatusEnum.SUCCESS;
            state.formErrors = [];
            state.isLoading = false;
        });
        builder.addCase(createRole.rejected, (state, action: PayloadAction<any>) => {
            state.status = StatusEnum.ERROR;
            state.formErrors = action.payload.errors;
            state.isLoading = false;
        });
        builder.addCase(updateRole.pending, (state) => {
            state.status = StatusEnum.PENDING;
            state.isLoading = true;
        });
        builder.addCase(updateRole.fulfilled, (state, action) => {
            state.status = StatusEnum.SUCCESS;
            state.role = action.payload;
            state.isLoading = false;
            state.formErrors = [];
        });
        builder.addCase(updateRole.rejected, (state, action: PayloadAction<any>) => {
            state.status = StatusEnum.ERROR;
            state.formErrors = action.payload.errors;
            state.isLoading = false;
        });
        builder.addCase(getRoleById.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(getRoleById.fulfilled, (state, action: PayloadAction<any>) => {
            state.role = action.payload;
            state.isLoading = false;
        });
        builder.addCase(getRoleById.rejected, (state) => {
            state.status = StatusEnum.ERROR;
            state.isLoading = false;
        });
        builder.addCase(deleteRole.pending, (state) => {
            state.status = StatusEnum.PENDING;
        });
        builder.addCase(deleteRole.fulfilled, (state) => {
            state.status = StatusEnum.SUCCESS;
            state.errors = [];
        });
        builder.addCase(deleteRole.rejected, (state, action: PayloadAction<any>) => {
            state.status = StatusEnum.ERROR;
            state.errors = action.payload.errors;
        });
        builder.addCase(getPermissions.pending, (state) => {
            // state.status = StatusEnum.PENDING;
        });
        builder.addCase(getPermissions.fulfilled, (state, action) => {
            // state.status = StatusEnum.SUCCESS;
            state.errors = [];
            state.permissions = action.payload;
            action.payload.forEach((i) => initialChecks[i.name] = false);
        });
        builder.addCase(getPermissions.rejected, (state, action: PayloadAction<any>) => {
            // state.status = StatusEnum.ERROR;
            state.errors = action.payload.errors;
        });
    },
});

export const { reducer: roleReducer, actions: roleActions } = roleSlice;
