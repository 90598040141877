import React, {
    FormEvent, useEffect, useState,
} from 'react';
import { Modal, ModalClose, ModalDialog } from '@mui/joy';
import Box from '@mui/material/Box';
import { TextField } from '@mui/material';
import { useSelector } from 'react-redux';
import { ModalNewCustomerProps } from './ModalNewCustomer.props';
import { ButtonPrimary } from '../Buttons/ButtonPrimary';
import { useAppDispatch } from '../../../redux/store';
import { getStatusSelector, getCustomerErrorsSelector } from '../../../redux/tickets/selectors';
import { StatusEnum } from '../../../interfaces/StatusEnum';
import { ErrorsAlert } from '../ErrorAlert';
import { ticketActions } from '../../../redux/tickets/slice';
import { useActionCreators } from '../../../utils/hooks/useActionCreators';
import { onPhoneInput, onPhoneKeyDown, onPhonePaste } from '../../../utils/helpers/handlePhoneInput';
import { formatPhoneNumber } from '../../../utils/helpers/formatPhoneNumber';
import { customerActions } from '../../../redux/customer/slice';
import {
    getCustomerByPhoneSelector,
    getIsLoadingCustomerSelector,
    getIsRequestedSelector,
} from '../../../redux/customer/selectors';
import { updateCustomer, createCustomer, getCustomerByPhone } from '../../../redux/customer/asyncThunks';

export const ModalNewCustomer: React.FC<ModalNewCustomerProps> = ({
    isOpen, onClose, isLoading, roomId, customerToEdit, updater,
}) => {
    const dispatch = useAppDispatch();
    const actions = useActionCreators(ticketActions);
    const [name, setName] = useState<string>('');
    const [phone, setPhone] = useState<string>('');
    const [isEditing, setIsEditing] = useState(false);
    const errors = useSelector(getCustomerErrorsSelector);
    const status = useSelector(getStatusSelector);
    const customerByPhone = useSelector(getCustomerByPhoneSelector);
    const isCustomerByPhoneLoading = useSelector(getIsLoadingCustomerSelector);
    const isRequested = useSelector(getIsRequestedSelector);

    useEffect(() => {
        dispatch(customerActions.resetCustomer());
    }, []);

    useEffect(() => {
        if (customerToEdit) {
            setIsEditing(true);
            setName(customerToEdit.fullName);
            setPhone(formatPhoneNumber(customerToEdit?.phone || ' '));
        }
    }, [customerToEdit]);

    useEffect(() => {
        if (status === StatusEnum.SUCCESS) {
            onClose();
            dispatch(customerActions.resetIsRequested());
            setName('');
            setPhone('');
        }
    }, [status]);

    useEffect(() => {
        if (customerToEdit) return;
        if (customerByPhone) {
            setIsEditing(true);
            setName(customerByPhone.fullName);
        } else {
            setIsEditing(false);
            setName('');
        }
    }, [customerByPhone]);

    useEffect(() => {
        if (phone.replace(/\D/g, '').length === 11 && !customerToEdit) {
            dispatch(getCustomerByPhone(phone.replace(/\D/g, '')));
        }
    }, [phone, customerToEdit]);

    const handleCloseModal = () => {
        onClose();
        dispatch(customerActions.resetIsRequested());
        setName('');
        setPhone('');
        actions.resetCustomerErrors();
    };

    const handleSubmit = async (e: FormEvent) => {
        const id = customerToEdit?.id || customerByPhone?.id;
        e.preventDefault();
        const body = {
            fullName: name,
            phone: phone.replace(/\D/g, ''),
            roomId,
        };

        if (!isRequested && !customerToEdit) {
            dispatch(getCustomerByPhone(phone.replace(/\D/g, '')));
        } else {
            if (isEditing && id) {
                await dispatch(updateCustomer({ id, body }));
                updater?.();
            } else {
                await dispatch(createCustomer(body));
                updater?.();
            }
            handleCloseModal();
        }

        handleCloseModal();
    };

    const onNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target;
        if (value.length > 0) {
            const nameWithUpperCase = value
                .split(' ')
                .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                .join(' ');
            setName(nameWithUpperCase);
        } else {
            setName('');
        }
    };

    const onPhoneChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = onPhoneInput(e);
        setPhone(newValue);
    };

    return (
        <Modal open={isOpen} onClose={handleCloseModal}>
            <ModalDialog>
                {errors.length > 0 && <ErrorsAlert errors={errors} />}
                <ModalClose sx={{ top: -40, right: -20 }} />
                <Box
                    component="form"
                    onSubmit={handleSubmit}
                    className="form"
                    noValidate
                >
                    <TextField
                        id="phone"
                        label="Телефон"
                        name="phone"
                        required
                        variant="standard"
                        value={phone || ''}
                        type="tel"
                        placeholder="+7 (___) ___-__-__"
                        onKeyDown={onPhoneKeyDown}
                        onPaste={onPhonePaste}
                        onChange={onPhoneChange}
                    />
                    <TextField
                        id="name"
                        label="ФИО"
                        name="name"
                        required
                        variant="standard"
                        value={name || ''}
                        onChange={onNameChange}
                        disabled={!isRequested && !customerToEdit}
                    />
                    <ButtonPrimary
                        isLoading={isLoading || isCustomerByPhoneLoading}
                        disabled={!isRequested && !customerToEdit}
                        isEditing={false}
                    >
                        Сохранить
                    </ButtonPrimary>
                </Box>
            </ModalDialog>
        </Modal>
    );
};
