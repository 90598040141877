export const statusFilterDropDownItems = [
    {
        id: '100',
        name: 'Новая',
    },
    {
        id: '200',
        name: 'Назначена',
    },
    {
        id: '300',
        name: 'В работе',
    },
    {
        id: '400',
        name: 'На проверке',
    },
    {
        id: '430',
        name: 'На проверке ожидания',
    },
    {
        id: '470',
        name: 'Ожидает',
    },
    {
        id: '500',
        name: 'Выполнена',
    },
    {
        id: '600',
        name: 'Отмена',
    },
];
