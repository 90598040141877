import React from 'react';
import { CategoryWithImageProps } from './CategoryWithImage.props';
import styles from './CategoryWithImage.module.css';

export const CategoryWithImage: React.FC<CategoryWithImageProps> = ({ title, text, image }) => (
    <li className={styles.item}>
        <div className={styles.textWrapper}>
            <h3 className={styles.title}>{title}</h3>
            <p className={styles.text}>{text}</p>
        </div>
        <img className={styles.image} src={image} alt={title} />
    </li>
);
