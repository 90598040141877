import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import { ArrowDropDownIcon } from '@mui/x-date-pickers';
import Typography from '@mui/material/Typography';
import styled from '@emotion/styled';
import { DocumentResponseItem } from '../../../../redux/documents/types';
import css from './DashboardDocument.module.css';

const StyledAccordion = styled(Accordion)`
    max-width: fit-content;
    box-shadow: none;
    padding: 0;
    margin: 0;
    &:before {
      height: 0;
      width: 0;
    }
`;

interface DashboardDocumentProps {
    doc: DocumentResponseItem;
}
export const DashboardDocument: React.FC<DashboardDocumentProps> = ({ doc }) => {
    const navigate = useNavigate();
    const handleNavigateToDocument = () => {
        navigate(`/bo/document/${doc.id}/update`);
    };

    const getStatus = (status: 100 | 200) => (status === 100 ? 'Активный' : 'Архив');

    return (
        <li className={css.doc}>
            <div className={css.header}>
                {/* <div className={css.top}> */}
                {/*    /!* <div className={css.number}> *!/ */}
                {/*    /!*    № *!/ */}
                {/*    /!*    {doc.number} *!/ */}
                {/*    /!* </div> *!/ */}
                {/*    /!* <div className={css.status} style={{ backgroundColor: doc.status === 100 ? '#F9DFE3' : '#6c757d', color: doc.status === 100 ? '#CF0627' : 'white' }}>{getStatus(doc.status)}</div> *!/ */}
                {/*    /!* <div className={css.author}>{`${doc.author.fullName} (${doc.author.position.name})`}</div> *!/ */}
                {/* </div> */}
                {doc.houses.length <= 2 ? (
                    <div>
                        <span className={css.label}>Адреса: </span>
                        <div className={css.address} style={{ marginTop: '10px' }}>
                            {doc.houses.map((house) => (
                                <div key={house.id}>{house.addressString}</div>
                            ))}
                        </div>
                    </div>
                ) : (
                    <StyledAccordion>
                        <AccordionSummary
                            expandIcon={<ArrowDropDownIcon />}
                            sx={{
                                p: '0', m: '0', flexDirection: 'row-reverse', minHeight: '20px',
                            }}
                        >
                            <span className={css.label}>Адреса</span>
                        </AccordionSummary>
                        <AccordionDetails sx={{ p: '0' }}>
                            <div className={css.address}>
                                {doc.houses.map((house) => (
                                    <div>{house.addressString}</div>
                                ))}
                            </div>
                        </AccordionDetails>
                    </StyledAccordion>
                )}
            </div>
            <div className={css.text}>
                <span className={css.source}>
                    <span className={css.label}>Источник: </span>
                    {doc.source}
                </span>
                <span>
                    <span className={css.label}>Текст: </span>
                    {doc.text}
                </span>
            </div>
        </li>
    );
};
