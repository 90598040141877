import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { initialStateI } from './types';
import {
    createCategory, deleteCategory, getCategories, getCategoryById, updateCategory,
} from './asyncThunks';
import { StatusEnum } from '../../interfaces/StatusEnum';

const initialState: initialStateI = {
    category: {
        id: null,
        parentId: null,
        name: null,
        description: null,
        iconFileId: null,
        isReviewPhotoRequired: false,
        isReviewCommentRequired: false,
    },
    categories: [],
    status: null,
    errors: [],
    formErrors: [],
    isLoading: false,
    categoryIdToDelete: null,
    file: {
        id: null,
        url: null,
    },
};

export const categorySlice = createSlice({
    name: 'categories',
    initialState,
    reducers: {
        setIdToDelete(state, action) {
            state.categoryIdToDelete = action.payload;
        },
        resetCategory(state) {
            state.category.id = null;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getCategories.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(getCategories.fulfilled, (state, action) => {
            state.categories = action.payload;
            state.errors = [];
            state.isLoading = false;
            state.status = null;
        });
        builder.addCase(getCategories.rejected, (state) => {
            state.isLoading = false;
        });
        builder.addCase(createCategory.pending, (state) => {
            state.status = StatusEnum.PENDING;
            state.isLoading = true;
        });
        builder.addCase(createCategory.fulfilled, (state) => {
            state.status = StatusEnum.SUCCESS;
            state.formErrors = [];
            state.isLoading = false;
        });
        builder.addCase(createCategory.rejected, (state, action: PayloadAction<any>) => {
            state.status = StatusEnum.ERROR;
            state.formErrors = action.payload.errors;
            state.isLoading = false;
        });
        builder.addCase(updateCategory.pending, (state) => {
            state.status = StatusEnum.PENDING;
            state.isLoading = true;
        });
        builder.addCase(updateCategory.fulfilled, (state) => {
            state.status = StatusEnum.SUCCESS;
            state.isLoading = false;
            state.formErrors = [];
        });
        builder.addCase(updateCategory.rejected, (state, action: PayloadAction<any>) => {
            state.status = StatusEnum.ERROR;
            state.formErrors = action.payload.errors;
            state.isLoading = false;
        });
        builder.addCase(getCategoryById.fulfilled, (state, action) => {
            state.category = action.payload;
        });
        builder.addCase(getCategoryById.rejected, (state) => {
            state.status = StatusEnum.ERROR;
        });
        builder.addCase(deleteCategory.pending, (state) => {
            state.status = StatusEnum.PENDING;
        });
        builder.addCase(deleteCategory.fulfilled, (state) => {
            state.status = StatusEnum.SUCCESS;
            state.errors = [];
        });
        builder.addCase(deleteCategory.rejected, (state, action: PayloadAction<any>) => {
            state.status = StatusEnum.ERROR;
            state.errors = action.payload.errors;
        });
    },
});

export const { reducer: categoryReducer, actions: categoryActions } = categorySlice;
