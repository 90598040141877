import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { initialStateI } from './types';
import { StatusEnum } from '../../interfaces/StatusEnum';
import {
    createStreet, deleteStreet, getStreetById,
    getStreets, updateStreet,
} from './asyncThunks';

const initialState: initialStateI = {
    street: {
        settlementId: null,
        name: null,
        id: null,
    },
    streets: [],
    status: null,
    errors: [],
    formErrors: [],
    isLoading: false,
    streetIdToDelete: null,
};

export const streetSlice = createSlice({
    name: 'streetSlice',
    initialState,
    reducers: {
        setIdToDelete(state, action) {
            state.streetIdToDelete = action.payload;
        },
        resetStreet(state) {
            state.street.id = null;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getStreets.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(getStreets.fulfilled, (state, action) => {
            state.streets = action.payload;
            state.errors = [];
            state.isLoading = false;
            state.status = null;
        });
        builder.addCase(getStreets.rejected, (state) => {
            state.isLoading = false;
        });
        builder.addCase(createStreet.pending, (state) => {
            state.status = StatusEnum.PENDING;
            state.isLoading = true;
        });
        builder.addCase(createStreet.fulfilled, (state) => {
            state.status = StatusEnum.SUCCESS;
            state.formErrors = [];
            state.isLoading = false;
        });
        builder.addCase(createStreet.rejected, (state, action: PayloadAction<any>) => {
            state.status = StatusEnum.ERROR;
            state.formErrors = action.payload.errors;
            state.isLoading = false;
        });
        builder.addCase(updateStreet.pending, (state) => {
            state.status = StatusEnum.PENDING;
            state.isLoading = true;
        });
        builder.addCase(updateStreet.fulfilled, (state, action) => {
            state.status = StatusEnum.SUCCESS;
            state.street = action.payload;
            state.isLoading = false;
            state.formErrors = [];
        });
        builder.addCase(updateStreet.rejected, (state, action: PayloadAction<any>) => {
            state.status = StatusEnum.ERROR;
            state.formErrors = action.payload.errors;
            state.isLoading = false;
        });
        builder.addCase(getStreetById.fulfilled, (state, action: PayloadAction<any>) => {
            state.street.name = action.payload.name;
            state.street.id = action.payload.id;
            state.street.settlementId = action.payload.settlement.id;
        });
        builder.addCase(getStreetById.rejected, (state) => {
            state.status = StatusEnum.ERROR;
        });
        builder.addCase(deleteStreet.pending, (state) => {
            state.status = StatusEnum.PENDING;
        });
        builder.addCase(deleteStreet.fulfilled, (state) => {
            state.status = StatusEnum.SUCCESS;
            state.errors = [];
        });
        builder.addCase(deleteStreet.rejected, (state, action: PayloadAction<any>) => {
            state.status = StatusEnum.ERROR;
            state.errors = action.payload.errors;
        });
    },
});

export const { reducer: streetReducer, actions: streetActions } = streetSlice;
