import React from 'react';
import cn from 'classnames';
import { HomeMenuProps } from './HomeMenu.props';
import { ReactComponent as HomeIcon } from '../../../assets/img/header/home-menu.svg';
import { ReactComponent as PlusIcon } from '../../../assets/img/plus.svg';
import styles from './HomeMenu.module.css';

export const HomeItem = ({ address, isActive = false }: any) => (
    <li className={styles.homeItem}>
        <div className={cn(styles.iconWrapper, {
            [styles.active]: isActive,
        })}
        >
            <HomeIcon />
        </div>
        <p className={styles.address}>{address}</p>
    </li>
);

export const HomeMenu: React.FC<HomeMenuProps> = () => (
    <div className={styles.wrapper}>
        <ul className={styles.homeList}>
            <HomeItem address="Просп. Героев, д. 8, кв. 82" isActive />
            <HomeItem address="Просп. Героев, д. 8, кв. 82" />
            <HomeItem address="Просп. Героев, д. 8, кв. 82" />
            <HomeItem address="Просп. Героев, д. 8, кв. 82" />
        </ul>
        <button className={styles.newHome}>
            <PlusIcon />
            Добавить новый
        </button>
    </div>
);
