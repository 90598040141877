import React, { useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import { useSelector } from 'react-redux';

import { useParams } from 'react-router-dom';
import { useAppDispatch } from '../../../../redux/store';
import { ErrorsAlert } from '../../ErrorAlert';
import { ButtonPrimary } from '../../Buttons/ButtonPrimary';
import { useSuccessNavigate } from '../../../../utils/hooks/useSuccessNavigate';
import {
    getStatusSelector, getIsLoadingSelector, getSettlementSelector, getFormErrorsSelector,
} from '../../../../redux/settlements/selectors';
import { Settlement } from '../../../../redux/settlements/types';
import { createSettlement, getSettlementById, updateSettlement } from '../../../../redux/settlements/asyncThunks';
import { Preloader } from '../../../Client/ui/Preloader';

export const SettlementForm = () => {
    const { id } = useParams();
    const errors = useSelector(getFormErrorsSelector);
    const status = useSelector(getStatusSelector);
    const isLoading = useSelector(getIsLoadingSelector);
    const settlement = useSelector(getSettlementSelector);
    const dispatch = useAppDispatch();
    useSuccessNavigate('/bo/geo/settlement', status);
    const isEditing = Boolean(id);
    const [settlementValues, setSettlementValues] = useState<Settlement>({
        name: null,
    });

    useEffect(() => {
        if (id) {
            dispatch(getSettlementById(id));
        }
    }, []);

    useEffect(() => {
        if (isEditing) {
            setSettlementValues(settlement);
        }
    }, [settlement]);

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const body = {
            name: settlementValues.name,
        };
        if (isEditing && id) {
            dispatch(updateSettlement({ id, body }));
        } else {
            dispatch(createSettlement(body));
        }
    };

    if (isEditing && !settlement.id) {
        return <Preloader />;
    }

    return (
        <Box
            component="form"
            onSubmit={handleSubmit}
            className="form"
            noValidate
        >
            {errors.length > 0 && <ErrorsAlert errors={errors} />}
            <TextField
                id="name"
                label="Имя"
                name="name"
                required
                variant="standard"
                value={settlementValues.name || ''}
                onChange={(e) => setSettlementValues({ ...settlementValues, name: e.target.value })}
            />
            <ButtonPrimary isLoading={isLoading} isEditing={isEditing} />
        </Box>
    );
};
