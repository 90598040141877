import React from 'react';
import styles from './Reading.module.css';
import { ReadingProps } from './Reading.props';
import { Button } from '../../../../components/Client/ui/Button';

export const Reading: React.FC<ReadingProps> = ({
    title, number, icon, children,
}) => (
    <div className={styles.wrapper}>
        <div className={styles.headingWrapper}>
            <div className={styles.textWrapper}>
                <h2 className={styles.title}>{title}</h2>
                <span className={styles.number}>
                    № счетчика:&nbsp;
                    {number}
                </span>
            </div>
            <div className={styles.iconWrapper}>
                <img src={icon} alt="" />
            </div>
        </div>
        {children}
        <Button className={styles.button} appearance="primary">Отправить показания</Button>
    </div>
);
