import React, { useEffect } from 'react';
import { Modal, ModalClose, ModalDialog } from '@mui/joy';
import styles from './ModalConfitm.module.css';
import { ModalConfirmProps } from './ModalConfirm.props';
import { ErrorsAlert } from '../ErrorAlert';
import { StatusEnum } from '../../../interfaces/StatusEnum';

export const ModalConfirm: React.FC<ModalConfirmProps> = ({
    handler, isOpen, onClose, errors = [], status,
}) => {
    useEffect(() => {
        if (status === StatusEnum.SUCCESS) {
            onClose();
        }
    }, [status]);

    return (
        <Modal open={isOpen} onClose={onClose}>
            <ModalDialog sx={{ backgroundColor: 'var(--dark-red)', color: 'white' }}>
                {errors.length > 0 && (
                    <ErrorsAlert errors={errors} />
                )}
                <ModalClose sx={{ color: 'white' }} />
                <div className={styles.content}>
                    <span>Вы подтверждаете своё действие?</span>
                    <button
                        className={styles.button}
                        onClick={handler}
                        type="button"
                    >
                        Да
                    </button>
                </div>
            </ModalDialog>
        </Modal>
    );
};
