import React from 'react';
import { Link } from 'react-router-dom';
import { SectionTitle } from '../../../../components/Client/ui/SectionTitle';
import { NewsItem } from '../NewsItem/NewsItem';
import { NewsSectionProps } from './NewsSection.props';
import { YearsFilter } from '../../../../components/Client/ui/YearsFilter/YearsFilter';
import { ReactComponent as ArrowIcon } from '../../../../assets/img/home/arrow.svg';
import styles from './NewsSection.module.css';

export const NewsSection: React.FC<NewsSectionProps> = () => (
    <div className={styles.wrapper}>
        <SectionTitle>Новости</SectionTitle>
        <div className={styles.topInfoWrapper}>
            <YearsFilter />
            <Link to="/lk/news" className={styles.link}>
                Посмотреть все
                <ArrowIcon />
            </Link>
        </div>
        <ul className={styles.newsList}>
            <NewsItem />
            <NewsItem isChecked />
            <NewsItem />
            <NewsItem />
        </ul>
        <Link to="/news" className={styles.linkBottom}>
            Посмотреть все
            <ArrowIcon />
        </Link>
    </div>
);
