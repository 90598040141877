import React from 'react';
import { ErrorsAlertProps } from './ErrorsAlert.props';
import styles from './ErrorsAlert.module.css';
import { ErrorItem } from '../ErrorItem';

export const ErrorsAlert: React.FC<ErrorsAlertProps> = ({ errors }) => (
    <ul className={styles.errorsList}>
        {
            errors.map((e, i) => (
                // eslint-disable-next-line react/no-array-index-key
                <ErrorItem error={e} key={i} />
            ))
        }
    </ul>
);
