import { Permissions } from '../../interfaces/Permissions';

export const panelNavList = [
    {
        path: '/bo/ticket',
        text: 'Заявки',
        directories: [],
        isTicket: true,
    },
    {
        path: '/bo/document',
        text: 'Документы',
        directories: [],
        isTicket: false,
        permission: Permissions.DOCUMENTS_READ,
    },
    {
        path: '/bo/geo',
        text: 'Объекты',
        directories: [
            {
                path: '/bo/geo/settlement',
                name: 'Населённые пункты',
            },
            {
                path: '/bo/geo/street',
                name: 'Улицы',
            },
            {
                path: '/bo/geo/house',
                name: 'Дома',
            },
            {
                path: '/bo/geo/room',
                name: 'Квартиры',
            },
        ],
        isTicket: false,
    },
    {
        path: '/bo/employee',
        text: 'Пользователи',
        directories: [
            {
                path: '/bo/employee/position',
                name: 'Должности',
            },
            {
                path: '/bo/employee/department',
                name: 'Департаменты',
            },
            {
                path: '/bo/employee/role',
                name: 'Роли',
            },
            {
                path: '/bo/employee/employee',
                name: 'Сотрудники',
            },
        ],
        isTicket: false,
    },
    {
        path: '/bo/settings',
        text: 'Настройки',
        directories: [
            {
                path: '/bo/settings/ticket-category',
                name: 'Категории',
            },
            {
                path: '/bo/settings/ticket-priority',
                name: 'Приоритеты',
            },
            {
                path: '/bo/settings/ticket-assignment-rule',
                name: 'Правила назначения',
            },
        ],
        isTicket: false,
    },
];
