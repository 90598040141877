import React from 'react';
import { ProfileProps } from './Profile.props';
import { SectionTitle } from '../../../../components/Client/ui/SectionTitle';
import styles from './Profile.module.css';
import { ReactComponent as EditIcon } from '../../../../assets/img/edit.svg';
import { ReactComponent as PhoneIcon } from '../../../../assets/img/phone.svg';
import { ReactComponent as TimeIcon } from '../../../../assets/img/time-green.svg';
import { ReactComponent as MailIcon } from '../../../../assets/img/mail-green.svg';
import { ReactComponent as HomeIcon } from '../../../../assets/img/home-green.svg';
import { ReactComponent as PlusIcon } from '../../../../assets/img/plus.svg';
import { AddressItem } from '../../../../components/Client/AddressItem/AddressItem';
import { BreadCrumbs } from '../../../../components/Client/BreadCrumbs';

export const Profile: React.FC<ProfileProps> = () => (
    <main className="content">
        <BreadCrumbs />
        <div className={styles.profileInfo}>
            <div className={styles.userInfo}>
                <div className={styles.nameWrapper}>
                    <h2 className={styles.name}>Констатинопольский Константин</h2>
                    <button className={styles.editButton}>
                        <EditIcon />
                    </button>
                </div>
                <ul className={styles.dataWrapper}>
                    <li className={styles.data}>
                        <p className={styles.dataCapture}>Дата рождения</p>
                        <p className={styles.dataValue}>17.12.1995</p>
                    </li>
                    <li className={styles.data}>
                        <p className={styles.dataCapture}>E-mail</p>
                        <p className={styles.dataValue}>Не заполнено</p>
                    </li>
                    <li className={styles.data}>
                        <p className={styles.dataCapture}>Номер телефона</p>
                        <p className={styles.dataValue}>+7 999 999 99 99</p>
                    </li>
                </ul>
            </div>
            <div className={styles.companyInfo}>
                <h2 className={styles.companyName}>
                    Вас обслуживает&nbsp;
                    <span className={styles.greenAccent}>УК Гранд-Сервис+</span>
                </h2>
                <ul className={styles.companyGrid}>
                    <li className={styles.companyData}>
                        <p className={styles.dataCapture}>
                            <HomeIcon className={styles.icon} />
                            Адрес
                        </p>
                        <p className={styles.dataValue}>
                            Балашиха, ул Константиновская
                            д.&nbsp;23
                        </p>
                    </li>
                    <li className={styles.companyData}>
                        <p className={styles.dataCapture}>
                            <PhoneIcon className={styles.icon} />
                            Телефон
                        </p>
                        <p className={styles.dataValue}>
                            +7 (812) 888-33-11
                        </p>
                    </li>
                    <li className={styles.companyData}>
                        <p className={styles.dataCapture}>
                            <TimeIcon className={styles.icon} />
                            Время работы
                        </p>
                        <p className={styles.dataValue}>
                            с 9:00–17:30
                        </p>
                    </li>
                    <li className={styles.companyData}>
                        <p className={styles.dataCapture}>
                            <MailIcon className={styles.icon} />
                            Электронный адрес
                        </p>
                        <p className={styles.dataValue}>
                            uk-grand@grand.ru
                        </p>
                    </li>
                </ul>
            </div>
        </div>
        <div className={styles.addressesWrapper}>
            <SectionTitle>Адреса</SectionTitle>
            <button className={styles.addressButton}>
                <PlusIcon />
                Добавить
            </button>
        </div>
        <ul className={styles.addressList}>
            <AddressItem address="г. Балашиха, ул Главная дом 24 квартира 666" />
            <AddressItem address="г. Балашиха, ул Главная дом 24 квартира 662" />
            <AddressItem address="г. Балашиха, ул Главная дом 24 квартира 661" />
        </ul>
    </main>
);
