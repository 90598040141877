import React, {
    FormEvent, useEffect, useState,
} from 'react';
import { Modal, ModalClose, ModalDialog } from '@mui/joy';
import Box from '@mui/material/Box';
import { useSelector } from 'react-redux';
import { TextField } from '@mui/material';
import { ButtonPrimary } from '../Buttons/ButtonPrimary';
import { useAppDispatch } from '../../../redux/store';
import {
    getStatusSelector,
    getIsLoadingSelector,
} from '../../../redux/tickets/selectors';
import { StatusEnum } from '../../../interfaces/StatusEnum';
import { changePassword } from '../../../redux/employees/asyncThunks';
import { getIdToDeleteSelector } from '../../../redux/employees/selectors';

type componentProps = {
    isOpen: boolean;
    onClose: () => void;
}

export const ModalChangePassword = ({
    isOpen, onClose,
}: componentProps) => {
    const dispatch = useAppDispatch();
    const [password, setPassword] = useState<string>();
    const status = useSelector(getStatusSelector);
    const isLoading = useSelector(getIsLoadingSelector);
    const employeeId = useSelector(getIdToDeleteSelector);

    useEffect(() => {
        if (status === StatusEnum.SUCCESS) {
            onClose();
        }
    }, [status]);

    const handleCloseModal = () => {
        onClose();
        setPassword('');
    };

    const handleSubmit = (e: FormEvent) => {
        e.preventDefault();
        if (password && employeeId) {
            dispatch(changePassword({ id: employeeId, password }));
        }
        handleCloseModal();
    };

    return (
        <Modal open={isOpen} onClose={handleCloseModal}>
            <ModalDialog sx={{ backgroundColor: 'white' }}>
                <ModalClose sx={{ top: -40, right: -20 }} />
                <Box
                    component="form"
                    onSubmit={handleSubmit}
                    className="form"
                    noValidate
                >
                    <TextField
                        id="password"
                        name="password"
                        type="password"
                        label="Поменять пароль"
                        value={password || ''}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                    <ButtonPrimary isLoading={isLoading} isEditing />
                </Box>
            </ModalDialog>
        </Modal>
    );
};
