import * as React from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { DropDownMenuProps } from './DropDownMenu.props';

export const DropDownMenu: React.FC<DropDownMenuProps> = ({
    value, handleChange, items, label, required,
}) => (
    <FormControl fullWidth required={required}>
        <InputLabel id="demo-simple-select-label">{label}</InputLabel>
        <Select
            MenuProps={{
                style: { zIndex: 35001 },
            }}
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={value}
            label={label}
            onChange={handleChange}
        >
            {items.length > 0 && items.map((i) => (
                <MenuItem key={i.id} value={i.id as string}>{i.name}</MenuItem>
            ))}
        </Select>
    </FormControl>
);
