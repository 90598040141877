import React from 'react';
import styles from '../Header/Header.module.css';
import { UserInfoProps } from './UserInfo.props';

export const UserInfo: React.FC<UserInfoProps> = ({
    username, userImage, userAddress, isPanel, ...props
}) => (
    <button className={styles.userInfo} {...props}>
        <img className={styles.avatar} src={userImage} alt="Аватар пользователя" />
        <div className={styles.info}>
            <div className={styles.userName}>{username || 'Иванов Иван'}</div>
            {!isPanel && <div className={styles.userAddress}>{userAddress}</div>}
        </div>
    </button>
);
