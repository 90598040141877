import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DirectoryPageProps } from './DirectoryPage.props';
import { ModalConfirm } from '../../ModalConfirm';
import { getIsModalOpenSelector } from '../../../../redux/directories/selectors';
import { directoryActions } from '../../../../redux/directories/slice';
import styles from './Directory.module.css';
import { DirectoryTopInfo } from '../../DirectoryTopInfo/DirectoryTopInfo';

export const DirectoryPage: React.FC<DirectoryPageProps> = ({
    info, headings, children, modalHandler, errors, status,
}) => {
    const dispatch = useDispatch();
    const isModalOpen = useSelector(getIsModalOpenSelector);

    const onCloseModal = () => {
        dispatch(directoryActions.setIsModalClose());
    };

    const gridStyles = headings.length > 2 ? { gridTemplateColumns: `repeat(${headings.length}, 2fr)` } : { gridTemplateColumns: '2fr auto' };

    return (
        <div className={styles.wrapper}>
            <DirectoryTopInfo title={info.title} text={info.text} />
            <div className={styles.table}>
                <ul className={styles.headings} style={gridStyles}>
                    {headings.map((h) => (
                        <li key={h} className={styles.heading}>
                            {h}
                        </li>
                    ))}
                </ul>
                {children}
            </div>
            {isModalOpen && modalHandler && (
                <ModalConfirm
                    handler={modalHandler}
                    isOpen={isModalOpen}
                    onClose={onCloseModal}
                    errors={errors}
                    status={status}
                />
            )}
        </div>
    );
};
