import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import cn from 'classnames';
import { useSelector } from 'react-redux';
import { panelNavList } from '../../../../utils/data/panelNavList';
import styles from './BurgerMenu.module.css';
import { useAppDispatch } from '../../../../redux/store';
import { ticketActions } from '../../../../redux/tickets/slice';
import { getUserPermissionsSelector } from '../../../../redux/auth/selectors';

type componentProps = {
  handleToggleBurgerMenu: () => void;
  isOpen: boolean;
  forceUpdate: () => void;
}
export const BurgerMenu = ({ handleToggleBurgerMenu, isOpen, forceUpdate }: componentProps) => {
    const dispatch = useAppDispatch();
    const userPermissions = useSelector(getUserPermissionsSelector);

    const [isMenuExtended, setIsMenuExtended] = useState(false);

    useEffect(() => {
        if (window.innerWidth < 800) setIsMenuExtended(true);
    }, []);

    const onLinkClick = (isTicket: boolean) => {
        handleToggleBurgerMenu();
        if (isTicket) {
            forceUpdate();
            dispatch(ticketActions.resetSuccessCreated());
        }
    };

    return (
        <nav className={cn(styles.navigation, {
            [styles.opened]: isOpen,
        })}
        >
            <ul className={styles.navList}>
                {panelNavList.map((i) => (i.permission ? userPermissions.includes(i.permission) && (
                    <li key={i.text} className={styles.navItem}>
                        <NavLink
                            onClick={() => onLinkClick(i.isTicket)}
                            to={i.path}
                            className={({ isActive }) => (cn(styles.link, {
                                [styles.activeLink]: isActive,
                            }))}
                        >
                            {i.text}
                        </NavLink>
                        {isMenuExtended && (
                            <ul className={styles.navList}>
                                {i.directories.map((l: {name: string, path: string}) => (
                                    <li key={l.name} className={styles.childNavItem}>
                                        <NavLink
                                            to={l.path}
                                            onClick={handleToggleBurgerMenu}
                                            className={({ isActive }) => (cn(styles.childLink, {
                                                [styles.activeChildLink]: isActive,
                                            }))}
                                        >
                                            {l.name}
                                        </NavLink>
                                    </li>
                                ))}
                            </ul>
                        )}
                    </li>
                ) : (
                    <li key={i.text} className={styles.navItem}>
                        <NavLink
                            onClick={() => onLinkClick(i.isTicket)}
                            to={i.path}
                            className={({ isActive }) => (cn(styles.link, {
                                [styles.activeLink]: isActive,
                            }))}
                        >
                            {i.text}
                        </NavLink>
                        {isMenuExtended && (
                            <ul className={styles.navList}>
                                {i.directories.map((l) => (
                                    <li key={l.name} className={styles.childNavItem}>
                                        <NavLink
                                            to={l.path}
                                            onClick={handleToggleBurgerMenu}
                                            className={({ isActive }) => (cn(styles.childLink, {
                                                [styles.activeChildLink]: isActive,
                                            }))}
                                        >
                                            {l.name}
                                        </NavLink>
                                    </li>
                                ))}
                            </ul>
                        )}
                    </li>
                )
                ))}
            </ul>
        </nav>
    );
};
