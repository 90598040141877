import React from 'react';
import {
    FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, Stack,
} from '@mui/material';
import { RadioGroupMenuProps } from './RadioGroupMenu.props';
import { CustomerI } from '../../../../interfaces/Tickets';
import { EditButton } from '../../../Panel/Buttons/EditButton';
import { formatPhoneNumber } from '../../../../utils/helpers/formatPhoneNumber';

export const RadioGroupMenu: React.FC<RadioGroupMenuProps> = ({
    value, label, onChange, onEditClick, items, isCustomers = false,
}) => {
    const getLabel = (customer: CustomerI) => `${customer.fullName}, ${formatPhoneNumber(customer.phone)}`;
    return (
        <FormControl>
            <FormLabel id="demo-radio-buttons-group-label">{label}</FormLabel>
            <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                name="radio-buttons-group"
                value={value || ''}
                onChange={onChange}
            >
                {items.map((item) => (
                    <Stack key={item.id} sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        <FormControlLabel
                            value={item.id}
                            control={<Radio />}
                            label={getLabel(item)}
                        />
                        {item.isEditable && <EditButton onClick={() => onEditClick(item.id)} />}
                    </Stack>
                ))}
                {isCustomers && <FormControlLabel value="new" control={<Radio />} label="Добавить нового" />}
            </RadioGroup>
        </FormControl>
    );
};
