import { createAsyncThunk } from '@reduxjs/toolkit';
import { Room } from './types';
import axios from '../../axios';
import { roomActions } from './slice';

type DataI = {
  id: string;
  body: Room;
}

export const getRooms = createAsyncThunk<Room[]>(
    'getRooms',
    async (body, { dispatch }) => {
        await dispatch(roomActions.resetRoom());
        const res = await axios.get('geo/room');
        return res.data.data;
    },
);

export const createRoom = createAsyncThunk<Room, Room>(
    'createRoom',
    async (body, { rejectWithValue }) => {
        try {
            const res = await axios.post('geo/room', body);
            return res.data;
        } catch (err: any) {
            return rejectWithValue(err.response.data);
        }
    },
);

export const deleteRoom = createAsyncThunk<string, string | null>(
    'deleteRoom',
    async (id, { dispatch, rejectWithValue }) => {
        try {
            const res = await axios.delete(`geo/room/${id}`);
            dispatch(getRooms());
            return res.data.data;
        } catch (err: any) {
            return rejectWithValue(err.response.data);
        }
    },
);

export const updateRoom = createAsyncThunk<Room, DataI>(
    'updateRoom',
    async ({ id, body }, { rejectWithValue }) => {
        try {
            const res = await axios.patch(`geo/room/${id}`, body);
            return await res.data.data;
        } catch (err: any) {
            return rejectWithValue(err.response.data);
        }
    },
);
export const getRoomById = createAsyncThunk<Room, string>(
    'getRoomById',
    async (id) => {
        const res = await axios.get(`geo/room/${id}`);
        return res.data.data;
    },
);
