import React from 'react';
import LoadingButton from '@mui/lab/LoadingButton';
import Button from '@mui/material/Button';
import { ButtonPrimaryProps } from './ButtonPrimary.props';
import styles from './ButtonPrimary.module.css';

export const ButtonPrimary: React.FC<ButtonPrimaryProps> = ({
    isLoading,
    isEditing = false,
    children,
    type = 'submit',
    disabled = false,
    ...props
}) => (
    <div>
        {isLoading
            ? <LoadingButton className={styles.button} loading disabled variant="contained" />
            : (
                <Button
                    sx={{ backgroundColor: 'var(--green)' }}
                    type={type}
                    className={styles.button}
                    variant="contained"
                    disabled={disabled}
                    {...props}
                >
                    <>
                        {children || (isEditing ? 'Сохранить' : 'Создать')}
                    </>
                </Button>
            )}
    </div>
);
