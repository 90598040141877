import { createAsyncThunk } from '@reduxjs/toolkit';
import { Priority } from './types';
import axios from '../../axios';
import { priorityActions } from './slice';

type DataI = {
  id: string;
  body: Priority;
}

export const getPriorities = createAsyncThunk<Priority[]>(
    'getPriorities',
    async (body, { dispatch, rejectWithValue }) => {
        try {
            await dispatch(priorityActions.resetPriority());
            const res = await axios.get('ba/helpdesk/priority');
            return res.data.data;
        } catch (err: any) {
            return rejectWithValue(err.response.data);
        }
    },
);

export const createPriority = createAsyncThunk<Priority, Priority>(
    'createPriorities',
    async (body, { rejectWithValue }) => {
        try {
            const res = await axios.post('ba/helpdesk/priority', body);
            return res.data;
        } catch (err: any) {
            return rejectWithValue(err.response.data);
        }
    },
);

export const deletePriority = createAsyncThunk<string, string | null>(
    'deletePriority',
    async (id, { dispatch, rejectWithValue }) => {
        try {
            const res = await axios.delete(`ba/helpdesk/priority/${id}`);
            dispatch(getPriorities());
            return res.data.data;
        } catch (err: any) {
            return rejectWithValue(err.response.data);
        }
    },
);

export const updatePriority = createAsyncThunk<Priority, DataI>(
    'updatePriorities',
    async ({ id, body }, { rejectWithValue }) => {
        try {
            const res = await axios.patch(`ba/helpdesk/priority/${id}`, body);
            return await res.data.data;
        } catch (err: any) {
            return rejectWithValue(err.response.data);
        }
    },
);
export const getPriorityById = createAsyncThunk<Priority, string>(
    'getPriorityById',
    async (id, { rejectWithValue }) => {
        try {
            const res = await axios.get(`ba/helpdesk/priority/${id}`);
            return res.data.data;
        } catch (err: any) {
            return rejectWithValue(err.response.data);
        }
    },
);
