import React from 'react';
import { Settlements } from '../../../components/Panel/Directories/Settlements/Settlements';
import { SettlementForm } from '../../../components/Panel/Directories/Settlements/SettlementForm';
import { Streets } from '../../../components/Panel/Directories/Streets/Streets';
import { StreetForm } from '../../../components/Panel/Directories/Streets/StreetForm';
import { Rooms } from '../../../components/Panel/Directories/Rooms/Rooms';
import { RoomForm } from '../../../components/Panel/Directories/Rooms/RoomForm';
import { Houses } from '../../../components/Panel/Directories/Houses/Houses';
import { HouseForm } from '../../../components/Panel/Directories/Houses/HouseForm';

export const geoRouting = [
    {
        path: '/settlement',
        component: <Settlements />,
        form: <SettlementForm />,
    },
    {
        path: '/street',
        component: <Streets />,
        form: <StreetForm />,
    },
    {
        path: '/house',
        component: <Houses />,
        form: <HouseForm />,
    },
    {
        path: '/room',
        component: <Rooms />,
        form: <RoomForm />,
    },
];
