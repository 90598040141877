import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { ReactComponent as ProfileIcon } from '../../../assets/img/header/profile.svg';
import { ReactComponent as ExitIcon } from '../../../assets/img/header/exit.svg';
import { ReactComponent as ArrowIcon } from '../../../assets/img/header/arrow.svg';
import styles from './ProfileMenu.module.css';
import { useAppDispatch } from '../../../redux/store';
import { authActions, authSlice } from '../../../redux/auth/slice';

export interface ProfileMenuProps {
  handleClose: () => void;
  isPanel: boolean
}

export const ProfileMenu: React.FC<ProfileMenuProps> = ({ handleClose, isPanel }) => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const onExit = () => {
        dispatch(authActions.signout());
        localStorage.removeItem('token');
    };
    return (
        <div className={styles.wrapper} onClick={handleClose}>
            {!isPanel && (
                <Link to="/lk/profile" className={styles.link}>
                    <ProfileIcon />
                    <p className={styles.linkText}>Профиль</p>
                    <ArrowIcon className={styles.arrow} />
                </Link>
            )}
            <button className={styles.link} onClick={onExit}>
                <ExitIcon />
                <p className={styles.linkText}>Выход</p>
                <ArrowIcon className={styles.arrow} />
            </button>
        </div>
    );
};
