import React from 'react';
import styles from './Readings.module.css';
import { Reading } from './Reading/Reading';
import energyIconSrc from '../../../assets/img/energy-with-bg.svg';
import waterIconSrc from '../../../assets/img/water-with-bg.svg';
import { ReadingInput } from './ReadingInput/ReadingInput';
import { Alert } from '../../../components/Client/ui/Alert/Alert';
import { ReadingsModal } from './ReadingsModal/ReadingsModal';
import { Modal } from '../../../components/Client/ui/Modal/Modal';

const modalData = [
    {
        name: 'Холодная вода',
        value: '23123',
    },
    {
        name: 'Горячая вода',
        value: '1111 ',
    },
];

export const Readings = () => (
    <main className="content">
        <h1 className={styles.title}>
            Показания за&nbsp;
            <span className={styles.accentGreen}>февраль</span>
        </h1>
        <span className={styles.deadlineInfo}>Отправьте показания до 24 февраля</span>
        <div className={styles.wrapper}>
            <Reading icon={energyIconSrc} title="Электроэнергия" number="0912 2223311">
                <div className={styles.inputsWrapper}>
                    <ReadingInput prevReadings="12311 кВТ" placeholder="Показания по счетчику T1" />
                    <ReadingInput prevReadings="12311 кВТ" placeholder="Показания по счетчику T2" />
                    <ReadingInput prevReadings="12311 кВТ" placeholder="Показания по счетчику T3" />
                </div>
            </Reading>
            <Reading icon={waterIconSrc} title="Водоснабжение" number="0912 2223311">
                <div className={styles.inputsWrapper}>
                    <ReadingInput prevReadings="12311 м3" placeholder="Холодная вода" />
                    <ReadingInput prevReadings="12311 м3" placeholder="Горячая вода" />
                </div>
            </Reading>
            <Alert heading="Неправильно ввели показания?" text="Вы можете изменить переданные показания до 24 числа каждого месяца" />
        </div>
        {/* <Modal> */}
        {/*    <ReadingsModal */}
        {/*        metersName="Водоснабжение" */}
        {/*        metersNumber="№ счетчика: 0912 2223311" */}
        {/*        metersData={modalData} */}
        {/*    /> */}
        {/* </Modal> */}
    </main>
);
