import { createAsyncThunk } from '@reduxjs/toolkit';
import { PermissionResponseItem, Role } from './types';
import axios from '../../axios';
import { roleActions } from './slice';

type DataI = {
  id: string;
  body: Role;
}

export const getRoles = createAsyncThunk<Role[]>(
    'getRoles',
    async (body, { dispatch }) => {
        await dispatch(roleActions.resetRole());
        const res = await axios.get('auth/role');
        return res.data.data;
    },
);

export const createRole = createAsyncThunk<Role, Role>(
    'createRole',
    async (body, { rejectWithValue }) => {
        try {
            const res = await axios.post('auth/role', body);
            return res.data;
        } catch (err: any) {
            return rejectWithValue(err.response.data);
        }
    },
);

export const deleteRole = createAsyncThunk<string, string | null>(
    'deleteRole',
    async (id, { dispatch, rejectWithValue }) => {
        try {
            const res = await axios.delete(`auth/role/${id}`);
            dispatch(getRoles());
            return res.data.data;
        } catch (err: any) {
            return rejectWithValue(err.response.data);
        }
    },
);

export const updateRole = createAsyncThunk<Role, DataI>(
    'updateRole',
    async ({ id, body }, { rejectWithValue }) => {
        try {
            const res = await axios.patch(`auth/role/${id}`, body);
            return await res.data.data;
        } catch (err: any) {
            return rejectWithValue(err.response.data);
        }
    },
);
export const getRoleById = createAsyncThunk<Role, string>(
    'getRoleById',
    async (id) => {
        const res = await axios.get(`auth/role/${id}`);
        return res.data.data;
    },
);

export const getPermissions = createAsyncThunk<PermissionResponseItem[]>(
    'getPermissions',
    async () => {
        const res = await axios.get('ba/auth/permission');
        return res.data.data;
    },
);
