import { Button } from '@mui/material';
import styles from './ErrorPage.module.css';

export const ErrorPage = () => {
    const reloadPage = () => {
        // eslint-disable-next-line no-restricted-globals
        location.reload();
    };
    return (
        <div className={styles.pageWrapper}>
            <h1 className={styles.heading}>Произошла непредвиденная ошибка :(</h1>
            <Button onClick={reloadPage} variant="contained">Обновить страницу</Button>
        </div>
    );
};
