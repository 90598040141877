import React from 'react';
import { ReactComponent as EnergyIcon } from '../../../../assets/img/meters/energy.svg';
import styles from './Badge.module.css';
import { BadgeProps } from './Badge.props';

export const Badge: React.FC<BadgeProps> = ({ text, isWithIcon = false }) => (
    <div className={styles.wrapper}>
        { isWithIcon && <EnergyIcon className={styles.icon} />}
        {text}
    </div>
);
