import React, { useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import { useSelector } from 'react-redux';

import { useParams } from 'react-router-dom';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { createPriority, getPriorityById, updatePriority } from '../../../../redux/priorities/asyncThunks';
import { useAppDispatch } from '../../../../redux/store';
import { ErrorsAlert } from '../../ErrorAlert';
import {
    getStatusSelector, getIsLoadingSelector, getPrioritySelector, getFormErrorsSelector,
} from '../../../../redux/priorities/selectors';
import { ButtonPrimary } from '../../Buttons/ButtonPrimary';
import { useSuccessNavigate } from '../../../../utils/hooks/useSuccessNavigate';
import { Priority } from '../../../../redux/priorities/types';
import { Preloader } from '../../../Client/ui/Preloader';

export const PriorityForm = () => {
    const { id } = useParams();
    const priority = useSelector(getPrioritySelector);
    const errors = useSelector(getFormErrorsSelector);
    const status = useSelector(getStatusSelector);
    const isLoading = useSelector(getIsLoadingSelector);
    const dispatch = useAppDispatch();
    useSuccessNavigate('/bo/settings/ticket-priority', status);
    const isEditing = Boolean(id);
    const [priorityValues, setPriorityValues] = useState<Priority>(
        {
            name: null,
            term: null,
            backgroundColor: '',
            textColor: '',
            weight: null,
            isDefault: false,
        },
    );

    useEffect(() => {
        if (id) {
            dispatch(getPriorityById(id));
        }
    }, []);

    useEffect(() => {
        if (isEditing) {
            setPriorityValues(priority);
        }
    }, [priority]);

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const body = {
            name: priorityValues.name,
            term: priorityValues.term,
            backgroundColor: priorityValues.backgroundColor,
            textColor: priorityValues.textColor,
            weight: Number(priorityValues.weight),
            isDefault: priorityValues.isDefault,
        };
        if (isEditing && id) {
            dispatch(updatePriority({ id, body }));
        } else {
            dispatch(createPriority(body));
        }
    };

    if (isEditing && !priority.id) {
        return <Preloader />;
    }

    return (
        <Box
            component="form"
            onSubmit={handleSubmit}
            className="form"
            noValidate
        >
            {errors.length > 0 && <ErrorsAlert errors={errors} />}
            <TextField
                id="name"
                label="Имя"
                name="name"
                required
                variant="standard"
                value={priorityValues.name || ''}
                onChange={(e) => setPriorityValues({ ...priorityValues, name: e.target.value })}
            />
            <TextField
                id="term"
                label="Срок"
                name="term"
                required
                variant="standard"
                value={priorityValues.term || ''}
                onChange={(e) => setPriorityValues({ ...priorityValues, term: Number(e.target.value) })}
            />
            <TextField
                id="backgroundColor"
                label="Цвет фона"
                name="bgColor"
                required
                variant="standard"
                value={priorityValues.backgroundColor || ''}
                onChange={(e) => setPriorityValues({ ...priorityValues, backgroundColor: e.target.value })}
            />
            <TextField
                id="textColor"
                label="Цвет текста"
                name="textColor"
                required
                variant="standard"
                value={priorityValues.textColor || ''}
                onChange={(e) => setPriorityValues({ ...priorityValues, textColor: e.target.value })}
            />
            <TextField
                id="weight"
                label="Вес"
                name="weight"
                required
                variant="standard"
                value={priorityValues.weight || ''}
                onChange={(e) => setPriorityValues({ ...priorityValues, weight: e.target.value.replace(/\D/g, '') as unknown as number })}
            />
            <FormControlLabel
                control={(
                    <Checkbox
                        color="primary"
                        checked={priorityValues.isDefault || false}
                        onChange={(e) => setPriorityValues({ ...priorityValues, isDefault: e.target.checked })}
                    />
                )}
                name="По умолчанию"
                label="По умолчанию"
            />
            <ButtonPrimary isLoading={isLoading} isEditing={isEditing} />
        </Box>
    );
};
