import { createAsyncThunk } from '@reduxjs/toolkit';
import { House } from './types';
import axios from '../../axios';
import { houseActions } from './slice';

type DataI = {
  id: string;
  body: House;
}

export const getHouses = createAsyncThunk<House[]>(
    'getHouses',
    async (body, { dispatch }) => {
        await dispatch(houseActions.resetHouse());
        const res = await axios.get('geo/house');
        return res.data.data;
    },
);

export const createHouse = createAsyncThunk<House, House>(
    'createHouse',
    async (body, { rejectWithValue }) => {
        try {
            const res = await axios.post('geo/house', body);
            return res.data;
        } catch (err: any) {
            return rejectWithValue(err.response.data);
        }
    },
);

export const deleteHouse = createAsyncThunk<string, string | null>(
    'deleteHouse',
    async (id, { dispatch, rejectWithValue }) => {
        try {
            const res = await axios.delete(`geo/house/${id}`);
            await dispatch(getHouses());
            return res.data.data;
        } catch (err: any) {
            return rejectWithValue(err.response.data);
        }
    },
);

export const updateHouse = createAsyncThunk<House, DataI>(
    'updateHouse',
    async ({ id, body }, { rejectWithValue }) => {
        try {
            const res = await axios.patch(`geo/house/${id}`, body);
            return await res.data.data;
        } catch (err: any) {
            return rejectWithValue(err.response.data);
        }
    },
);
export const getHouseById = createAsyncThunk<House, string>(
    'getHouseById',
    async (id) => {
        const res = await axios.get(`geo/house/${id}`);
        return res.data.data;
    },
);
