import React from 'react';
import { useNavigate } from 'react-router-dom';
import { DashboardItem } from '../../../../redux/dashboard/types';
import styles from '../../../Client/Tickets/Ticket/Ticket.module.css';
import css from './DashboardTicket.module.css';
import { HistoryItem } from '../../Ticket/TicketFull/HistoryItem';
import { Tag } from '../../../../components/Panel/Tag';

interface DashboardItemProps {
  item: DashboardItem;
}
export const DashboardTicket: React.FC<DashboardItemProps> = ({ item }) => {
    const navigate = useNavigate();
    const handleNavigateToTicket = () => {
        navigate(`/bo/ticket/${item.id}`);
    };

    return (
        <li className={css.doc} onClick={handleNavigateToTicket}>
            <div className={css.header}>
                <div className={css.top}>
                    <div className={css.number}>
                        №
                        {item.number}
                    </div>
                    <div className={css.status} style={{ backgroundColor: item.status.backgroundColor, color: item.status.textColor }}>{item.status.name}</div>
                    {item.isOverdue && <Tag color="#CF0627" text="Просрочено" />}
                </div>
                <div className={css.type}>
                    <span className={css.label}>Адрес: </span>
                    {item.addressString}
                </div>
            </div>
            {item.lastLogs && (
                <div className={css.text}>
                    {
                        item.lastLogs.map((log) => (
                            <div key={log[0].id} className={styles.infoWrapper}>
                                <HistoryItem logs={log} />
                            </div>
                        ))
                    }
                </div>
            )}
        </li>
    );
};
