import React from 'react';
import { Categories } from '../../../components/Panel/Directories/Categories/Categories';
import { CategoryForm } from '../../../components/Panel/Directories/Categories/CategoryForm';
import { Priorities } from '../../../components/Panel/Directories/Priorities/Priorities';
import { PriorityForm } from '../../../components/Panel/Directories/Priorities/PriorityForm';
import { AssignmentRules } from '../../../components/Panel/Directories/AssignmentRules/AssignmentRules';
import { AssignmentRuleForm } from '../../../components/Panel/Directories/AssignmentRules/AssignmentRuleForm';

export const settingsRouting = [
    {
        path: 'ticket-category',
        component: <Categories />,
        form: <CategoryForm />,
    },
    {
        path: 'ticket-priority',
        component: <Priorities />,
        form: <PriorityForm />,
    },
    {
        path: 'ticket-assignment-rule',
        component: <AssignmentRules />,
        form: <AssignmentRuleForm />,
    },
];
