import React from 'react';
import { Input } from '../../../../components/Client/ui/Input';
import { ReadingInputProps } from './ReadingInput.props';
import styles from './ReadingInput.module.css';

export const ReadingInput: React.FC<ReadingInputProps> = ({ prevReadings, placeholder }) => (
    <div className={styles.wrapper}>
        <Input className={styles.input} placeholder={placeholder} />
        <p className={styles.prevReadings}>
            Предыдущие показания&nbsp;
            {prevReadings}
        </p>
    </div>
);
