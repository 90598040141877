import React, { useCallback, useLayoutEffect } from 'react';
import { useSelector } from 'react-redux';

import { useAppDispatch } from '../../../../redux/store';
import { EmptyTable } from '../../EmptyTable';
import { Preloader } from '../../../Client/ui/Preloader';
import { Position } from './Position';

import { DirectoryPage } from '../DirectoryPage';
import {
    getPositionsSelector,
    getIsLoadingSelector,
    getIdToDeleteSelector,
    getErrorsSelector, getStatusSelector,
} from '../../../../redux/positions/selectors';
import { deletePosition, getPositions } from '../../../../redux/positions/asyncThunks';

const prioritiesInfo = {
    path: '/bo/directories/position/create',
    title: 'Должности',
    text: 'должность',
};

const prioritiesHeadings = ['Имя', 'Действия'];

export const Positions: React.FC = () => {
    const dispatch = useAppDispatch();
    const positions = useSelector(getPositionsSelector);
    const isLoading = useSelector(getIsLoadingSelector);
    const idToDelete = useSelector(getIdToDeleteSelector);
    const errors = useSelector(getErrorsSelector);
    const status = useSelector(getStatusSelector);

    useLayoutEffect(() => {
        dispatch(getPositions());
    }, []);

    const modalHandler = useCallback(() => {
        dispatch(deletePosition(idToDelete));
    }, [idToDelete]);

    return (
        <DirectoryPage
            headings={prioritiesHeadings}
            info={prioritiesInfo}
            modalHandler={modalHandler}
            errors={errors}
            status={status}
        >
            {isLoading
                ? <Preloader />
                : (
                    <ul>
                        {positions.length > 0
                            ? positions.map((i) => <Position key={i.id} position={i} />)
                            : <EmptyTable />}
                    </ul>
                )}
        </DirectoryPage>

    );
};
