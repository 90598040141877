import React from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import { EditButton } from '../../Buttons/EditButton';
import { RedButton } from '../../Buttons/RedButton';
import { DirectoryItemProps } from './DirectoryItem.props';

import styles from './DirectoryItem.module.css';

export const DirectoryItem: React.FC<DirectoryItemProps> = ({
    item, handleEditButtonClick, handleDeleteButtonClick,
}) => (
    <li className={styles.item}>
        <span className={styles.name}>{item?.name || ''}</span>
        <div className={styles.buttonWrapper}>
            <EditButton onClick={() => handleEditButtonClick(item?.id)} />
            <RedButton onClick={() => handleDeleteButtonClick(item?.id)}>
                <DeleteIcon fontSize="small" />
            </RedButton>
        </div>
    </li>
);
