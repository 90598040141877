import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { initialStateI } from './types';
import {
    createDocument, getDocumentById, getDocuments, updateDocument,
} from './asyncThunks';
import { StatusEnum } from '../../interfaces/StatusEnum';

const initialState: initialStateI = {
    document: null,
    documents: [],
    status: null,
    errors: [],
    formErrors: [],
    isLoading: false,
    idToDelete: null,
};

export const documentSlice = createSlice({
    name: 'documents',
    initialState,
    reducers: {
        setIdToDelete(state, action) {
            state.idToDelete = action.payload;
        },
        resetDocument(state) {
            state.document = null;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getDocuments.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(getDocuments.fulfilled, (state, action) => {
            state.documents = action.payload;
            state.errors = [];
            state.isLoading = false;
            state.status = null;
        });
        builder.addCase(getDocuments.rejected, (state) => {
            state.isLoading = false;
        });
        builder.addCase(createDocument.pending, (state) => {
            state.status = StatusEnum.PENDING;
            state.isLoading = true;
        });
        builder.addCase(createDocument.fulfilled, (state) => {
            state.status = StatusEnum.SUCCESS;
            state.formErrors = [];
            state.isLoading = false;
        });
        builder.addCase(createDocument.rejected, (state, action: PayloadAction<any>) => {
            state.status = StatusEnum.ERROR;
            state.formErrors = action.payload.errors;
            state.isLoading = false;
        });
        builder.addCase(getDocumentById.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(getDocumentById.fulfilled, (state, action) => {
            state.formErrors = [];
            state.isLoading = false;
            state.document = action.payload;
        });
        builder.addCase(getDocumentById.rejected, (state, action: PayloadAction<any>) => {
            state.formErrors = action.payload.errors;
            state.isLoading = false;
        });
        builder.addCase(updateDocument.fulfilled, (state, action) => {
            state.formErrors = [];
            state.status = StatusEnum.SUCCESS;
        });
        builder.addCase(updateDocument.rejected, (state, action: PayloadAction<any>) => {
            state.formErrors = action.payload.errors;
        });
    },
});

export const { reducer: documentReducer, actions: documentActions } = documentSlice;
