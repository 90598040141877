import React, {
    ChangeEvent,
    FormEvent, useEffect, useState,
} from 'react';
import { Modal, ModalClose, ModalDialog } from '@mui/joy';
import Box from '@mui/material/Box';
import { useSelector } from 'react-redux';
import { TextField } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { ruRU } from '@mui/x-date-pickers/locales';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import dayjs from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { ButtonPrimary } from '../Buttons/ButtonPrimary';
import { useAppDispatch } from '../../../redux/store';
import {
    getStatusSelector,
    getIsLoadingSelector,
    getErrorsSelector,
    getFilesEndTicketSelector,
} from '../../../redux/tickets/selectors';
import { StatusEnum } from '../../../interfaces/StatusEnum';
import { Preloader } from '../../Client/ui/Preloader';
import { ErrorsAlert } from '../ErrorAlert';
import styles from './ModalByAction.module.css';
import { Uploader } from '../../Client/ui/Uploader/Uploader';
import {
    cancelStatus,
    doneStatus,
    endStatus, returnInWorkStatus,
    uploadEndTicketFile,
    waitingStatus,
} from '../../../redux/tickets/asyncThunks';
import { ticketActions } from '../../../redux/tickets/slice';
import { TicketActionsEnum } from '../../../interfaces/TicketActionsEnum';
import { formatDateToUtc } from '../../../utils/helpers/formatDate';

type componentProps = {
    isOpen: boolean;
    onClose: () => void;
    ticketId: string;
    action: TicketActionsEnum;
}

export const ModalByAction = ({
    isOpen, onClose, ticketId, action,
}: componentProps) => {
    const dispatch = useAppDispatch();
    const isLoading = useSelector(getIsLoadingSelector);
    const errors = useSelector(getErrorsSelector);
    const status = useSelector(getStatusSelector);

    const [comment, setComment] = useState<string>();
    const [plannedEndedAt, setPlannedEndedAt] = useState<any>(null);
    const [files, setFiles] = useState<File[]>([]);

    useEffect(() => {
        dispatch(ticketActions.resetFilesModalByAction());
    }, []);

    useEffect(() => {
        if (status === StatusEnum.SUCCESS) {
            onClose();
            setComment('');
        }
    }, [status]);

    const getTextByAction = () => {
        switch (action) {
        case TicketActionsEnum.RETURN_IN_WORK:
            return 'Вернуть в работу';
        case TicketActionsEnum.DELAY:
            return 'Отложить заявку';
        case TicketActionsEnum.CANCEL:
            return 'Отменить заявку';
        case TicketActionsEnum.DONE:
            return 'Завершить заявку';
        default:
            return null;
        }
    };

    const handleCloseModal = () => {
        onClose();
    };

    const onChangeComment = (e: ChangeEvent<HTMLInputElement>) => {
        setComment(e.target.value);
    };

    const handleSubmit = async (e: FormEvent) => {
        e.preventDefault();
        for (let i = 0; i < files.length; i += 1) {
            await dispatch(uploadEndTicketFile({ file: files[i] }));
        }
        switch (action) {
        case TicketActionsEnum.DELAY:
            dispatch(waitingStatus({ id: ticketId, comment, plannedEndedAt: formatDateToUtc(plannedEndedAt) }));
            break;
        case TicketActionsEnum.CANCEL:
            dispatch(cancelStatus({ id: ticketId, comment }));
            break;
        case TicketActionsEnum.DONE:
            dispatch(doneStatus({ id: ticketId, comment }));
            break;
        case TicketActionsEnum.RETURN_IN_WORK:
            dispatch(returnInWorkStatus({ id: ticketId, comment }));
            break;
        default:
            console.log('');
        }
    };

    return (
        <Modal open={isOpen} onClose={handleCloseModal}>
            <ModalDialog sx={{ backgroundColor: 'white', width: '90%', maxWidth: '500px' }}>
                <ModalClose sx={{ top: -40, right: -20 }} />
                <Box
                    component="form"
                    onSubmit={handleSubmit}
                    className="form"
                    noValidate
                >
                    {errors?.length > 0 && <ErrorsAlert errors={errors} />}
                    {isLoading ? <Preloader />
                        : (
                            <>
                                <h4 className={styles.heading}>{getTextByAction()}</h4>
                                <div className={styles.commentWrapper}>
                                    <span className={styles.commentCaption}>{`Комментарий к работе ${action !== TicketActionsEnum.RETURN_IN_WORK ? '(по желанию)' : ''}`}</span>
                                    <TextField
                                        placeholder="Введите комментарий"
                                        value={comment || ''}
                                        onChange={onChangeComment}
                                    />
                                </div>
                                {action === TicketActionsEnum.DELAY && (
                                    <LocalizationProvider
                                        dateAdapter={AdapterDayjs}
                                        adapterLocale="ru"
                                        localeText={ruRU.components.MuiLocalizationProvider.defaultProps.localeText}
                                    >
                                        <DateTimePicker
                                            ampm={false}
                                            format="DD.MM.YYYY HH:mm"
                                            label="Срок для исполнителя"
                                            value={plannedEndedAt}
                                            onChange={(newValue) => setPlannedEndedAt(newValue)}
                                        />
                                    </LocalizationProvider>
                                )}
                                <div className={styles.filesWrapper}>
                                    <Uploader files={files} setFiles={setFiles} />
                                    {(files?.length === 0)
                                      && <div className={styles.emptyFiles}>Вы еще не загрузили файлы</div>}

                                </div>
                                <ButtonPrimary isLoading={isLoading}>
                                    {getTextByAction()}
                                </ButtonPrimary>
                            </>
                        )}
                </Box>
            </ModalDialog>
        </Modal>
    );
};
