import axios from 'axios';

const instance = axios.create({
    baseURL: 'https://api.cloverapp.ru/v1/',
    // baseURL: 'http://localhost:8080/v1/',
});

instance.interceptors.request.use((config) => {
    if (config.headers) {
        config.headers.Authorization = `Bearer ${window.localStorage.getItem('token')}`;
    }
    return config;
});

export default instance;
