import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../../axios';
import { Rule } from './types';

type InputData = {
  categoryId: string | null,
  departmentId: string | null,
}

export const getRules = createAsyncThunk<Rule[]>(
    'getRules',
    async () => {
        const res = await axios.get('ba/helpdesk/ticket-assignment-rule');
        return res.data.data;
    },
);

export const createRule = createAsyncThunk<Rule, InputData>(
    'createRule',
    async (body, { rejectWithValue }) => {
        try {
            const res = await axios.post('ba/helpdesk/ticket-assignment-rule', body);
            return res.data;
        } catch (err: any) {
            return rejectWithValue(err.response.data);
        }
    },
);

export const deleteRule = createAsyncThunk<string, string | null>(
    'deleteRule',
    async (id, { dispatch, rejectWithValue }) => {
        try {
            const res = await axios.delete(`ba/helpdesk/ticket-assignment-rule/${id}`);
            await dispatch(getRules());
            return res.data.data;
        } catch (err: any) {
            return rejectWithValue(err.response.data);
        }
    },
);
