import React from 'react';
import { Box } from '@mui/material';

export const Tag = ({ text, color }: {text: string, color: string}) => (
    <Box sx={{
        p: '5px', borderRadius: '8px', textTransform: 'uppercase', border: `2px solid ${color}`, color, fontSize: '13px', width: 'fit-content',
    }}
    >
        {text}
    </Box>
);
