import { ExecutorI } from './Executor';
import { AttachmentI } from './Attachment';

export enum LogTypeEnum {
  COMMENT = 100,
  PRIORITY = 200,
  PLANNED_ENDED_AT = 300,
  PLANNED_FINISHED_AT = 310,
  STATUS = 400,
  ATTACHMENT = 500,
  ASSIGN_EXECUTOR = 600,
  CHANGE_CATEGORY = 800,
}

interface LogAttachment {
  id: string,
  url: string,
}

export interface LogItem {
  id: string,
  author: ExecutorI,
  type: LogTypeEnum,
  text: string,
  textPrev: string | null,
  attachments: LogAttachment[],
  createdAt: string,
}
