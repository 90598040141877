import cn from 'classnames';
import { InputProps } from './Input.props';
import styles from './Input.module.css';

export const Input: React.FC<InputProps> = ({ className, withHomeIcon = false, ...props }) => (
    <input
        type="text"
        className={cn(styles.input, className, {
            [styles.withIcon]: withHomeIcon,
        })}
        {...props}
    />
);
