import React from 'react';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { styled } from '@mui/system';
import { ToggleGroupProps } from './ToggleGroup.props';

const CustomToggleButtonGroup = styled(ToggleButtonGroup)(() => ({
    '& .MuiToggleButton-root': {
        borderWidth: '1px',
        color: 'black',
        backgroundColor: 'white',
        '&:hover': {
            backgroundColor: 'white',
            opacity: '0.7',
        },
        '&.Mui-selected': {
            color: 'white',
            backgroundColor: 'var(--green)',
            '&:hover': {
                backgroundColor: 'var(--green)',
            },
        },

    },
}));

export const ToggleGroup = ({ value, onChange, buttonData }: ToggleGroupProps) => (
    <CustomToggleButtonGroup value={value} aria-label="toggle-group">
        {buttonData.map((b) => (
            <ToggleButton key={b.value} value={b.value} onClick={() => onChange(b.value)}>
                {b.label}
            </ToggleButton>
        ))}
    </CustomToggleButtonGroup>
);
