import { RootState } from '../../store';

export const getNumberFilterSelector = (state: RootState) => state?.ticketReducer?.numberFilterValue;
export const getStatusFilterSelector = (state: RootState) => state?.ticketReducer?.statusFilterValue;
export const getPriorityFilterSelector = (state: RootState) => state?.ticketReducer?.priorityFilterValue;
export const getAddressFilterSelector = (state: RootState) => state?.ticketReducer?.adressFilterValue;
export const getRoomFilterSelector = (state: RootState) => state?.ticketReducer?.roomFilterValue;
export const getExecutorFilterSelector = (state: RootState) => state?.ticketReducer?.executorFilterValue || null;
export const getCategoryFilterSelector = (state: RootState) => state?.ticketReducer?.categoryFilterValue;
export const getCreatedAtFilterSelector = (state: RootState) => state?.ticketReducer?.createdAtFilterValue;
export const getPlannedEndedAtFilterSelector = (state: RootState) => state?.ticketReducer?.plannedEndedAtFilterValue;
