import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getMe, login } from './asyncThunks';
import { initialStateI } from './types';
import { StatusEnum } from '../../interfaces/StatusEnum';

const initialState: initialStateI = {
    isLogged: !!localStorage.getItem('token'),
    username: '',
    errors: [],
    status: null,
    token: null,
    permissions: [],
};

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setUserName(state, action: PayloadAction<string>) {
            state.username = action.payload;
        },
        signout(state) {
            state.isLogged = false;
            state.status = null;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(login.pending, (state) => {
            state.status = StatusEnum.PENDING;
        });
        builder.addCase(login.fulfilled, (state, action) => {
            localStorage.setItem('token', action.payload.token);
            localStorage.setItem('isPanel', 'true');
            state.isLogged = true;
            state.errors = [];
            state.status = StatusEnum.SUCCESS;
            state.token = action.payload.token;
        });
        builder.addCase(login.rejected, (state, action: PayloadAction<any>) => {
            state.username = '';
            state.isLogged = false;
            state.errors = action.payload.errors;
            state.status = StatusEnum.ERROR;
            state.token = null;
        });
        builder.addCase(getMe.fulfilled, (state, action) => {
            state.username = action.payload.name;
            state.permissions = action.payload.permissions;
        });
        builder.addCase(getMe.rejected, (state, action: PayloadAction<any>) => {
            state.username = '';
        });
    },
});

export const { reducer: authReducer, actions: authActions } = authSlice;
