import { useEffect, useState } from 'react';
import cn from 'classnames';
import styles from './Rating.module.css';
import { ReactComponent as RateStarsIcon } from '../../../../assets/img/rateStar.svg';
import { ReactComponent as RateStarsIconFilled } from '../../../../assets/img/rateStar-filled.svg';

export const Rating = ({ isEditable = true }) => {
    const [ratingArray, setRatingArray] = useState<JSX.Element[]>(new Array(5).fill(<></>));
    const [rating, setRating] = useState(0);

    useEffect(() => {
        constructRating(rating);
    }, [rating]);

    const changeDisplay = (i: number) => {
        if (!isEditable || !setRating) {
            return;
        }
        constructRating(i);
    };

    const onClick = (i: number) => {
        if (!isEditable || !setRating) {
            return;
        }
        setRating(i);
    };

    const constructRating = (currentRating: number) => {
        const updatedArray = ratingArray.map((r: JSX.Element, index: number) => (
            <span
                onMouseEnter={() => changeDisplay(index + 1)}
                onMouseLeave={() => changeDisplay(rating)}
                onClick={() => onClick(index + 1)}
                className={cn(styles.star, {
                    [styles.editable]: isEditable,
                })}
            >
                { index < currentRating
                    ? <RateStarsIconFilled />
                    : <RateStarsIcon />}
            </span>
        ));
        setRatingArray(updatedArray);
    };
    return (
        <div className={styles.wrapper}>
            {ratingArray.map((rating, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <span key={index}>{rating}</span>
            ))}
        </div>
    );
};
