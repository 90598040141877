import React, {
    FormEvent, useEffect,
} from 'react';
import { Modal, ModalClose, ModalDialog } from '@mui/joy';
import Box from '@mui/material/Box';
import {
    Button, InputLabel, TextField,
} from '@mui/material';
import { useSelector } from 'react-redux';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { SelectChangeEvent } from '@mui/material/Select';
import dayjs from 'dayjs';
import { ruRU } from '@mui/x-date-pickers/locales';
import { ModalTicketFiltersProps } from './ModalTicketFilters.props';
import { useAppDispatch } from '../../../redux/store';
import {
    getStatusSelector,
    getCustomerErrorsSelector,
    getAvailablePrioritiesSelector,
    getNumberFilterSelector,
    getStatusFilterSelector,
    getPriorityFilterSelector,
    getPlannedEndedAtFilterSelector,
    getCreatedAtFilterSelector,
    getAddressesSelector,
    getCategoriesSelector,
    getAddressFilterSelector,
    getCategoryFilterSelector,
    getRoomsSelector,
    getRoomFilterSelector, getExecutorFilterSelector,
} from '../../../redux/tickets/selectors';
import { StatusEnum } from '../../../interfaces/StatusEnum';
import { ErrorsAlert } from '../ErrorAlert';
import { ticketActions } from '../../../redux/tickets/slice';
import { statusFilterDropDownItems } from '../../../utils/data/statusFilterDropDownItems';
import {
    filterTickets, getAddresses, getAvailablePriorities, getCategories, getRooms,
} from '../../../redux/tickets/asyncThunks';
import { MultipleDropDownMenu } from '../MultipleDropDownMenu';
import { AutoCompleteMenu } from '../AutoCompleteMenu';
import { MultipleDropDownMenuCategories } from '../MultipleDropDownMenuCategories';
import { AddressI, RoomI } from '../../../interfaces/Tickets';
import 'dayjs/locale/ru';
import { formatDateToUtc } from '../../../utils/helpers/formatDate';
import { getEmployees } from '../../../redux/employees/asyncThunks';
import { getEmployeesSelector } from '../../../redux/employees/selectors';
import { EmployeeI } from '../../../redux/employees/types';

export const ModalTicketFilters: React.FC<ModalTicketFiltersProps> = ({
    isOpen, onClose,
}) => {
    const dispatch = useAppDispatch();
    const errors = useSelector(getCustomerErrorsSelector);
    const status = useSelector(getStatusSelector);
    const addresses = useSelector(getAddressesSelector);
    const rooms = useSelector(getRoomsSelector);
    const executors = useSelector(getEmployeesSelector);
    const availableCategories = useSelector(getCategoriesSelector);
    const availablePriorities = useSelector(getAvailablePrioritiesSelector);
    const numberFilterValue = useSelector(getNumberFilterSelector);
    const statusFilterValue = useSelector(getStatusFilterSelector);
    const priorityFilterValue = useSelector(getPriorityFilterSelector);
    const addressFilterValue = useSelector(getAddressFilterSelector);
    const roomFilterValue = useSelector(getRoomFilterSelector);
    const executorFilterValue = useSelector(getExecutorFilterSelector);
    const categoryFilterValue = useSelector(getCategoryFilterSelector);
    const plannedEndedAt = useSelector(getPlannedEndedAtFilterSelector);
    const createdAtFilterValue = useSelector(getCreatedAtFilterSelector);

    useEffect(() => {
        if (status === StatusEnum.SUCCESS) {
            onClose();
        }
    }, [status]);

    useEffect(() => {
        dispatch(getAvailablePriorities());
        dispatch(getAddresses());
        dispatch(getCategories());
        dispatch(getEmployees('1'));
    }, []);

    const handleCloseModal = () => {
        onClose();
    };

    const parentsCategories = availableCategories?.filter((i) => !i.parent);
    const childCategories = availableCategories?.filter((i) => i.parent);
    let resCategories: any[] = [];
    parentsCategories.forEach((i) => {
        const childs = childCategories.filter((c: any) => c.parent.id === i.id);
        resCategories = [...resCategories, i, ...childs];
    });

    const handleSubmit = async (e: FormEvent) => {
        e.preventDefault();
        const body = {
            page: 1,
            number: numberFilterValue || null,
            statuses: statusFilterValue.map((i) => +i) || [],
            priorityIds: priorityFilterValue || [],
            createdAtFrom: createdAtFilterValue[0] ? formatDateToUtc(dayjs(createdAtFilterValue[0]!.$d).format('YYYY-MM-DD')) : null,
            createdAtTo: createdAtFilterValue[1] ? formatDateToUtc(dayjs(createdAtFilterValue[1]!.$d).format('YYYY-MM-DD')) : null,
            plannedEndedAtFrom: plannedEndedAt[0] ? formatDateToUtc(dayjs(plannedEndedAt[0]!.$d).format('YYYY-MM-DD')) : null,
            plannedEndedAtTo: plannedEndedAt[1] ? formatDateToUtc(dayjs(plannedEndedAt[1]!.$d).format('YYYY-MM-DD')) : null,
            houseId: addressFilterValue?.id || null,
            roomId: roomFilterValue?.id || null,
            categoryIds: categoryFilterValue || null,
            executorId: executorFilterValue?.id || null,
        };
        dispatch(filterTickets(body));
        handleCloseModal();
    };

    const handleChangeStatusFilter = (event: SelectChangeEvent<typeof statusFilterValue>) => {
        const { target: { value } } = event;
        dispatch(ticketActions.setStatusFilterValue(typeof value === 'string' ? value.split(',') : value));
    };

    const handleChangePriorityFilter = (event: SelectChangeEvent<typeof priorityFilterValue>) => {
        const { target: { value } } = event;
        dispatch(ticketActions.setPriorityFilterValue(typeof value === 'string' ? value.split(',') : value));
    };

    const handleChangeAddressFilter = (newValue: AddressI) => {
        dispatch(ticketActions.setAddressFilterValue(newValue));
        dispatch(ticketActions.setRoomFilterValue(null));
        dispatch(getRooms(newValue.id));
    };

    const handleChangeRoomFilter = (newValue: RoomI) => {
        dispatch(ticketActions.setRoomFilterValue(newValue));
    };
    const handleChangeExecutorFilter = (newValue: EmployeeI) => {
        dispatch(ticketActions.setExecutorFilterValue(newValue));
    };

    const handleChangeCategoryFilter = (event: SelectChangeEvent<typeof categoryFilterValue>) => {
        const { target: { value } } = event;
        dispatch(ticketActions.setCategoryFilterValue(typeof value === 'string' ? value.split(',') : value));
    };

    const resetFilters = () => {
        dispatch(ticketActions.resetFilters());
    };

    return (
        <Modal
            open={isOpen}
            onClose={handleCloseModal}
            disableEnforceFocus
            sx={{
                overflow: 'scroll', p: '30px',
            }}
        >
            <ModalDialog sx={{
                backgroundColor: 'white',
                width: '70%',
                maxWidth: '900px',
                height: 'fit-content',
                top: 50,
                bottom: '',
                transform: 'translateX(-50%)',
                marginBottom: '10px',
            }}
            >
                {errors.length > 0 && <ErrorsAlert errors={errors} />}
                <ModalClose sx={{ top: -10, right: -45 }} />
                <Box
                    component="form"
                    onSubmit={handleSubmit}
                    className="form"
                    noValidate
                >
                    <AutoCompleteMenu value={addressFilterValue} options={addresses} setter={handleChangeAddressFilter} label="Адрес" isAddress />
                    <AutoCompleteMenu value={roomFilterValue} options={rooms} setter={handleChangeRoomFilter} label="Квартира" />
                    <TextField
                        label="По номеру"
                        value={numberFilterValue}
                        onChange={(e) => dispatch(ticketActions.setNumberFilterValue(e.target.value))}
                    />
                    <MultipleDropDownMenu
                        items={statusFilterDropDownItems}
                        value={statusFilterValue}
                        onChange={handleChangeStatusFilter}
                        label="По статусу"
                    />
                    <MultipleDropDownMenuCategories
                        items={resCategories}
                        value={categoryFilterValue}
                        onChange={handleChangeCategoryFilter}
                        label="Категория"
                    />
                    <MultipleDropDownMenu
                        items={availablePriorities}
                        value={priorityFilterValue}
                        onChange={handleChangePriorityFilter}
                        label="По приоритету"
                    />
                    <AutoCompleteMenu
                        value={executorFilterValue}
                        options={executors.map((e) => ({ ...e, name: e.fullName }))}
                        setter={handleChangeExecutorFilter}
                        label="По исполнителю"
                    />
                    <LocalizationProvider
                        dateAdapter={AdapterDayjs}
                        adapterLocale="ru"
                        localeText={ruRU.components.MuiLocalizationProvider.defaultProps.localeText}
                    >
                        <InputLabel>Дата создания</InputLabel>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <DatePicker
                                sx={{ width: '47%' }}
                                format="DD.MM.YYYY"
                                label="От"
                                value={createdAtFilterValue[0]}
                                onChange={(newValue) => dispatch(ticketActions.setCreatedAtFilterValue([newValue, createdAtFilterValue[1]]))}
                            />
                            <span> - </span>
                            <DatePicker
                                format="DD.MM.YYYY"
                                sx={{ width: '47%' }}
                                label="До"
                                value={createdAtFilterValue[1]}
                                onChange={(newValue) => dispatch(ticketActions.setCreatedAtFilterValue([createdAtFilterValue[0], newValue]))}
                            />
                        </Box>
                        <InputLabel>Срок для исполнителя</InputLabel>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <DatePicker
                                sx={{ width: '47%' }}
                                format="DD.MM.YYYY"
                                label="От"
                                value={plannedEndedAt[0]}
                                onChange={(newValue) => dispatch(ticketActions.setPlannedEndedAtFilterValue([newValue, plannedEndedAt[1]]))}
                            />
                            <span> - </span>
                            <DatePicker
                                format="DD.MM.YYYY"
                                sx={{ width: '47%' }}
                                label="До"
                                value={plannedEndedAt[1]}
                                onChange={(newValue) => dispatch(ticketActions.setPlannedEndedAtFilterValue([plannedEndedAt[0], newValue]))}
                            />
                        </Box>
                    </LocalizationProvider>
                    <Box sx={{
                        display: 'flex', flexDirection: 'row', mt: 2, gap: '10px',
                    }}
                    >
                        <Button
                            type="button"
                            variant="contained"
                            onClick={resetFilters}
                        >
                            Очистить фильтры
                        </Button>
                        <Button
                            type="submit"
                            color="success"
                            variant="contained"
                        >
                            Применить фильтры
                        </Button>
                    </Box>
                </Box>
            </ModalDialog>
        </Modal>
    );
};
