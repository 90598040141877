import React from 'react';
import { Positions } from '../../../components/Panel/Directories/Positions/Positions';
import { PositionForm } from '../../../components/Panel/Directories/Positions/PositionForm';
import { Departments } from '../../../components/Panel/Directories/Departments/Departments';
import { DepartmentForm } from '../../../components/Panel/Directories/Departments/DepartmentForm';
import { Roles } from '../../../components/Panel/Directories/Roles/Roles';
import { RoleForm } from '../../../components/Panel/Directories/Roles/RoleForm';
import { Employees } from '../../../components/Panel/Directories/Employees/Employees';
import { EmployeesForm } from '../../../components/Panel/Directories/Employees/EmployeesForm';

export const employeeRouting = [
    {
        path: '/position',
        component: <Positions />,
        form: <PositionForm />,
    },
    {
        path: '/department',
        component: <Departments />,
        form: <DepartmentForm />,
    },
    {
        path: '/role',
        component: <Roles />,
        form: <RoleForm />,
    },
    {
        path: '/employee',
        component: <Employees />,
        form: <EmployeesForm />,
    },
];
