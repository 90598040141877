import React, {
    useLayoutEffect, useState,
} from 'react';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import { useSelector } from 'react-redux';

import { useParams } from 'react-router-dom';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { useAppDispatch } from '../../../../redux/store';
import { ErrorsAlert } from '../../ErrorAlert';
import { ButtonPrimary } from '../../Buttons/ButtonPrimary';
import { useSuccessNavigate } from '../../../../utils/hooks/useSuccessNavigate';
import { DropDownMenu } from '../../DropDownMenu';

import { Preloader } from '../../../Client/ui/Preloader';
import {
    getAvailablePositionsSelector, getAvailableRolesSelector,
    getEmployeeSelector,
    getFormErrorsSelector,
    getIsLoadingSelector,
    getStatusSelector,
    getAvailableDepartmentsSelector,
} from '../../../../redux/employees/selectors';
import {
    createEmployee,
    getAvailableDepartments,
    getAvailablePositions,
    getAvailableRoles, getEmployeeById, updateEmployee,
} from '../../../../redux/employees/asyncThunks';
import { employeeActions } from '../../../../redux/employees/slice';
import { onPhoneInput, onPhoneKeyDown, onPhonePaste } from '../../../../utils/helpers/handlePhoneInput';

export const EmployeesForm = () => {
    const { id } = useParams();
    const isEditing = Boolean(id);
    const errors = useSelector(getFormErrorsSelector);
    const status = useSelector(getStatusSelector);
    const isLoading = useSelector(getIsLoadingSelector);
    const employee = useSelector(getEmployeeSelector);
    const availableDepartments = useSelector(getAvailableDepartmentsSelector);
    const departmentsToPick = availableDepartments.map((i) => ({ ...i, name: i.fullName }));
    const availablePositions = useSelector(getAvailablePositionsSelector);
    const availableRoles = useSelector(getAvailableRolesSelector);

    const dispatch = useAppDispatch();
    useSuccessNavigate('/bo/employee/employee', status);
    const [password, setPassword] = useState<string>();

    useLayoutEffect(() => {
        dispatch(getAvailableDepartments());
        dispatch(getAvailablePositions());
        dispatch(getAvailableRoles());
        if (isEditing && id) {
            dispatch(getEmployeeById(id));
        }
    }, []);

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const body = {
            phone: employee.user.phone.replace(/\D/g, ''),
            lastName: employee.lastName,
            firstName: employee.firstName,
            username: employee.user.username,
            password,
            departmentId: employee.department.id,
            positionId: employee.position.id,
            roles: employee.user.roles,
        };
        if (isEditing && id) {
            dispatch(updateEmployee({ id, body }));
        } else {
            dispatch(createEmployee(body));
        }
    };

    const handleCheck = (item: string) => {
        const index = employee.user.roles.indexOf(item);
        if (index !== -1) {
            dispatch(employeeActions.setEmployee({ ...employee, user: { ...employee.user, roles: employee.user.roles.filter((i) => i !== item) } }));
        } else {
            dispatch(employeeActions.setEmployee({ ...employee, user: { ...employee.user, roles: [...employee.user.roles, item] } }));
        }
    };

    const onPhoneChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = onPhoneInput(e);
        dispatch(employeeActions.setEmployee({ ...employee, user: { ...employee.user, phone: newValue } }));
    };

    if ((isEditing && !employee.id) || isLoading) {
        return <Preloader />;
    }

    return (
        <Box
            component="form"
            onSubmit={handleSubmit}
            className="form"
            noValidate
        >
            {errors.length > 0 && <ErrorsAlert errors={errors} />}
            <TextField
                id="phone"
                label="Телефон"
                name="phone"
                required
                variant="standard"
                value={employee?.user.phone || ''}
                type="tel"
                placeholder="+7 (___) ___-__-__"
                onKeyDown={onPhoneKeyDown}
                onPaste={onPhonePaste}
                onChange={onPhoneChange}
            />
            <TextField
                id="lastName"
                label="Фамилия"
                name="lastName"
                required
                variant="standard"
                value={employee.lastName || ''}
                onChange={(e) => dispatch(employeeActions.setEmployee({ ...employee, lastName: e.target.value }))}
            />
            <TextField
                id="firstName"
                label="Имя"
                name="firstName"
                required
                variant="standard"
                value={employee.firstName || ''}
                onChange={(e) => dispatch(employeeActions.setEmployee({ ...employee, firstName: e.target.value }))}
            />
            <TextField
                id="username"
                label="Имя пользователя"
                name="username"
                required
                variant="standard"
                value={employee.user.username || ''}
                onChange={(e) => dispatch(employeeActions.setEmployee({ ...employee, user: { ...employee.user, username: e.target.value } }))}
            />
            {!isEditing
              && (
                  <TextField
                      id="password"
                      label="Пароль"
                      name="password"
                      required
                      type="password"
                      variant="standard"
                      value={password || ''}
                      onChange={(e) => setPassword(e.target.value)}
                  />
              )}
            <DropDownMenu
                label="Выберите департамент"
                value={employee.department.id || ''}
                handleChange={(e) => dispatch(employeeActions.setEmployee({ ...employee, department: { ...employee.department, id: e.target.value } }))}
                items={departmentsToPick}
            />
            <DropDownMenu
                label="Выберите должность"
                value={employee.position.id || ''}
                handleChange={(e) => dispatch(employeeActions.setEmployee({ ...employee, position: { ...employee.position, id: e.target.value } }))}
                items={availablePositions}
            />
            {availableRoles.map((p) => (
                <FormControlLabel
                    key={p.name}
                    control={(
                        <Checkbox
                            color="primary"
                            checked={employee.user.roles.includes(p.id)}
                            onChange={() => handleCheck(p.id)}
                        />
                    )}
                    name={p.name}
                    label={p.name}
                />
            ))}
            <ButtonPrimary isLoading={isLoading} isEditing={isEditing} />
        </Box>
    );
};
