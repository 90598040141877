import { Link, useLocation } from 'react-router-dom';
import useReactRouterBreadcrumbs from 'use-react-router-breadcrumbs';
import { crumbRoutes } from '../../../utils/data/crumbRoutes';
import styles from './BreadCrumbs.module.css';

export const BreadCrumbs = () => {
    const crumbs = useReactRouterBreadcrumbs(crumbRoutes).filter((i) => i.key !== '/');
    const { pathname } = useLocation();

    return (
        <ul className={styles.crumbsList}>
            {crumbs.map(({ match, breadcrumb }) => (
                <li key={match.pathname} className={styles.crumb}>
                    {pathname === match.pathname
                        ? <span className={styles.currentCrumb}>{breadcrumb}</span>
                        : <Link to={match.pathname}>{breadcrumb}</Link>}
                </li>
            ))}
        </ul>
    );
};
