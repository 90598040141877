import React from 'react';
import { MeterItemProps } from './MeterItem.props';
import styles from './MeterItem.module.css';

export const MeterItem: React.FC<MeterItemProps> = ({
    title, value, meterIcon, meterBgIcon,
}) => (
    <li className={styles.meter}>
        <h3 className={styles.title}>
            {title}
            {meterIcon}
        </h3>
        <p className={styles.value}>{value}</p>
        <div className={styles.bgIconWrapper}>
            {meterBgIcon}
        </div>
    </li>
);
