import React from 'react';
import cn from 'classnames';
import { TagProps } from './Tag.props';
import { ReactComponent as CheckIcon } from '../../../../assets/img/check.svg';
import styles from './Tag.module.css';

export const Tag: React.FC<TagProps> = ({
    color, children, isChecked = false, className, ...props
}) => (
    <div
        className={cn(styles.tag, className, {
            [styles.red]: color === 'red',
            [styles.green]: color === 'green',
        })}
        {...props}
    >
        <>
            { isChecked ? (
                <>
                    <CheckIcon />
                    {children}
                </>
            ) : children}
        </>
    </div>
);
