import React from 'react';
import { useNavigate } from 'react-router-dom';
import { SettlementProps } from './Settlement.props';
import { useAppDispatch } from '../../../../redux/store';
import { settlementActions } from '../../../../redux/settlements/slice';
import { getSettlementById } from '../../../../redux/settlements/asyncThunks';
import { directoryActions } from '../../../../redux/directories/slice';
import { DirectoryItem } from '../DirectoryItem';

export const Settlement: React.FC<SettlementProps> = ({ settlement }) => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const handleDeleteButtonClick = (id?: string | null) => {
        dispatch(directoryActions.setIsModalOpen());
        dispatch(settlementActions.setIdToDelete(id));
    };

    const handleEditButtonClick = (id? : string | null) => {
        if (id) {
            navigate(`${id}/update`);
        }
    };

    return (
        <DirectoryItem
            item={settlement}
            handleEditButtonClick={handleEditButtonClick}
            handleDeleteButtonClick={handleDeleteButtonClick}
        />
    );
};
