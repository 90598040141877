import * as React from 'react';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import { ListSubheader } from '@mui/material';

type componentProps = {
  items: Array<{id: string, name: string, parent: {name: string, id: string}}>,
  value: Array<string>,
  onChange: (event: SelectChangeEvent<Array<string>>) => void,
  label: string,
}

export const MultipleDropDownMenuCategories = ({
    items, value, onChange, label,
}: componentProps) => {
    const obj: Record<string, string> = {};
    items.map((item) => obj[item.id] = item.name);
    return (
        <div>
            <FormControl sx={{ width: '100%' }}>
                <InputLabel>{label}</InputLabel>
                <Select
                    multiple
                    value={value}
                    onChange={onChange}
                    input={<OutlinedInput label={label} />}
                    renderValue={(s) => s.map((item) => obj[item]).join(', ')}
                    MenuProps={{
                        style: { zIndex: 35001 },
                    }}
                >
                    {items.map((item) => (item.parent ? (
                        <MenuItem key={item.id} value={item.id}>
                            <Checkbox checked={value.indexOf(item.id) > -1} />
                            <ListItemText primary={item.name} />
                        </MenuItem>
                    ) : (
                        <ListSubheader key={item.id}>{item.name}</ListSubheader>
                    )))}
                </Select>
            </FormControl>
        </div>
    );
};
