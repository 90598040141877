import React from 'react';
import cn from 'classnames';
import { RedButtonProps } from './RedButton.props';
import styles from './RedButton.module.css';

export const RedButton: React.FC<RedButtonProps> = ({ className, children, ...props }) => (
    <button
        type="button"
        className={cn(styles.button, className)}
        {...props}
    >
        {children}
    </button>
);
