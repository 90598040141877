import React, { useCallback, useLayoutEffect } from 'react';
import { useSelector } from 'react-redux';

import { useAppDispatch } from '../../../../redux/store';
import { EmptyTable } from '../../EmptyTable';
import { Preloader } from '../../../Client/ui/Preloader';
import { Employee } from './Employee';

import { DirectoryPage } from '../DirectoryPage';

import { activeEmployee, blockEmployee, getEmployees } from '../../../../redux/employees/asyncThunks';
import {
    getEmployeesSelector,
    getErrorsSelector,
    getIdToDeleteSelector,
    getStatusSelector,
    getIsLoadingSelector, getIsActiveModalOpenSelector, getIsPasswordModalOpenSelector,
} from '../../../../redux/employees/selectors';
import { ModalConfirm } from '../../ModalConfirm';
import { employeeActions } from '../../../../redux/employees/slice';
import { ModalChangePassword } from '../../ModalChangePassword';

const employeesInfo = {
    title: 'Сотрудники',
    text: 'сотрудника',
};

const departmentsHeadings = ['Имя Фамилия', 'Должность', 'Статус', 'Действия'];
export const Employees: React.FC = () => {
    const dispatch = useAppDispatch();
    const employees = useSelector(getEmployeesSelector);
    const isLoading = useSelector(getIsLoadingSelector);
    const idToBlock = useSelector(getIdToDeleteSelector);
    const errors = useSelector(getErrorsSelector);
    const status = useSelector(getStatusSelector);
    const isActiveModalOpen = useSelector(getIsActiveModalOpenSelector);
    const isPasswordModalOpen = useSelector(getIsPasswordModalOpenSelector);

    useLayoutEffect(() => {
        dispatch(getEmployees());
    }, []);

    const onBlockModalHandler = useCallback(() => {
        dispatch(blockEmployee(idToBlock));
    }, [idToBlock]);

    const onActiveModalHandler = useCallback(() => {
        dispatch(activeEmployee(idToBlock));
    }, [idToBlock]);

    const onCloseActiveModal = () => {
        dispatch(employeeActions.setIsActiveModalOpen(false));
    };

    const onClosePasswordModal = () => {
        dispatch(employeeActions.setIsPasswordModalOpen(false));
    };

    return (
        <>

            <DirectoryPage
                headings={departmentsHeadings}
                info={employeesInfo}
                modalHandler={onBlockModalHandler}
                errors={errors}
                status={status}
            >
                {isLoading
                    ? <Preloader />
                    : (
                        <ul>
                            {employees.length > 0
                                ? employees.map((i) => <Employee key={i.id} employee={i} />)
                                : <EmptyTable />}
                        </ul>
                    )}
            </DirectoryPage>
            <ModalConfirm
                handler={onActiveModalHandler}
                isOpen={isActiveModalOpen}
                onClose={onCloseActiveModal}
                errors={errors}
                status={status}
            />
            <ModalChangePassword
                isOpen={isPasswordModalOpen}
                onClose={onClosePasswordModal}
            />
        </>
    );
};
