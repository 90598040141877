import React from 'react';
import { Input } from '../../../components/Client/ui/Input';
import { Button } from '../../../components/Client/ui/Button';
import { onPhoneInput, onPhoneKeyDown, onPhonePaste } from '../../../utils/helpers/handlePhoneInput';
import { ReactComponent as PhoneIcon } from '../../../assets/img/phone.svg';
import styles from './Login.module.css';

export const Login = () => (
    <div className={styles.flexWrapper}>
        <div className={styles.wrapper}>
            <div className={styles.icon} />
            <div className={styles.inputWrapper}>
                <h2 className={styles.title}>Личный кабинет</h2>
                <p className={styles.subtitle}>Введите ваш номер, чтобы войти или зарегистрироваться</p>
                <label htmlFor="phone" className={styles.label}>
                    Номер телефона
                </label>
                <Input
                    onKeyDown={onPhoneKeyDown}
                    onChange={onPhoneInput}
                    onPaste={onPhonePaste}
                    type="tel"
                    id="phone"
                    className={styles.input}
                    placeholder="+7 (___) ___-__-__"
                />
                <Button appearance="primary" className={styles.button} type="submit">Войти</Button>
                <p className={styles.caption}>
                    Нажимая кнопку «Войти», вы даете&nbsp;
                    <a className={styles.link} href="https://#" target="_blank" rel="noreferrer">Согласие&nbsp;</a>
                    с условиями&nbsp;
                    <a className={styles.link} href="https://#" target="_blank" rel="noreferrer">
                        Политики об
                        обработке защиты персональных данных
                    </a>
                </p>
                <div className={styles.supportPhoneWrapper}>
                    <div className={styles.supportPhoneCapture}>
                        <PhoneIcon />
                        Служба поддержки
                    </div>
                    <a href="tel:+78128883311" className={styles.supportPhone}>
                        +7 (812) 888-33-11
                    </a>
                </div>
            </div>
        </div>
    </div>
);
