import InboxIcon from '@mui/icons-material/MoveToInbox';
import React from 'react';

export const sideBarSettingsLinks = [
    {
        path: 'ticket-category',
        name: 'Категории',
        icon: <InboxIcon />,
    },
    {
        path: 'ticket-priority',
        name: 'Приоритеты',
        icon: <InboxIcon />,
    },
    {
        path: 'ticket-assignment-rule',
        name: 'Правила назначения',
        icon: <InboxIcon />,
    },
];
