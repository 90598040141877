import {
    YMaps, Map, FullscreenControl, Polygon,
} from '@pbe/react-yandex-maps';
import React, { useLayoutEffect, useRef, useState } from 'react';
import {
    Autocomplete, Box, Button, TextField,
} from '@mui/material';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { Balloon } from '../../../../components/Panel/Balloon';
import { Preloader } from '../../../../components/Client/ui/Preloader';
import { getTickets } from '../../../../redux/tickets/asyncThunks';
import { useAppDispatch } from '../../../../redux/store';
import { getIsLoadingSelector } from '../../../../redux/tickets/selectors';
import { ModalTicketFilters } from '../../../../components/Panel/ModalTicketFilters';

const mapData = {
    center: [55.798616, 37.939861],
    zoom: 15,
};

const MAX_ZOOM = 16;

export const TicketMap = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const isLoading = useSelector(getIsLoadingSelector);

    const ymaps = useRef<any>(null);
    const map = useRef<any>(null);
    const [balloonTop, setBalloonTop] = useState<number>(0);
    const [balloonLeft, setBalloonLeft] = useState<number>(0);
    const [isBalloonOpen, setIsBalloonOpen] = useState(false);
    const [isFilterModalOpen, setIsFilterModalOpen] = useState(false);

    useLayoutEffect(() => {
        dispatch(getTickets());
    }, []);

    let idPromise: Promise<string>;
    const zoom = (coords: [number, number]) => {
        map.current.setCenter(coords, MAX_ZOOM);
    };

    const onHouseClick = async (e: any) => {
        const id = await idPromise;
        if (id) {
            // await dispatch(getHouseInfo(id));
            setBalloonTop(e.clientY - 170);
            setBalloonLeft(e.clientX);
            setIsBalloonOpen(true);
        }
    };

    const onPolygonClick = (id: string) => {
        idPromise = new Promise((res) => {
            res(id);
        });
    };

    const handleOpenFilterModal = () => {
        setIsFilterModalOpen(true);
    };

    const handleCloseFilterModal = () => {
        setIsFilterModalOpen(false);
    };

    if (isLoading) {
        return (
            <Box sx={{ height: '100vh', display: 'flex', alignItems: 'center' }}>
                <Preloader />
            </Box>
        );
    }

    return (
        <YMaps>
            <div onClick={onHouseClick}>
                <Map
                    width="100%"
                    height="100vh"
                    defaultState={mapData}
                    options={{ maxZoom: MAX_ZOOM }}
                    modules={['control.ZoomControl',
                        'geoObject.addon.balloon',
                        'GeoObjectCollection',
                        'Placemark',
                    ]}
                    onLoad={(api) => {
                        ymaps.current = api;
                    }}
                    instanceRef={map}
                >
                    <Button
                        sx={{
                            position: 'absolute', left: '20px', top: '70px', zIndex: 1000,
                        }}
                        onClick={handleOpenFilterModal}
                        variant="contained"
                    >
                        Фильтры
                    </Button>

                    {/* {houses.length > 0 && houses.map((item) => ( */}
                    {/*    <Polygon */}
                    {/*        key={item.id} */}
                    {/*        geometry={item.coordinates} */}
                    {/*        options={{ */}
                    {/*            fillColor: item.hasActiveTicket ? '#e30000' : '#00FF00', */}
                    {/*            opacity: 0.8, */}
                    {/*        }} */}
                    {/*        onClick={() => onPolygonClick(item.id)} */}
                    {/*    /> */}
                    {/* ))} */}
                </Map>
            </div>
            {/*    {isBalloonOpen && currentHouse */}
            {/* && ( */}
            {/*    <Balloon */}
            {/*        top={balloonTop} */}
            {/*        left={balloonLeft} */}
            {/*        house={currentHouse} */}
            {/*        handleOpenApartmentModal={handleOpenApartmentModal} */}
            {/*        handleOpenEmergencyModal={handleOpenEmergencyModal} */}
            {/*        handleOpenEmergencyTableModal={handleOpenEmergencyTableModal} */}
            {/*        isLoading={isLoading} */}
            {/*        handleCloseBalloon={() => setIsBalloonOpen(false)} */}
            {/*    /> */}
            {/* )} */}
            <ModalTicketFilters isOpen={isFilterModalOpen} onClose={handleCloseFilterModal} />
        </YMaps>
    );
};
