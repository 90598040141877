import React, { useEffect, useState } from 'react';
import cn from 'classnames';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';
import { useNavigate } from 'react-router-dom';
import styles from './TicketsPage.module.css';
import { Ticket } from '../../../Client/Tickets/Ticket/Ticket';
import { useAppDispatch } from '../../../../redux/store';
import {
    getAddressFilterSelector,
    getCategoryFilterSelector,
    getCreatedAtFilterSelector, getCurrentPageSelector, getExecutorFilterSelector,
    getIsLoadingSelector,
    getNumberFilterSelector,
    getPlannedEndedAtFilterSelector,
    getPriorityFilterSelector, getRoomFilterSelector,
    getStatusFilterSelector,
    getSuccessCreatedSelector,
    getTicketsSelector, getTotalPagesSelector,
} from '../../../../redux/tickets/selectors';
import { filterTickets, updateTickets } from '../../../../redux/tickets/asyncThunks';
import { Preloader } from '../../../../components/Client/ui/Preloader';
import { DirectoryTopInfo } from '../../../../components/Panel/DirectoryTopInfo/DirectoryTopInfo';
import { ModalTicketFilters } from '../../../../components/Panel/ModalTicketFilters';
import { EmptyTable } from '../../../../components/Panel/EmptyTable';
import { ticketActions } from '../../../../redux/tickets/slice';
import { TicketSuccess } from '../TicketSuccess/TicketSuccess';
import { Paginator } from '../../../../components/Client/ui/Paginator/Paginator';

export const TicketPage: React.FC = () => {
    const [isFilterModalOpen, setIsFilterModalOpen] = useState(false);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const tickets = useSelector(getTicketsSelector);
    const isLoading = useSelector(getIsLoadingSelector);
    const numberFilterValue = useSelector(getNumberFilterSelector);
    const statusFilterValue = useSelector(getStatusFilterSelector);
    const priorityFilterValue = useSelector(getPriorityFilterSelector);
    const addressFilterValue = useSelector(getAddressFilterSelector);
    const roomFilterValue = useSelector(getRoomFilterSelector);
    const categoryFilterValue = useSelector(getCategoryFilterSelector);
    const executorFilterValue = useSelector(getExecutorFilterSelector);
    const createdAtFilterValue = useSelector(getCreatedAtFilterSelector);
    const plannedEndedAt = useSelector(getPlannedEndedAtFilterSelector);
    const isSuccessScreen = useSelector(getSuccessCreatedSelector);
    const currentPage = useSelector(getCurrentPageSelector);
    const totalPages = useSelector(getTotalPagesSelector);

    useEffect(() => {
        dispatch(filterTickets({
            page: currentPage,
            number: numberFilterValue || null,
            statuses: statusFilterValue.map((i) => +i) || [],
            priorityIds: priorityFilterValue || [],
            createdAtFrom: createdAtFilterValue[0] ? dayjs(createdAtFilterValue[0]!.$d).format('YYYY-MM-DDTHH:mm:ss') : null,
            createdAtTo: createdAtFilterValue[1] ? dayjs(createdAtFilterValue[1]!.$d).format('YYYY-MM-DDTHH:mm:ss') : null,
            plannedEndedAtFrom: plannedEndedAt[0] ? dayjs(plannedEndedAt[0]!.$d).format('YYYY-MM-DDTHH:mm:ss') : null,
            plannedEndedAtTo: plannedEndedAt[1] ? dayjs(plannedEndedAt[1]!.$d).format('YYYY-MM-DDTHH:mm:ss') : null,
            houseId: addressFilterValue?.id || null,
            roomId: roomFilterValue?.id || null,
            categoryIds: categoryFilterValue || null,
            executorId: executorFilterValue?.id || null,
        }));
    }, [currentPage]);

    useEffect(() => {
        const intervalId = setInterval(() => {
            if (!isFilterModalOpen) {
                dispatch(updateTickets({
                    page: currentPage,
                    number: numberFilterValue || null,
                    statuses: statusFilterValue.map((i) => +i) || [],
                    priorityIds: priorityFilterValue || [],
                    createdAtFrom: createdAtFilterValue[0] ? dayjs(createdAtFilterValue[0]!.$d).format('YYYY-MM-DDTHH:mm:ss') : null,
                    createdAtTo: createdAtFilterValue[1] ? dayjs(createdAtFilterValue[1]!.$d).format('YYYY-MM-DDTHH:mm:ss') : null,
                    plannedEndedAtFrom: plannedEndedAt[0] ? dayjs(plannedEndedAt[0]!.$d).format('YYYY-MM-DDTHH:mm:ss') : null,
                    plannedEndedAtTo: plannedEndedAt[1] ? dayjs(plannedEndedAt[1]!.$d).format('YYYY-MM-DDTHH:mm:ss') : null,
                    houseId: addressFilterValue?.id || null,
                    roomId: roomFilterValue?.id || null,
                    categoryIds: categoryFilterValue || null,
                    executorId: executorFilterValue?.id || null,
                }));
            }
        }, 5000);

        return () => clearInterval(intervalId);
    }, [dispatch, isFilterModalOpen, currentPage]);

    useEffect(() => {
        dispatch(ticketActions.resetStatus());

        return () => {
            dispatch(ticketActions.resetFilters());
        };
    }, []);

    const handleOpenFilterModal = () => {
        setIsFilterModalOpen(true);
    };

    const handleCloseFilterModal = () => {
        setIsFilterModalOpen(false);
    };

    const setCurrentPage = (page: number) => {
        dispatch(ticketActions.setCurrentPage(page));
    };

    const handleNavigateToMap = () => {
        navigate('map');
    };

    if (isSuccessScreen) {
        return <TicketSuccess />;
    }

    if (isLoading) {
        return <Preloader />;
    }

    return (
        <main className="content">
            <DirectoryTopInfo
                title="Заявки"
                text="Создать заявку"
                withFilterButton
                withoutButton
                filterButtonHandler={handleOpenFilterModal}
                mapButtonHandler={handleNavigateToMap}
            />

            <div className={styles.table}>
                <ul className={styles.headings}>
                    <li className={styles.heading}>
                        № Заявки
                    </li>
                    <li className={styles.heading}>
                        Создано
                    </li>
                    <li className={styles.heading}>
                        Срок
                    </li>
                    <li className={styles.heading}>
                        Приоритет
                    </li>
                    <li className={styles.heading}>
                        Статус
                    </li>
                    <li className={cn(styles.heading, styles.headingAppeal)}>
                        Категория
                    </li>
                    <li className={styles.heading}>
                        Адрес
                    </li>
                </ul>
                <ul className={styles.tickets}>
                    {tickets.length > 0
                        ? (
                            <>
                                {tickets.map((ticket) => (
                                    <Ticket
                                        key={ticket.id}
                                        id={ticket.id}
                                        number={ticket.number}
                                        status={ticket.status}
                                        appeal={ticket.category.name}
                                        addressString={ticket.addressString}
                                        priority={ticket.priority}
                                        createdAt={dayjs(ticket.createdAt).format('DD.MM.YYYY HH:mm')}
                                        plannedFinishedAt={dayjs(ticket.plannedFinishedAt).format('DD.MM.YYYY HH:mm')}
                                        customer={ticket.customer}
                                        isOverdue={ticket.isOverdue}
                                    />
                                ))}
                            </>
                        )
                        : <EmptyTable />}
                </ul>
                {totalPages > 1 && <Paginator currentPage={currentPage} totalPages={totalPages} setCurrentPage={setCurrentPage} />}
            </div>
            {isFilterModalOpen && <ModalTicketFilters isOpen={isFilterModalOpen} onClose={handleCloseFilterModal} />}
        </main>
    );
};
