import React, {
    FormEvent, useEffect, useLayoutEffect, useState,
} from 'react';
import { Modal, ModalClose, ModalDialog } from '@mui/joy';
import Box from '@mui/material/Box';
import { useSelector } from 'react-redux';
import { SelectChangeEvent } from '@mui/material/Select';
import { ButtonPrimary } from '../Buttons/ButtonPrimary';
import { useAppDispatch } from '../../../redux/store';
import { changeCategory, getAvailableCategories } from '../../../redux/tickets/asyncThunks';
import {
    getStatusSelector, getIsLoadingSelector, getErrorsSelector,
} from '../../../redux/tickets/selectors';
import { StatusEnum } from '../../../interfaces/StatusEnum';
import { DropDownMenu } from '../DropDownMenu';
import { Preloader } from '../../Client/ui/Preloader';
import { ErrorsAlert } from '../ErrorAlert';
import { CategoryI, TicketI } from '../../../interfaces/Tickets';
import { getAvailableCategoriesSelector } from '../../../redux/tickets/selectors/getAvailableCategoriesSelector';

type componentProps = {
    isOpen: boolean;
    onClose: () => void;
    ticketId: string;
    currentTicket: TicketI;
}

export const ModalEditCategory = ({
    isOpen, onClose, ticketId, currentTicket,
}: componentProps) => {
    const dispatch = useAppDispatch();
    const isLoading = useSelector(getIsLoadingSelector);
    const errors = useSelector(getErrorsSelector);
    const status = useSelector(getStatusSelector);

    const categories = useSelector(getAvailableCategoriesSelector);
    const parentCategories = categories.filter((i) => !i.parent);
    const [childCategories, setChildCategories] = React.useState<CategoryI[]>([]);
    const [childCategory, setChildCategory] = React.useState<string>('');

    const [category, setCategory] = React.useState<string>('');
    const onChangeCategory = (e: SelectChangeEvent) => setCategory(e.target.value);
    const onChangeChildCategory = (e: SelectChangeEvent) => setChildCategory(e.target.value);

    useEffect(() => {
        if (status === StatusEnum.SUCCESS) {
            onClose();
            setChildCategory('');
        }
    }, [status]);
    useEffect(() => {
        if (categories.length > 0 && category) {
            setChildCategories(categories.filter((c) => c?.parent?.id === category));
        }
    }, [parentCategories]);

    useLayoutEffect(() => {
        dispatch(getAvailableCategories());
    }, []);

    const handleCloseModal = () => {
        onClose();
    };

    const handleSubmit = (e: FormEvent) => {
        e.preventDefault();
        // const plannedFinishedAtFormatted = dayjs(plannedFinishedAt.$d).format('YYYY-MM-DDTHH:mm:ss');
        // const plannedEndedAtFormatted = dayjs(plannedEndedAt).format('YYYY-MM-DDTHH:mm:ss');
        if (childCategory) {
            dispatch(changeCategory({
                id: ticketId,
                categoryId: childCategory,
            }));
        }
    };

    return (
        <Modal open={isOpen} onClose={handleCloseModal}>
            <ModalDialog sx={{ backgroundColor: 'white' }}>
                <ModalClose sx={{ top: -40, right: -20 }} />
                <Box
                    component="form"
                    onSubmit={handleSubmit}
                    className="form"
                    noValidate
                >
                    {errors?.length > 0 && <ErrorsAlert errors={errors} />}
                    {isLoading ? <Preloader />
                        : (
                            <>
                                <DropDownMenu
                                    label="Категория"
                                    items={parentCategories}
                                    value={category}
                                    handleChange={onChangeCategory}
                                />
                                {category && (
                                    <DropDownMenu
                                        label="Подкатегория"
                                        items={childCategories}
                                        value={childCategory}
                                        handleChange={onChangeChildCategory}
                                    />
                                )}

                                <ButtonPrimary isLoading={isLoading} isEditing />
                            </>
                        )}
                </Box>
            </ModalDialog>
        </Modal>
    );
};
