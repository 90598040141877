import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { groupBy } from 'lodash';
import { InitialStateI } from './types';
import {
    cancelStatus,
    changeExecutor,
    changeCategory,
    changePriority,
    createTicket,
    doneStatus,
    endStatus,
    filterTickets, getActiveTicketsByAddress,
    getAddresses,
    getAvailableExecutors,
    getAvailableCategories,
    getAvailablePriorities,
    getCategories,
    getCustomers,
    getLogs,
    getRooms,
    getTicketById,
    getTickets,
    returnStatus,
    sendComment,
    startStatus,
    updateTickets,
    uploadEndTicketFile,
    waitingStatus,
    returnInWorkStatus, changeCustomer,
} from './asyncThunks';
import { StatusEnum } from '../../interfaces/StatusEnum';
import { uploadFile } from '../uploader/asyncThunks';
import { createCustomer, updateCustomer } from '../customer/asyncThunks';

const initialState: InitialStateI = {
    isLoading: false,
    ticketIsLoading: false,
    status: null,
    addresses: [],
    rooms: [],
    customers: [],
    errors: [],
    newCustomerErrors: [],
    categories: [],
    currentCustomerId: null,
    tickets: [],
    ticketFormValues: {
        categoryId: '',
        text: '',
        houseId: '',
        roomId: '',
        customerId: null,
        files: [],
        priorityId: '',
    },
    ticket: null,
    logs: [],
    files: [],
    availableExecutors: [],
    availableCategories: [],
    availablePriorities: [],
    numberFilterValue: '',
    executorFilterValue: null,
    statusFilterValue: [],
    createdAtFilterValue: [null, null],
    plannedEndedAtFilterValue: [null, null],
    priorityFilterValue: [],
    adressFilterValue: null,
    roomFilterValue: null,
    categoryFilterValue: [],
    ticketNumber: '',
    filesModalByAction: [],
    successCreated: false,
    activeTicketsByAddress: [],
    activeTicketsByAddressLoading: false,
    createdNowCustomers: [],
    currentPage: 1,
    totalPages: 1,
};

export const ticketSlice = createSlice({
    name: 'ticketSlice',
    initialState,
    reducers: {
        setCurrentCustomerId(state, action: PayloadAction<string>) {
            state.currentCustomerId = action.payload;
        },
        resetStatus(state) {
            state.status = null;
        },
        resetCustomerErrors(state) {
            state.newCustomerErrors = [];
        },
        setTicket(state, action) {
            state.ticketFormValues.houseId = action.payload.houseId;
            state.ticketFormValues.roomId = action.payload.roomId;
            state.ticketFormValues.customerId = action.payload.customerId;
            state.ticketFormValues.categoryId = action.payload.categoryId;
            state.ticketFormValues.text = action.payload.text;
            state.ticketFormValues.priorityId = action.payload.priorityId;
        },
        resetTicket(state) {
            state.ticketFormValues.houseId = '';
            state.ticketFormValues.roomId = '';
            state.ticketFormValues.customerId = null;
            state.ticketFormValues.categoryId = '';
            state.ticketFormValues.text = '';
            state.ticketFormValues.priorityId = '';
            state.activeTicketsByAddress = [];
        },
        setNumberFilterValue(state, action) {
            state.numberFilterValue = action.payload;
        },
        setExecutorFilterValue(state, action) {
            state.executorFilterValue = action.payload;
        },
        setStatusFilterValue(state, action) {
            state.statusFilterValue = action.payload;
        },
        setPriorityFilterValue(state, action) {
            state.priorityFilterValue = action.payload;
        },
        setCreatedAtFilterValue(state, action) {
            state.createdAtFilterValue = action.payload;
        },
        setPlannedEndedAtFilterValue(state, action) {
            state.plannedEndedAtFilterValue = action.payload;
        },
        setAddressFilterValue(state, action) {
            state.adressFilterValue = action.payload;
        },
        setRoomFilterValue(state, action) {
            state.roomFilterValue = action.payload;
        },
        setCategoryFilterValue(state, action) {
            state.categoryFilterValue = action.payload;
        },
        resetFilters(state) {
            state.numberFilterValue = '';
            state.statusFilterValue = [];
            state.createdAtFilterValue = [null, null];
            state.plannedEndedAtFilterValue = [null, null];
            state.priorityFilterValue = [];
            state.adressFilterValue = null;
            state.roomFilterValue = null;
            state.categoryFilterValue = [];
            state.currentPage = 1;
        },
        resetSuccessCreated(state) {
            state.successCreated = false;
        },
        resetFilesModalByAction(state) {
            state.filesModalByAction = [];
            state.errors = [];
        },
        setCurrentPage(state, action: PayloadAction<number>) {
            state.currentPage = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getAddresses.pending, (state) => {
            state.ticketIsLoading = true;
        });
        builder.addCase(getAddresses.rejected, (state, action: PayloadAction<any>) => {
            state.ticketIsLoading = false;
            state.errors = action.payload;
        });
        builder.addCase(getAddresses.fulfilled, (state, action) => {
            state.ticketIsLoading = false;
            state.addresses = action.payload;
        });
        builder.addCase(getRooms.pending, (state) => {
            state.ticketIsLoading = true;
        });
        builder.addCase(getRooms.rejected, (state, action: PayloadAction<any>) => {
            state.ticketIsLoading = false;
            state.errors = action.payload;
        });
        builder.addCase(getRooms.fulfilled, (state, action) => {
            state.ticketIsLoading = false;
            state.rooms = action.payload;
        });
        builder.addCase(getCustomers.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(getCustomers.rejected, (state, action: PayloadAction<any>) => {
            state.isLoading = false;
            state.errors = action.payload;
        });
        builder.addCase(getCustomers.fulfilled, (state, action) => {
            state.isLoading = false;
            state.customers = action.payload;
        });
        builder.addCase(getCategories.pending, (state) => {
            state.ticketIsLoading = true;
        });
        builder.addCase(getCategories.rejected, (state, action: PayloadAction<any>) => {
            state.ticketIsLoading = false;
            state.errors = action.payload;
        });
        builder.addCase(getCategories.fulfilled, (state, action) => {
            state.ticketIsLoading = false;
            state.categories = action.payload;
        });
        builder.addCase(createCustomer.fulfilled, (state, action) => {
            state.isLoading = false;
            state.newCustomerErrors = [];
            state.customers = [...state.customers, action.payload];
            state.currentCustomerId = action.payload.id;
        });
        builder.addCase(updateCustomer.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(updateCustomer.rejected, (state, action: PayloadAction<any>) => {
            state.isLoading = false;
            state.newCustomerErrors = action.payload.errors;
        });
        builder.addCase(updateCustomer.fulfilled, (state, action) => {
            state.isLoading = false;
            state.newCustomerErrors = [];
            state.customers = [...state.customers.filter((i) => i.id !== action.payload.id), action.payload];
            state.currentCustomerId = action.payload.id;
            state.createdNowCustomers.push(action.payload.id);
        });
        builder.addCase(createTicket.pending, (state) => {
            state.isLoading = true;
            state.status = StatusEnum.PENDING;
            state.successCreated = false;
        });
        builder.addCase(createTicket.rejected, (state, action: PayloadAction<any>) => {
            state.isLoading = false;
            state.status = StatusEnum.ERROR;
            state.errors = action.payload.errors;
            state.successCreated = false;
        });
        builder.addCase(createTicket.fulfilled, (state, action) => {
            state.isLoading = false;
            state.status = StatusEnum.SUCCESS;
            state.errors = [];
            state.tickets = [...state.tickets, action.payload];
            state.ticketNumber = action.payload.number;
            state.successCreated = true;
        });
        builder.addCase(uploadFile.pending, (state) => {
            state.status = StatusEnum.PENDING;
        });
        builder.addCase(uploadFile.fulfilled, (state, action: PayloadAction<any>) => {
            state.errors = [];
            state.ticketFormValues.files = [...state.ticketFormValues.files, action.payload.id];
        });
        builder.addCase(uploadFile.rejected, (state, action: PayloadAction<any>) => {
            state.errors = action.payload.errors;
            state.status = StatusEnum.ERROR;
        });
        builder.addCase(uploadEndTicketFile.pending, (state) => {
            state.status = StatusEnum.PENDING;
        });
        builder.addCase(uploadEndTicketFile.fulfilled, (state, action: PayloadAction<any>) => {
            state.errors = [];
            state.filesModalByAction = [...state.filesModalByAction, action.payload.id];
        });
        builder.addCase(uploadEndTicketFile.rejected, (state, action: PayloadAction<any>) => {
            state.errors = action.payload.errors;
            state.status = StatusEnum.ERROR;
        });
        builder.addCase(getTickets.pending, (state) => {
            state.status = StatusEnum.PENDING;
            state.isLoading = true;
        });
        builder.addCase(getTickets.fulfilled, (state, action) => {
            state.errors = [];
            state.tickets = action.payload.data;
            state.isLoading = false;
            state.successCreated = false;
            state.totalPages = action.payload.meta.totalPages;
            state.currentPage = action.payload.meta.currentPage;
        });
        builder.addCase(getTickets.rejected, (state, action: PayloadAction<any>) => {
            state.errors = action.payload.errors;
            state.isLoading = false;
        });
        builder.addCase(getTicketById.pending, (state) => {
            state.ticketIsLoading = true;
        });
        builder.addCase(getTicketById.fulfilled, (state, action) => {
            state.errors = [];
            state.ticket = action.payload;
            state.ticketIsLoading = false;
        });
        builder.addCase(getTicketById.rejected, (state, action: PayloadAction<any>) => {
            state.errors = action.payload.errors;
            state.ticketIsLoading = false;
        });
        builder.addCase(getAvailableExecutors.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(getAvailableExecutors.fulfilled, (state, action) => {
            state.isLoading = false;
            state.errors = [];
            state.availableExecutors = action.payload;
        });
        builder.addCase(getAvailableExecutors.rejected, (state, action: PayloadAction<any>) => {
            state.errors = action.payload.errors;
            state.isLoading = false;
        });

        builder.addCase(getAvailableCategories.fulfilled, (state, action) => {
            state.errors = [];
            state.availableCategories = action.payload;
        });
        builder.addCase(getAvailableCategories.rejected, (state, action: PayloadAction<any>) => {
            state.errors = action.payload.errors;
        });

        builder.addCase(getAvailablePriorities.fulfilled, (state, action) => {
            state.errors = [];
            state.availablePriorities = action.payload;
        });
        builder.addCase(getAvailablePriorities.rejected, (state, action: PayloadAction<any>) => {
            state.errors = action.payload.errors;
        });

        builder.addCase(changeExecutor.pending, (state) => {
            state.ticketIsLoading = true;
        });
        builder.addCase(changeExecutor.fulfilled, (state, action) => {
            state.ticketIsLoading = false;
            state.errors = [];
            state.ticket = action.payload;
        });
        builder.addCase(changeExecutor.rejected, (state, action: PayloadAction<any>) => {
            state.errors = action.payload.errors;
            state.ticketIsLoading = false;
        });

        builder.addCase(changeCategory.pending, (state) => {
            state.isLoading = true;
            state.status = StatusEnum.PENDING;
        });
        builder.addCase(changeCategory.fulfilled, (state, action) => {
            state.isLoading = false;
            state.errors = [];
            state.ticket = action.payload;
            state.status = StatusEnum.SUCCESS;
        });
        builder.addCase(changeCategory.rejected, (state, action: PayloadAction<any>) => {
            state.errors = action.payload.errors;
            state.isLoading = false;
            state.status = StatusEnum.ERROR;
        });

        builder.addCase(changePriority.pending, (state) => {
            state.isLoading = true;
            state.status = StatusEnum.PENDING;
        });
        builder.addCase(changePriority.fulfilled, (state, action) => {
            state.isLoading = false;
            state.errors = [];
            state.ticket = action.payload;
            state.status = StatusEnum.SUCCESS;
        });
        builder.addCase(changePriority.rejected, (state, action: PayloadAction<any>) => {
            state.errors = action.payload.errors;
            state.isLoading = false;
            state.status = StatusEnum.ERROR;
        });
        builder.addCase(changeCustomer.pending, (state) => {
            state.isLoading = true;
            state.status = StatusEnum.PENDING;
        });
        builder.addCase(changeCustomer.fulfilled, (state, action) => {
            state.isLoading = false;
            state.errors = [];
            state.ticket = action.payload;
            state.status = StatusEnum.SUCCESS;
        });
        builder.addCase(changeCustomer.rejected, (state, action: PayloadAction<any>) => {
            state.errors = action.payload.errors;
            state.isLoading = false;
            state.status = StatusEnum.ERROR;
        });
        builder.addCase(getLogs.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(getLogs.fulfilled, (state, action) => {
            state.isLoading = false;
            state.errors = [];
            const groupObject = groupBy(action.payload, (item) => item.author.fullName + item.createdAt);
            state.logs = Object.keys(groupObject).map((key: string) => groupObject[key]);
            state.status = null;
        });
        builder.addCase(getLogs.rejected, (state, action: PayloadAction<any>) => {
            state.errors = action.payload.errors;
            state.isLoading = false;
        });
        builder.addCase(sendComment.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(sendComment.fulfilled, (state, action) => {
            state.isLoading = false;
            state.errors = [];
            const groupObject = groupBy(action.payload, (item) => item.author.fullName + item.createdAt);
            state.logs = Object.keys(groupObject).map((key: string) => groupObject[key]);
        });
        builder.addCase(sendComment.rejected, (state, action: PayloadAction<any>) => {
            state.errors = action.payload.errors;
            state.isLoading = false;
        });
        builder.addCase(filterTickets.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(filterTickets.fulfilled, (state, action: PayloadAction<any>) => {
            state.isLoading = false;
            state.errors = [];
            state.tickets = action.payload.data;
            state.totalPages = action.payload.meta.totalPages;
            state.currentPage = action.payload.meta.currentPage;
        });
        builder.addCase(filterTickets.rejected, (state, action: PayloadAction<any>) => {
            state.errors = action.payload.errors;
            state.isLoading = false;
        });
        builder.addCase(updateTickets.fulfilled, (state, action: PayloadAction<any>) => {
            state.isLoading = false;
            state.errors = [];
            state.tickets = action.payload.data;
            state.totalPages = action.payload.meta.totalPages;
            state.currentPage = action.payload.meta.currentPage;
        });
        builder.addCase(cancelStatus.pending, (state) => {
            state.status = StatusEnum.PENDING;
        });
        builder.addCase(cancelStatus.fulfilled, (state, action) => {
            state.errors = [];
            state.ticket = action.payload;
            state.status = StatusEnum.SUCCESS;
        });
        builder.addCase(cancelStatus.rejected, (state, action: PayloadAction<any>) => {
            state.errors = action.payload.errors;
            state.status = StatusEnum.ERROR;
        });
        builder.addCase(doneStatus.pending, (state) => {
            state.status = StatusEnum.PENDING;
        });
        builder.addCase(doneStatus.fulfilled, (state, action) => {
            state.errors = [];
            state.ticket = action.payload;
            state.status = StatusEnum.SUCCESS;
        });
        builder.addCase(doneStatus.rejected, (state, action: PayloadAction<any>) => {
            state.errors = action.payload.errors;
            state.status = StatusEnum.ERROR;
        });
        builder.addCase(returnStatus.pending, (state) => {
            state.status = StatusEnum.PENDING;
        });
        builder.addCase(returnStatus.fulfilled, (state, action) => {
            state.errors = [];
            state.ticket = action.payload;
            state.status = StatusEnum.SUCCESS;
        });
        builder.addCase(returnStatus.rejected, (state, action: PayloadAction<any>) => {
            state.errors = action.payload.errors;
            state.status = StatusEnum.ERROR;
        });
        builder.addCase(startStatus.pending, (state) => {
            state.status = StatusEnum.PENDING;
        });
        builder.addCase(startStatus.fulfilled, (state, action) => {
            state.errors = [];
            state.ticket = action.payload;
            state.status = StatusEnum.SUCCESS;
        });
        builder.addCase(startStatus.rejected, (state, action: PayloadAction<any>) => {
            state.errors = action.payload.errors;
            state.status = StatusEnum.ERROR;
        });
        builder.addCase(endStatus.pending, (state) => {
            state.status = StatusEnum.PENDING;
        });
        builder.addCase(endStatus.fulfilled, (state, action) => {
            state.errors = [];
            state.ticket = action.payload;
            state.status = StatusEnum.SUCCESS;
        });
        builder.addCase(endStatus.rejected, (state, action: PayloadAction<any>) => {
            state.errors = action.payload.errors;
            state.status = StatusEnum.ERROR;
        });
        builder.addCase(waitingStatus.pending, (state) => {
            state.status = StatusEnum.PENDING;
        });
        builder.addCase(waitingStatus.fulfilled, (state, action) => {
            state.errors = [];
            state.ticket = action.payload;
            state.status = StatusEnum.SUCCESS;
        });
        builder.addCase(waitingStatus.rejected, (state, action: PayloadAction<any>) => {
            state.errors = action.payload.errors;
            state.status = StatusEnum.ERROR;
        });
        builder.addCase(returnInWorkStatus.pending, (state) => {
            state.status = StatusEnum.PENDING;
        });
        builder.addCase(returnInWorkStatus.fulfilled, (state, action) => {
            state.errors = [];
            state.ticket = action.payload;
            state.status = StatusEnum.SUCCESS;
        });
        builder.addCase(returnInWorkStatus.rejected, (state, action: PayloadAction<any>) => {
            state.errors = action.payload.errors;
            state.status = StatusEnum.ERROR;
        });
        builder.addCase(getActiveTicketsByAddress.pending, (state) => {
            state.activeTicketsByAddressLoading = true;
        });
        builder.addCase(getActiveTicketsByAddress.fulfilled, (state, action) => {
            state.activeTicketsByAddressLoading = false;
            state.activeTicketsByAddress = action.payload;
        });
        builder.addCase(getActiveTicketsByAddress.rejected, (state) => {
            state.activeTicketsByAddressLoading = false;
        });
    },
});

export const { reducer: ticketReducer, actions: ticketActions } = ticketSlice;
