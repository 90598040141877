import React, { useCallback, useLayoutEffect } from 'react';
import { useSelector } from 'react-redux';

import { useAppDispatch } from '../../../../redux/store';
import { EmptyTable } from '../../EmptyTable';
import { Preloader } from '../../../Client/ui/Preloader';
import { Room } from './Room';

import { DirectoryPage } from '../DirectoryPage';
import {
    getRoomsSelector,
    getIsLoadingSelector,
    getIdToDeleteSelector,
    getErrorsSelector, getStatusSelector,
} from '../../../../redux/rooms/selectors';
import { deleteRoom, getRooms } from '../../../../redux/rooms/asyncThunks';
import { getHouses } from '../../../../redux/houses/asyncThunks';

export const roomsInfo = {
    path: '/bo/directories/rooms/create',
    title: 'Квартиры',
    text: 'квартиру',
};

const roomsHeadings = ['Имя', 'Действия'];

export const Rooms: React.FC = () => {
    const dispatch = useAppDispatch();
    const rooms = useSelector(getRoomsSelector);
    const isLoading = useSelector(getIsLoadingSelector);
    const idToDelete = useSelector(getIdToDeleteSelector);
    const errors = useSelector(getErrorsSelector);
    const status = useSelector(getStatusSelector);

    useLayoutEffect(() => {
        dispatch(getHouses());
        dispatch(getRooms());
    }, []);

    const modalHandler = useCallback(() => {
        dispatch(deleteRoom(idToDelete));
    }, [idToDelete]);

    return (
        <DirectoryPage
            headings={roomsHeadings}
            info={roomsInfo}
            modalHandler={modalHandler}
            errors={errors}
            status={status}
        >
            {isLoading
                ? <Preloader />
                : (
                    <ul>
                        {rooms.length > 0
                            ? rooms.map((i) => <Room key={i.id} room={i} />)
                            : <EmptyTable />}
                    </ul>
                )}
        </DirectoryPage>

    );
};
