export const clientNavList = [
    {
        path: '/lk/',
        text: 'Главная',
    },
    {
        path: '/lk/readings',
        text: 'Показания',
    },
    {
        path: '/lk/payments',
        text: 'Платежи',
    },
    {
        path: '/lk/ticket',
        text: 'Заявки',
    },
    {
        path: '/lk/news',
        text: 'Новости',
    },
    {
        path: '/lk/services',
        text: 'Услуги',
    },
];
