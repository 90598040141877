import { createSlice } from '@reduxjs/toolkit';

import { initialStateI } from './types';
import {
    getCustomerByPhone,
} from './asyncThunks';

const initialState: initialStateI = {
    customerByPhone: null,
    isLoading: false,
    isRequested: false,
};

export const customerSlice = createSlice({
    name: 'customerSlice',
    initialState,
    reducers: {
        resetIsRequested(state) {
            state.isRequested = false;
        },
        resetCustomer(state) {
            state.customerByPhone = null;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getCustomerByPhone.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(getCustomerByPhone.fulfilled, (state, action) => {
            state.customerByPhone = action.payload;
            state.isLoading = false;
            state.isRequested = true;
        });
        builder.addCase(getCustomerByPhone.rejected, (state) => {
            state.customerByPhone = null;
            state.isRequested = true;
            state.isLoading = false;
        });
    },
});

export const { reducer: customerReducer, actions: customerActions } = customerSlice;
