import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../../axios';
import { DashboardItem } from './types';

export const getDashboardItems = createAsyncThunk<DashboardItem[]>(
    'getDashboardItems',
    async () => {
        const res = await axios.get('ba/dashboard');
        return res.data.data;
    },
);
