import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../../axios';
import {
    AddressI, CategoryI, CustomerDataI, CustomerI, RoomI, TicketFormValuesI, TicketI,
} from '../../interfaces/Tickets';
import { RootState } from '../store';
import { ExecutorI } from '../../interfaces/Executor';
import { AvailablePriority } from '../../interfaces/AvailablePriority';
import { LogItem } from '../../interfaces/LogItem';
import { FileI } from '../categories/types';

type FilterInputData = {
  page: number,
  statuses: number[] | [],
  number: string | null,
  priorityIds: string[] | [],
  createdAtFrom: string | null,
  createdAtTo: string | null,
  plannedEndedAtFrom: string | null,
  plannedEndedAtTo: string | null,
  houseId: string | null,
  roomId: string | null,
  categoryIds: string[] | null,
  executorId: string | null
}

export const getAddresses = createAsyncThunk<AddressI[]>(
    'getAddresses',
    async () => {
        const res = await axios.get('geo/house/available');
        return res.data.data;
    },
);

export const getRooms = createAsyncThunk<RoomI[], string>(
    'getRooms',
    async (houseId) => {
        const res = await axios.get(`geo/room/available?houseId=${houseId}`);
        return res.data.data;
    },
);

export const getCustomers = createAsyncThunk<CustomerI[], string>(
    'getCustomers',
    async (roomId) => {
        const res = await axios.get(`customer/available?roomId=${roomId}`);
        return res.data.data;
    },
);

export const getCategories = createAsyncThunk<CategoryI[]>(
    'getCategories',
    async () => {
        const res = await axios.get('helpdesk/category/available');
        return res.data.data;
    },
);

export const getActiveTicketsByAddress = createAsyncThunk<any[], {houseId: string, roomId?: string}>(
    'getActiveTicketsByAddress',
    async ({ houseId, roomId }) => {
        const res = await axios.get('ba/helpdesk/ticket/current-by-address', {
            params: {
                houseId,
                roomId,
            },
        });
        return res.data.data;
    },
);

export const createTicket = createAsyncThunk<TicketI, TicketFormValuesI, { state: RootState }>(
    'createTicket',
    async (_, { rejectWithValue, getState }) => {
        try {
            const state = getState();
            const body = state.ticketReducer.ticketFormValues;
            const res = await axios.post('ba/helpdesk/ticket', body);
            return res.data.data;
        } catch (err: any) {
            return rejectWithValue(err.response.data);
        }
    },
);

export const getTickets = createAsyncThunk(
    'getTickets',
    async (_, { rejectWithValue }) => {
        try {
            const res = await axios.get('ba/helpdesk/ticket');
            return res.data;
        } catch (err: any) {
            return rejectWithValue(err.response.data);
        }
    },
);

export const getTicketById = createAsyncThunk<TicketI, string>(
    'getTicketById',
    async (id, { rejectWithValue }) => {
        try {
            const res = await axios.get(`ba/helpdesk/ticket/${id}`);
            return res.data.data;
        } catch (err: any) {
            return rejectWithValue(err.response.data);
        }
    },
);

export const getAvailableExecutors = createAsyncThunk<ExecutorI[], string>(
    'getAvailableExecutors',
    async (id, { rejectWithValue }) => {
        try {
            const res = await axios.get(`ba/helpdesk/ticket/${id}/available-executor`);
            return res.data.data;
        } catch (err: any) {
            return rejectWithValue(err.response.data);
        }
    },
);

export const changeExecutor = createAsyncThunk<TicketI, { id: string, executorId: string }>(
    'changeExecutor',
    async ({ id, executorId }, { rejectWithValue, dispatch }) => {
        try {
            const res = await axios.post(`ba/helpdesk/ticket/${id}/assign-executor`, {
                employeeId: executorId,
            });
            dispatch(getLogs(id));

            return res.data.data;
        } catch (err: any) {
            return rejectWithValue(err.response.data);
        }
    },
);

export const getAvailableCategories = createAsyncThunk<CategoryI[]>(
    'getAvailableCategories',
    async (_, { rejectWithValue }) => {
        try {
            const res = await axios.get('helpdesk/category/available');
            return res.data.data;
        } catch (err: any) {
            return rejectWithValue(err.response.data);
        }
    },
);

export const getAvailablePriorities = createAsyncThunk<AvailablePriority[]>(
    'getAvailablePriorities',
    async (_, { rejectWithValue }) => {
        try {
            const res = await axios.get('ba/helpdesk/priority/available');
            return res.data.data;
        } catch (err: any) {
            return rejectWithValue(err.response.data);
        }
    },
);

export const changeCategory = createAsyncThunk<TicketI, {
  id: string,
  categoryId: string,
}>(
    'changeCategory',
    async ({
        id, categoryId,
    }, { rejectWithValue, dispatch }) => {
        try {
            const res = await axios.post(`ba/helpdesk/ticket/${id}/change-category`, {
                categoryId,
            });
            dispatch(getLogs(id));
            return res.data.data;
        } catch (err: any) {
            return rejectWithValue(err.response.data);
        }
    },
);

export const changePriority = createAsyncThunk<TicketI, {
  id: string,
  priorityId: string,
  plannedEndedAt: string,
  // plannedFinishedAt: string
}>(
    'changePriority',
    async ({
        id, priorityId, plannedEndedAt,
    }, { rejectWithValue, dispatch }) => {
        try {
            const res = await axios.post(`ba/helpdesk/ticket/${id}/change-priority`, {
                priorityId,
                plannedEndedAt,
                // plannedFinishedAt,
            });
            dispatch(getLogs(id));
            return res.data.data;
        } catch (err: any) {
            return rejectWithValue(err.response.data);
        }
    },
);

export const changeCustomer = createAsyncThunk<TicketI, {
    id: string,
    customerId: string,
}>(
    'changeCustomer',
    async ({
        id, customerId,
    }, { rejectWithValue, dispatch }) => {
        try {
            const res = await axios.post(`ba/helpdesk/ticket/${id}/change-customer`, {
                customerId,
            });
            dispatch(getLogs(id));
            return res.data.data;
        } catch (err: any) {
            return rejectWithValue(err.response.data);
        }
    },
);

export const sendComment = createAsyncThunk<LogItem[], { id: string, comment: string }>(
    'sendComment',
    async ({ id, comment }, { rejectWithValue }) => {
        try {
            const res = await axios.post(`ba/helpdesk/ticket/${id}/comment`, {
                text: comment,
            });
            return res.data.data;
        } catch (err: any) {
            return rejectWithValue(err.response.data);
        }
    },
);

export const getLogs = createAsyncThunk<LogItem[], string>(
    'getLogs',
    async (id, { rejectWithValue }) => {
        try {
            const res = await axios.get(`ba/helpdesk/ticket/${id}/log`);
            return res.data.data;
        } catch (err: any) {
            return rejectWithValue(err.response.data);
        }
    },
);

export const doneStatus = createAsyncThunk<TicketI, { id: string, comment?: string}, {state: RootState}>(
    'doneStatus',
    async ({ id, comment }, { rejectWithValue, dispatch, getState }) => {
        try {
            const files = getState().ticketReducer.filesModalByAction;
            const res = await axios.post(`ba/helpdesk/ticket/${id}/done`, {
                comment,
                files,
            });
            dispatch(getLogs(id));
            return res.data.data;
        } catch (err: any) {
            return rejectWithValue(err.response.data);
        }
    },
);

export const returnInWorkStatus = createAsyncThunk<TicketI, { id: string, comment?: string}, {state: RootState}>(
    'returnInWorkStatus',
    async ({ id, comment }, { rejectWithValue, dispatch, getState }) => {
        try {
            const files = getState().ticketReducer.filesModalByAction;
            const res = await axios.post(`ba/helpdesk/ticket/${id}/reopen`, {
                comment,
                files,
            });
            dispatch(getLogs(id));
            return res.data.data;
        } catch (err: any) {
            return rejectWithValue(err.response.data);
        }
    },
);

export const returnStatus = createAsyncThunk<TicketI, string>(
    'returnStatus',
    async (id, { rejectWithValue, dispatch }) => {
        try {
            const res = await axios.post(`ba/helpdesk/ticket/${id}/return`);
            dispatch(getLogs(id));
            return res.data.data;
        } catch (err: any) {
            return rejectWithValue(err.response.data);
        }
    },
);

export const uploadEndTicketFile = createAsyncThunk<FileI, { file: File }>(
    'uploadEndTicketFile',
    async (body, { rejectWithValue }) => {
        try {
            const res = await axios.post('storage/file/upload', body, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            return res.data.data;
        } catch (err: any) {
            return rejectWithValue(err.response.data);
        }
    },
);
export const startStatus = createAsyncThunk<TicketI, string>(
    'startStatus',
    async (id, { rejectWithValue, dispatch }) => {
        try {
            const res = await axios.post(`ba/helpdesk/ticket/${id}/start`);
            dispatch(getLogs(id));
            return res.data.data;
        } catch (err: any) {
            return rejectWithValue(err.response.data);
        }
    },
);

export const endStatus = createAsyncThunk<TicketI, { id: string, comment?: string }, {state: RootState}>(
    'endStatus',
    async ({ id, comment }, { rejectWithValue, dispatch, getState }) => {
        try {
            const files = getState().ticketReducer.filesModalByAction;
            const res = await axios.post(`ba/helpdesk/ticket/${id}/end`, {
                comment,
                files,
            });
            dispatch(getLogs(id));
            return res.data.data;
        } catch (err: any) {
            return rejectWithValue(err.response.data);
        }
    },
);

export const waitingStatus = createAsyncThunk<TicketI, { id: string, comment?: string, plannedEndedAt?: string }, {state: RootState}>(
    'waitingStatus',
    async ({ id, comment, plannedEndedAt }, { rejectWithValue, dispatch, getState }) => {
        try {
            const files = getState().ticketReducer.filesModalByAction;
            const res = await axios.post(`ba/helpdesk/ticket/${id}/waiting`, {
                comment,
                files,
                plannedEndedAt,
            });
            dispatch(getLogs(id));
            return res.data.data;
        } catch (err: any) {
            return rejectWithValue(err.response.data);
        }
    },
);

export const cancelStatus = createAsyncThunk<TicketI, { id: string, comment?: string }, { state: RootState }>(
    'cancelStatus',
    async ({ id, comment }, { rejectWithValue, dispatch, getState }) => {
        try {
            const files = getState().ticketReducer.filesModalByAction;
            const res = await axios.post(`ba/helpdesk/ticket/${id}/cancel`, {
                comment,
                files,
            });
            dispatch(getLogs(id));
            return res.data.data;
        } catch (err: any) {
            return rejectWithValue(err.response.data);
        }
    },
);

export const filterTickets = createAsyncThunk<TicketI[], FilterInputData>(
    'filterTickets',
    async (
        {
            number,
            priorityIds,
            statuses,
            createdAtFrom,
            createdAtTo,
            plannedEndedAtFrom,
            plannedEndedAtTo,
            page,
            houseId,
            roomId,
            categoryIds,
            executorId,
        },
        { rejectWithValue },
    ) => {
        try {
            const res = await axios.get('ba/helpdesk/ticket', {
                params: {
                    number,
                    priorityIds,
                    statuses,
                    createdAtFrom,
                    createdAtTo,
                    plannedEndedAtFrom,
                    plannedEndedAtTo,
                    page,
                    houseId,
                    roomId,
                    categoryIds,
                    executorId,
                },
            });
            return res.data;
        } catch (err: any) {
            return rejectWithValue(err.response.data);
        }
    },
);

export const updateTickets = createAsyncThunk<TicketI[], FilterInputData>(
    'updateTickets',
    async (
        {
            number,
            priorityIds,
            statuses,
            createdAtFrom,
            createdAtTo,
            plannedEndedAtFrom,
            plannedEndedAtTo,
            page,
            houseId,
            roomId,
            categoryIds,
            executorId,
        },
        { rejectWithValue },
    ) => {
        try {
            const res = await axios.get('ba/helpdesk/ticket', {
                params: {
                    number,
                    priorityIds,
                    statuses,
                    createdAtFrom,
                    createdAtTo,
                    plannedEndedAtFrom,
                    plannedEndedAtTo,
                    page,
                    houseId,
                    roomId,
                    categoryIds,
                    executorId,
                },
            });
            return res.data;
        } catch (err: any) {
            return rejectWithValue(err.response.data);
        }
    },
);
