import React, { useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import { useSelector } from 'react-redux';

import { useParams } from 'react-router-dom';
import { useAppDispatch } from '../../../../redux/store';
import { ErrorsAlert } from '../../ErrorAlert';
import { ButtonPrimary } from '../../Buttons/ButtonPrimary';
import { useSuccessNavigate } from '../../../../utils/hooks/useSuccessNavigate';
import { DropDownMenu } from '../../DropDownMenu';
import {
    getStatusSelector,
    getIsLoadingSelector,
    getDepartmentSelector,
    getDepartmentsSelector,
    getFormErrorsSelector,
} from '../../../../redux/departments/selectors';
import {
    createDepartment,
    getDepartmentById,
    getDepartments,
    updateDepartment,
} from '../../../../redux/departments/asyncThunks';
import { Department } from '../../../../redux/departments/types';
import { Preloader } from '../../../Client/ui/Preloader';

export const DepartmentForm = () => {
    const { id } = useParams();
    const isEditing = Boolean(id);
    const errors = useSelector(getFormErrorsSelector);
    const status = useSelector(getStatusSelector);
    const isLoading = useSelector(getIsLoadingSelector);
    const department = useSelector(getDepartmentSelector);
    const departments = useSelector(getDepartmentsSelector);
    const departmentsSelect = isEditing ? departments.filter((d) => d.id !== id) : departments;
    const dispatch = useAppDispatch();
    useSuccessNavigate('/bo/employee/department', status);
    const [departmentValues, setDepartmentValues] = useState<Department>({
        parentId: null,
        ticketNumberPrefix: null,
        name: null,
        text: null,
    });

    useEffect(() => {
        dispatch(getDepartments());
        if (id) {
            dispatch(getDepartmentById(id));
        }
    }, []);

    useEffect(() => {
        if (isEditing) {
            setDepartmentValues(department);
        }
    }, [department]);

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const body = {
            parentId: departmentValues.parentId,
            ticketNumberPrefix: departmentValues.ticketNumberPrefix,
            name: departmentValues.name,
            // text: departmentValues.text,
        };
        if (isEditing && id) {
            dispatch(updateDepartment({ id, body }));
        } else {
            dispatch(createDepartment(body));
        }
    };

    if (isEditing && !department.id) {
        return <Preloader />;
    }

    return (
        <Box
            component="form"
            onSubmit={handleSubmit}
            className="form"
            noValidate
        >
            {errors.length > 0 && <ErrorsAlert errors={errors} />}
            <DropDownMenu
                label="Выберите департамент"
                value={departmentValues.parentId || ''}
                handleChange={(e) => setDepartmentValues({ ...departmentValues, parentId: e.target.value })}
                items={departmentsSelect}
            />

            <TextField
                id="name"
                label="Имя"
                name="name"
                required
                variant="standard"
                value={departmentValues.name || ''}
                onChange={(e) => setDepartmentValues({ ...departmentValues, name: e.target.value })}
            />
            <TextField
                id="ticketNumberPrefix"
                label="Префикс"
                name="ticketNumberPrefix"
                required
                variant="standard"
                value={departmentValues.ticketNumberPrefix || ''}
                onChange={(e) => setDepartmentValues({ ...departmentValues, ticketNumberPrefix: e.target.value })}
            />
            {/* <TextField */}
            {/*    id="text" */}
            {/*    label="Текст" */}
            {/*    name="text" */}
            {/*    variant="standard" */}
            {/*    value={departmentValues.text || ''} */}
            {/*    onChange={(e) => setDepartmentValues({ ...departmentValues, text: e.target.value })} */}
            {/* /> */}
            <ButtonPrimary isLoading={isLoading} isEditing={isEditing} />
        </Box>
    );
};
