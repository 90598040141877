import React from 'react';
import cn from 'classnames';
import { BreadCrumbs } from '../../../../components/Client/BreadCrumbs';
import styles from './TicketFull.module.css';
import MockImg from '../../News/NewsFull/news-full-mock.png';
import { Message } from '../../../../components/Client/Message/Message';
import { Rating } from '../../../../components/Client/ui/Rating/Rating';

export const TicketFull = () => (
    <main className="content">
        <BreadCrumbs />
        <div className={styles.wrapper}>
            <div className={styles.infoWrapper}>
                <h1 className={styles.title}>Уже очень долго не работает лифт в 3 подъезде</h1>
                <span className={styles.number}>№ 12314254</span>
                <p className={styles.text}>
                    Проблема очень серьезная конечно Проблема очень серьезная конечно Проблема очень серьезная конечно
                </p>
                <div className={styles.messageWrapper}>
                    <Message />
                    <Rating />
                </div>
            </div>
            <div className={styles.imagesWrapper}>
                {/* TODO: Добавить альты */}
                <img className={styles.image} src={MockImg} alt="" />
                <img className={cn(styles.image, styles.extendedImage)} src={MockImg} alt="" />
                <div className={styles.overlayWrapper}>
                    <img className={styles.image} src={MockImg} alt="" />
                    <div className={styles.overlay}>
                        +2
                    </div>
                </div>
            </div>
            <div className={cn(styles.messageWrapper, styles.messageWrapperBottom)}>
                <Message />
                <Rating />
            </div>
        </div>
    </main>
);
