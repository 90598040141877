import React from 'react';
import { ErrorItemProps } from './ErrorItem.props';
import styles from './ErrorItem.module.css';

export const ErrorItem: React.FC<ErrorItemProps> = ({ error }) => (
    <li className={styles.error}>
        {error.property && (
            <span className={styles.property}>
                {error.property}
                :
            </span>
        )}
        <span>{error?.message || 'Что-то пошло не так'}</span>
    </li>
);
