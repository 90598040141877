import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { StatusEnum } from '../../interfaces/StatusEnum';

export const useSuccessNavigate = (path: string, status: StatusEnum | null) => {
    const navigate = useNavigate();
    useEffect(() => {
        if (status && status === StatusEnum.SUCCESS) {
            navigate(path);
        }
    }, [status]);
};
