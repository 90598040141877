export const ticketsToggleData = [
    {
        value: 'customer',
        label: 'от жителя',
    },
    {
        value: 'company',
        label: 'от ук',
    },
];
