export enum Permissions {
  DEPARTMENT_CONTROL = 10000,
  DEPARTMENT_READ = 10100,
  DOCUMENTS_READ = 10350,
  DOCUMENTS_CONTROL = 10300,

  POSITION_CONTROL = 10500, // Должности: управление
  POSITION_READ = 10600, // Должности: чтение

  EMPLOYEE_CONTROL = 11000, // Сотрудники: управление
  EMPLOYEE_READ = 11100, // Сотрудники: чтение

  WORK_PERIOD_OWN_START_END = 12000, // —

  HELPDESK_TICKET_ASSIGN_EXECUTOR = 20000, // Заявки: назначение исполнителя
  HELPDESK_TICKET_CANCEL = 20100, // Заявки: отмена
  HELPDESK_TICKET_CHANGE_PRIORITY = 20200, // Заявки: изменение приоритета
  HELPDESK_TICKET_COMMENT = 20300, // Заявки: комментирование
  HELPDESK_TICKET_DONE = 20400, // Заявки: закрытие после выполнения
  HELPDESK_TICKET_END = 20500, // Заявки: выполнение
  HELPDESK_TICKET_LIST = 20600, // Заявки: список
  HELPDESK_TICKET_VIEW = 20700, // Заявки: просмотр деталей
  HELPDESK_TICKET_RETURN = 20800, // Заявки: возврат в работу
  HELPDESK_TICKET_START = 20900, // Заявки: начало работы
  HELPDESK_TICKET_CREATE = 21000, // Заявки: создание

  HELPDESK_CATEGORY_CONTROL = 22000, // Категории заявок: управление
  HELPDESK_CATEGORY_READ = 22100, // Категории заявок: чтение
  HELPDESK_PRIORITY_CONTROL = 22500, // Приоритеты заявок: управление
  HELPDESK_PRIORITY_READ = 22600, // Приоритеты заявок: чтение

  GEO_CONTROL = 24000, // Объекты: управление
  GEO_READ = 24100, // Объекты: чтение

}

export interface Permission {
  name: string;
  value: number;
  isChecked: boolean;
}
