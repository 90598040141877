import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';

import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { useParams } from 'react-router-dom';
import { SelectChangeEvent } from '@mui/material/Select';
import { useAppDispatch } from '../../../../redux/store';
import { ErrorsAlert } from '../../ErrorAlert';
import {
    getStatusSelector,
    getIsLoadingSelector,
    getCategorySelector,
    getCategoriesSelector,
    getFormErrorsSelector,
} from '../../../../redux/categories/selectors';
import {
    createCategory, getCategories, getCategoryById, updateCategory,
} from '../../../../redux/categories/asyncThunks';
import { ButtonPrimary } from '../../Buttons/ButtonPrimary';
import { useSuccessNavigate } from '../../../../utils/hooks/useSuccessNavigate';
import { Category } from '../../../../redux/categories/types';
import { DropDownMenu } from '../../DropDownMenu';
import { Preloader } from '../../../Client/ui/Preloader';
import { uploadFile } from '../../../../redux/uploader/asyncThunks';

export const CategoryForm = () => {
    const { id } = useParams();
    const errors = useSelector(getFormErrorsSelector);
    const status = useSelector(getStatusSelector);
    const isLoading = useSelector(getIsLoadingSelector);
    const category = useSelector(getCategorySelector);
    const categories = useSelector(getCategoriesSelector);
    const isEditing = Boolean(id);
    const categoriesSelect = isEditing ? categories.filter((c) => c.id !== id) : categories;
    const dispatch = useAppDispatch();
    useSuccessNavigate('/bo/settings/ticket-category', status);
    const [categoryValues, setCategoryValues] = useState<Category>({
        parentId: null,
        name: null,
        description: null,
        iconFileId: null,
        isReviewPhotoRequired: false,
        isReviewCommentRequired: false,
    });

    useEffect(() => {
        if (id) {
            dispatch(getCategoryById(id));
        }
        dispatch(getCategories());
    }, []);

    useEffect(() => {
        if (isEditing) {
            setCategoryValues(category);
        }
    }, [category]);

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const body = {
            name: categoryValues.name,
            parentId: categoryValues.parentId,
            description: categoryValues.description,
            iconFileId: categoryValues.iconFileId,
            isReviewPhotoRequired: categoryValues.isReviewPhotoRequired,
            isReviewCommentRequired: categoryValues.isReviewCommentRequired,
        };
        if (isEditing && id) {
            dispatch(updateCategory({ id, body }));
        } else {
            dispatch(createCategory(body));
        }
    };

    const handleUploadFile = (e: any) => {
        const file = e.target.files[0];
        if (file) {
            const data = { file };
            dispatch(uploadFile(data));
        }
    };

    if (isEditing && !category.id) {
        return <Preloader />;
    }

    return (
        <Box
            component="form"
            onSubmit={handleSubmit}
            className="form"
            noValidate
        >
            {errors.length > 0 && <ErrorsAlert errors={errors} />}
            <DropDownMenu
                label="Выберите категорию"
                items={categoriesSelect}
                value={categoryValues.parentId || ''}
                handleChange={(e: SelectChangeEvent) => setCategoryValues({ ...categoryValues, parentId: e.target.value })}
            />
            <TextField
                id="name"
                label="Имя"
                name="name"
                required
                variant="standard"
                value={categoryValues.name || ''}
                onChange={(e) => setCategoryValues({ ...categoryValues, name: e.target.value })}
            />
            <TextField
                id="description"
                label="Описание"
                name="description"
                variant="standard"
                value={categoryValues.description || ''}
                onChange={(e) => setCategoryValues({ ...categoryValues, description: e.target.value })}
            />
            <TextField
                id="iconFileId"
                label="id файла"
                name="iconFileId"
                variant="standard"
                value={categoryValues.iconFileId || ''}
                onChange={(e) => setCategoryValues({ ...categoryValues, iconFileId: e.target.value })}
            />
            <FormControlLabel
                control={(
                    <Checkbox
                        color="success"
                        checked={categoryValues.isReviewPhotoRequired || false}
                        onChange={(e) => setCategoryValues({ ...categoryValues, isReviewPhotoRequired: e.target.checked })}
                    />
                )}
                id="isReviewPhotoRequired"
                name="isReviewPhotoRequired"
                label="Требуется подтверждение"
            />
            <FormControlLabel
                control={(
                    <Checkbox
                        color="success"
                        checked={categoryValues.isReviewCommentRequired || false}
                        onChange={(e) => setCategoryValues({ ...categoryValues, isReviewCommentRequired: e.target.checked })}
                    />
                )}
                id="isReviewCommentRequired"
                name="isReviewCommentRequired"
                label="Требуется комментарий"
            />
            <Button variant="contained" component="label" sx={{ width: '250px' }}>
                Загрузить файл
                <input hidden accept="image/*" type="file" onChange={handleUploadFile} />
            </Button>
            <ButtonPrimary isLoading={isLoading} isEditing={isEditing} />
        </Box>
    );
};
