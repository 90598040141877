import React, { useCallback, useLayoutEffect } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from '../../../../redux/store';
import { EmptyTable } from '../../EmptyTable';
import { Preloader } from '../../../Client/ui/Preloader';
import { Category } from './Category';
import {
    getCategoriesSelector,
    getIsLoadingSelector,
    getIdToDeleteSelector,
    getErrorsSelector, getStatusSelector,
} from '../../../../redux/categories/selectors';
import { deleteCategory, getCategories } from '../../../../redux/categories/asyncThunks';
import { DirectoryPage } from '../DirectoryPage';

const categoriesInfo = {
    title: 'Категории',
    text: 'категорию',
};

const categoriesHeadings = ['Имя', 'Действия'];

export const Categories: React.FC = () => {
    const dispatch = useAppDispatch();
    const isLoading = useSelector(getIsLoadingSelector);
    const idToDelete = useSelector(getIdToDeleteSelector);
    const categories = useSelector(getCategoriesSelector);
    const errors = useSelector(getErrorsSelector);
    const status = useSelector(getStatusSelector);

    useLayoutEffect(() => {
        dispatch(getCategories());
    }, []);

    const modalHandler = useCallback(() => {
        dispatch(deleteCategory(idToDelete));
    }, [idToDelete]);

    return (
        <DirectoryPage
            headings={categoriesHeadings}
            info={categoriesInfo}
            modalHandler={modalHandler}
            errors={errors}
            status={status}
        >
            { isLoading
                ? <Preloader />
                : (
                    <ul>
                        {categories.length > 0
                            ? categories.map((i) => <Category key={i.id} category={i} />)
                            : <EmptyTable />}
                    </ul>
                )}
        </DirectoryPage>
    );
};
