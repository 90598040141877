import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { initialStateI } from './types';

const initialState: initialStateI = {
    isActive: false,
    src: '',
};

export const photoZoomSlice = createSlice({
    name: 'photoZoomSlice',
    initialState,
    reducers: {
        setPhotoInZoom(state, action: PayloadAction<string>) {
            state.isActive = true;
            state.src = action.payload;
        },
        resetZoom(state) {
            state.isActive = false;
            state.src = '';
        },
    },
});

export const { reducer: photoZoomReducer, actions: photoZoomActions } = photoZoomSlice;
