import React, { useLayoutEffect, useState } from 'react';
import { Link, NavLink, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import cn from 'classnames';
import { UserInfo } from '../UserInfo';
import { getUserNameSelector, getUserPermissionsSelector } from '../../../redux/auth/selectors';
import { panelNavList } from '../../../utils/data/panelNavList';
import { ReactComponent as HomeIcon } from '../../../assets/img/header/home.svg';
import { ReactComponent as NotificationsIcon } from '../../../assets/img/header/notifications.svg';
import styles from './Header.module.css';
import { ProfileMenu } from '../ProfileMenu/ProfileMenu';
import { NotificationsMenu } from '../NotificationsMenu/NotificationsMenu';
import { HomeMenu } from '../HomeMenu/HomeMenu';
import { BurgerMenu } from '../ui/BurgerMenu';
import { clientNavList } from '../../../utils/data/clientNavList';
import { useAppDispatch } from '../../../redux/store';
import { getMe } from '../../../redux/auth/asyncThunks';
import LogoIcon from '../../../assets/img/logo.png';
import { ticketActions } from '../../../redux/tickets/slice';

export const Header = ({ forceUpdate }: {forceUpdate: () => void}) => {
    const dispatch = useAppDispatch();
    const username = useSelector(getUserNameSelector);
    const userPermissions = useSelector(getUserPermissionsSelector);
    const location = useLocation();
    const path = location.pathname;
    const isPanel = path.startsWith('/bo');
    const topBarData: {path: string, text: string, isTicket?: boolean, permission?: number}[] = isPanel ? panelNavList : clientNavList;
    const [isNotificationsVisible, setIsNotificationsVisible] = useState(false);
    const [isHomeMenuVisible, setIsHomeMenuVisible] = useState(false);
    const [isProfileMenuVisible, setIsProfileMenuVisible] = useState(false);
    const [isBurgerMenuOpened, setIsBurgerMenuOpened] = useState(false);

    const toggleNotificationsVisible = () => {
        setIsNotificationsVisible((prevState) => !prevState);
    };

    const toggleHomeMenuVisible = () => {
        setIsHomeMenuVisible((prevState) => !prevState);
    };

    const toggleProfileMenuVisible = () => {
        setIsProfileMenuVisible((prevState) => !prevState);
    };

    const handleCloseNotifications = () => {
        setIsNotificationsVisible(false);
    };

    const handleCloseProfileMenu = () => {
        setIsProfileMenuVisible(false);
    };

    const handleToggleBurgerMenu = () => {
        setIsBurgerMenuOpened((prevState) => !prevState);
    };

    const onLinkClick = (isTicket: boolean | undefined) => {
        if (isTicket) {
            forceUpdate();
            dispatch(ticketActions.resetSuccessCreated());
        }
    };

    useLayoutEffect(() => {
        dispatch(getMe());
    }, []);

    return (
        <header className={styles.header}>
            <div className={styles.wrapper}>
                <button
                    className={cn(styles.burger, {
                        [styles.burgerOpened]: isBurgerMenuOpened,
                    })}
                    onClick={handleToggleBurgerMenu}
                />
                <BurgerMenu handleToggleBurgerMenu={handleToggleBurgerMenu} isOpen={isBurgerMenuOpened} forceUpdate={forceUpdate} />
                <div className={styles.logo}>
                    {isPanel
                        ? (
                            <Link to="/bo/dashboard">
                                <img src={LogoIcon} alt="Logo" />
                                Клевер
                            </Link>
                        ) : 'Личный кабинет'}
                </div>
                <nav className={styles.navigation}>
                    <ul className={styles.navList}>
                        {topBarData.map((i) => (i.permission ? userPermissions.includes(i.permission) && (
                            <li key={i.text}>
                                <NavLink
                                    to={i.path}
                                    className={({ isActive }) => (isActive ? styles.activeLink : styles.link)}
                                    onClick={() => onLinkClick(i?.isTicket)}
                                >
                                    {i.text}

                                </NavLink>
                            </li>
                        ) : (
                            <li key={i.text}>
                                <NavLink
                                    to={i.path}
                                    className={({ isActive }) => (isActive ? styles.activeLink : styles.link)}
                                    onClick={() => onLinkClick(i?.isTicket)}
                                >
                                    {i.text}

                                </NavLink>
                            </li>
                        )))}
                    </ul>
                </nav>
            </div>
            {isPanel
              && (
                  <Link to="/bo/ticket/create" className={styles.ticketLink}>
                      <button className={styles.ticketButton} type="button">
                          <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M14 8H8V14H6V8H0V6H6V0H8V6H14V8Z" fill="white" />
                          </svg>
                          Создать заявку
                      </button>
                  </Link>
              )}
            <div className={styles.profileWrapper}>
                {
                    !isPanel
                  && (
                      <div className={styles.buttonWrapper}>
                          <button className={styles.button} onClick={toggleNotificationsVisible}>
                              <NotificationsIcon />
                          </button>
                          <button className={styles.button} onClick={toggleHomeMenuVisible}>
                              <HomeIcon />
                          </button>
                      </div>
                  )
                }

                <UserInfo
                    isPanel={isPanel}
                    onClick={toggleProfileMenuVisible}
                    username={username}
                    userImage="https://html5css.ru/howto/img_avatar.png"
                    userAddress="Просп. Героев, д. 8, кв. 82"
                />
            </div>
            {isNotificationsVisible && <NotificationsMenu handleClose={handleCloseNotifications} />}
            {isHomeMenuVisible && <HomeMenu />}
            {isProfileMenuVisible && (
                <div
                    className={styles.menuWrapper}
                    onClick={handleCloseProfileMenu}
                >
                    <ProfileMenu handleClose={handleCloseProfileMenu} isPanel={isPanel} />
                </div>
            )}
        </header>
    );
};
