import { ru } from 'date-fns/locale';
import { utcToZonedTime, format } from 'date-fns-tz';

export const formatDate = (date: string) => format((Date.parse(date)), 'dd MMMM до HH:mm', { locale: ru });

export const formatDateToUtc = (date: string): string => {
    const localDate = new Date(date);
    const utcDate = utcToZonedTime(localDate, 'UTC');
    return format(utcDate, "yyyy-MM-dd'T'HH:mm:ssxxx", { timeZone: 'UTC' });
};
