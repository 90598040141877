import React from 'react';
import cn from 'classnames';
import { Button } from '../Button';
import styles from './YearsFilter.module.css';
import { YearsFilterProps } from './YearsFilter.props';

export const YearsFilter:React.FC<YearsFilterProps> = ({ className }) => (
    <ul className={cn(styles.list, className)}>
        <li>
            <Button className={styles.button} appearance="primary">2022</Button>
        </li>
        <li>
            <Button className={styles.button} appearance="outlined">2021</Button>
        </li>
        <li>
            <Button className={styles.button} appearance="outlined">2020</Button>
        </li>
        <li>
            <Button className={styles.button} appearance="outlined">2019</Button>
        </li>
    </ul>
);
