import InboxIcon from '@mui/icons-material/MoveToInbox';
import React from 'react';

export const sideBarGeoLinks = [
    {
        path: 'settlement',
        name: 'Населённые пункты',
        icon: <InboxIcon />,
    },
    {
        path: 'street',
        name: 'Улицы',
        icon: <InboxIcon />,
    },
    {
        path: 'house',
        name: 'Дома',
        icon: <InboxIcon />,
    },
    {
        path: 'room',
        name: 'Квартиры',
        icon: <InboxIcon />,
    },
];
