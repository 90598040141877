import React, { useCallback, useLayoutEffect } from 'react';
import { useSelector } from 'react-redux';

import { useAppDispatch } from '../../../../redux/store';
import { EmptyTable } from '../../EmptyTable';
import { Preloader } from '../../../Client/ui/Preloader';
import { House } from './House';

import { DirectoryPage } from '../DirectoryPage';
import {
    getHousesSelector,
    getIsLoadingSelector,
    getIdToDeleteSelector,
    getErrorsSelector, getStatusSelector,
} from '../../../../redux/houses/selectors';
import { deleteHouse, getHouses } from '../../../../redux/houses/asyncThunks';

const housesInfo = {
    path: '/bo/directories/houses/create',
    title: 'Дома',
    text: 'дом',
};

const housesHeadings = ['Имя', 'Действия'];

export const Houses: React.FC = () => {
    const dispatch = useAppDispatch();
    const houses = useSelector(getHousesSelector);
    const isLoading = useSelector(getIsLoadingSelector);
    const idToDelete = useSelector(getIdToDeleteSelector);
    const errors = useSelector(getErrorsSelector);
    const status = useSelector(getStatusSelector);

    useLayoutEffect(() => {
        dispatch(getHouses());
    }, []);

    const modalHandler = useCallback(() => {
        dispatch(deleteHouse(idToDelete));
    }, [idToDelete]);

    return (
        <DirectoryPage
            headings={housesHeadings}
            info={housesInfo}
            modalHandler={modalHandler}
            errors={errors}
            status={status}
        >
            {isLoading
                ? <Preloader />
                : (
                    <ul>
                        {houses.length > 0
                            ? houses.map((i) => <House key={i.id} house={i} />)
                            : <EmptyTable />}
                    </ul>
                )}
        </DirectoryPage>

    );
};
