import React, { ChangeEventHandler } from 'react';
import { TextareaAutosize } from '@mui/material';
import cn from 'classnames';
import styles from './Textarea.module.css';

export const Textarea = ({
    value, onChange, className, placeHolder,
}: {value?: string, onChange?: ChangeEventHandler<HTMLTextAreaElement>, className?: string, placeHolder: string}) => (
    <TextareaAutosize
        className={cn(styles.area, className)}
        aria-label="textarea"
        placeholder={placeHolder}
        value={value}
        onChange={onChange}
    />
);
