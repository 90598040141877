import { RootState } from '../../store';

export const getEmployeeSelector = (state: RootState) => state.employeeReducer.employee;
export const getEmployeesSelector = (state: RootState) => state.employeeReducer.employees;
export const getIsLoadingSelector = (state: RootState) => state.employeeReducer.isLoading;
export const getIdToDeleteSelector = (state: RootState) => state.employeeReducer.employeeIdToDelete;
export const getErrorsSelector = (state: RootState) => state.employeeReducer.errors;
export const getStatusSelector = (state: RootState) => state.employeeReducer.status;
export const getFormErrorsSelector = (state: RootState) => state.employeeReducer.formErrors;
export const getAvailableDepartmentsSelector = (state: RootState) => state.employeeReducer.availableDepartments;
export const getAvailablePositionsSelector = (state: RootState) => state.employeeReducer.availablePositions;
export const getAvailableRolesSelector = (state: RootState) => state.employeeReducer.availableRoles;
export const getIsActiveModalOpenSelector = (state: RootState) => state.employeeReducer.isActiveModalOpen;
export const getIsPasswordModalOpenSelector = (state: RootState) => state.employeeReducer.isPasswordModalOpen;
