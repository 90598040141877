import React from 'react';
import { useNavigate } from 'react-router-dom';
import { RoleProps } from './Role.props';
import { useAppDispatch } from '../../../../redux/store';
import { directoryActions } from '../../../../redux/directories/slice';
import { roleActions } from '../../../../redux/role/slice';
import { DirectoryItem } from '../DirectoryItem';

export const Role: React.FC<RoleProps> = ({ role }) => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const handleDeleteButtonClick = (id?: string | null) => {
        dispatch(directoryActions.setIsModalOpen());
        dispatch(roleActions.setIdToDelete(id));
    };

    const handleEditButtonClick = (id? : string | null) => {
        if (id) {
            navigate(`${id}/update`);
        }
    };

    return (
        <DirectoryItem
            item={role}
            handleEditButtonClick={handleEditButtonClick}
            handleDeleteButtonClick={handleDeleteButtonClick}
        />
    );
};
