import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Button } from '@mui/material';
import { DirectoryTopInfoProps } from './DirectoryTopInfo.props';
import styles from './DirectoryTopInfo.module.css';

export const DirectoryTopInfo: React.FC<DirectoryTopInfoProps> = ({
    title, text, withFilterButton, filterButtonHandler, mapButtonHandler, withoutButton = false,
}) => {
    const [withoutText, setWithoutText] = useState(false);

    useEffect(() => {
        if (window.innerWidth < 500) setWithoutText(true);
    }, []);
    return (
        <div className={styles.wrapper}>
            <div className={styles.headingWrapper}>
                <h2 className={styles.title}>{title}</h2>
                {withFilterButton && (
                    <>
                        <Button onClick={filterButtonHandler} variant="contained">
                            Фильтры
                        </Button>
                        <Button onClick={mapButtonHandler} variant="contained">
                            Карта
                        </Button>
                    </>
                )}
            </div>
            {!withoutButton
        && (
            <Link to="create">
                <button className={styles.button} type="button">
                    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M14 8H8V14H6V8H0V6H6V0H8V6H14V8Z" fill="white" />
                    </svg>
                    {`Добавить ${!withoutText ? text : ''}`}
                </button>
            </Link>
        )}
        </div>
    );
};
