import { createAsyncThunk } from '@reduxjs/toolkit';
import { Settlement } from './types';
import axios from '../../axios';
import { settlementActions } from './slice';

type DataI = {
  id: string;
  body: Settlement;
}

export const getSettlements = createAsyncThunk<Settlement[]>(
    'getPositions',
    async (body, { dispatch }) => {
        await dispatch(settlementActions.resetSettlement());
        const res = await axios.get('geo/settlement');
        return res.data.data;
    },
);

export const createSettlement = createAsyncThunk<Settlement, Settlement>(
    'createSettlement',
    async (body, { rejectWithValue }) => {
        try {
            const res = await axios.post('geo/settlement', body);
            return res.data;
        } catch (err: any) {
            return rejectWithValue(err.response.data);
        }
    },
);

export const deleteSettlement = createAsyncThunk<string, string | null>(
    'deleteSettlement',
    async (id, { dispatch, rejectWithValue }) => {
        try {
            const res = await axios.delete(`geo/settlement/${id}`);
            dispatch(getSettlements());
            return res.data.data;
        } catch (err: any) {
            return rejectWithValue(err.response.data);
        }
    },
);

export const updateSettlement = createAsyncThunk<Settlement, DataI>(
    'updateSettlement',
    async ({ id, body }, { rejectWithValue }) => {
        try {
            const res = await axios.patch(`geo/settlement/${id}`, body);
            return await res.data.data;
        } catch (err: any) {
            return rejectWithValue(err.response.data);
        }
    },
);
export const getSettlementById = createAsyncThunk<Settlement, string>(
    'getSettlementById',
    async (id) => {
        const res = await axios.get(`geo/settlement/${id}`);
        return res.data.data;
    },
);
