import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { initialStateI } from './types';
import { StatusEnum } from '../../interfaces/StatusEnum';
import {
    createPosition, deletePosition, getPositionById, getPositions, updatePosition,
} from './asyncThunks';

const initialState: initialStateI = {
    position: {
        name: null,
        id: null,
    },
    positions: [],
    status: null,
    errors: [],
    formErrors: [],
    isLoading: false,
    positionIdToDelete: null,
};

export const positionSlice = createSlice({
    name: 'positions',
    initialState,
    reducers: {
        setIdToDelete(state, action) {
            state.positionIdToDelete = action.payload;
        },
        resetPosition(state) {
            state.position.id = null;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getPositions.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(getPositions.fulfilled, (state, action) => {
            state.positions = action.payload;
            state.errors = [];
            state.isLoading = false;
            state.status = null;
        });
        builder.addCase(getPositions.rejected, (state) => {
            state.isLoading = false;
        });
        builder.addCase(createPosition.pending, (state) => {
            state.status = StatusEnum.PENDING;
            state.isLoading = true;
        });
        builder.addCase(createPosition.fulfilled, (state) => {
            state.status = StatusEnum.SUCCESS;
            state.formErrors = [];
            state.isLoading = false;
        });
        builder.addCase(createPosition.rejected, (state, action: PayloadAction<any>) => {
            state.status = StatusEnum.ERROR;
            state.formErrors = action.payload.errors;
            state.isLoading = false;
        });
        builder.addCase(updatePosition.pending, (state) => {
            state.status = StatusEnum.PENDING;
            state.isLoading = true;
        });
        builder.addCase(updatePosition.fulfilled, (state, action) => {
            state.status = StatusEnum.SUCCESS;
            state.position = action.payload;
            state.isLoading = false;
            state.formErrors = [];
        });
        builder.addCase(updatePosition.rejected, (state, action: PayloadAction<any>) => {
            state.status = StatusEnum.ERROR;
            state.formErrors = action.payload.errors;
            state.isLoading = false;
        });
        builder.addCase(getPositionById.fulfilled, (state, action) => {
            state.position = action.payload;
        });
        builder.addCase(deletePosition.pending, (state) => {
            state.status = StatusEnum.PENDING;
        });
        builder.addCase(deletePosition.fulfilled, (state) => {
            state.status = StatusEnum.SUCCESS;
            state.errors = [];
        });
        builder.addCase(deletePosition.rejected, (state, action: PayloadAction<any>) => {
            state.status = StatusEnum.ERROR;
            state.errors = action.payload.errors;
        });
    },
});

export const { reducer: positionReducer, actions: positionActions } = positionSlice;
