import React from 'react';
import cn from 'classnames';
import { SortButtonProps } from './SortButton.props';
import styles from './SortButton.module.css';

export const SortButton: React.FC<SortButtonProps> = ({ isActive, className, ...props }) => (
    <button
        type="button"
        className={cn(styles.button, className, {
            [styles.active]: isActive,
        })}
        {...props}
    >
        <svg width="18" height="12" viewBox="0 0 18 12" fill="#9CA5B8" xmlns="http://www.w3.org/2000/svg">
            <path d="M7 12H11V10H7V12ZM0 0V2H18V0H0ZM3 7H15V5H3V7Z" />
        </svg>
    </button>
);
