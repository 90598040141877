import React from 'react';
import { useNavigate } from 'react-router-dom';
import BlockIcon from '@mui/icons-material/Block';
import { Button } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import PasswordIcon from '@mui/icons-material/Password';
import { useAppDispatch } from '../../../../redux/store';
import { directoryActions } from '../../../../redux/directories/slice';
import styles from '../DirectoryItem/DirectoryItem.module.css';
import { EditButton } from '../../Buttons/EditButton';
import { RedButton } from '../../Buttons/RedButton';
import { EmployeeI } from '../../../../redux/employees/types';
import { employeeActions } from '../../../../redux/employees/slice';

type componentProps = {
    employee: EmployeeI
}

export const renderEmployeeStatus = (status: number) => (status === 200 ? 'актив' : 'блок');
export const Employee = ({ employee }: componentProps) => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const handleDeleteButtonClick = (id?: string | null) => {
        dispatch(directoryActions.setIsModalOpen());
        dispatch(employeeActions.setIdToBlock(id));
    };

    const handleEditButtonClick = (id? : string | null) => {
        if (id) {
            navigate(`${id}/update`);
        }
    };

    const handleOpenActiveModal = () => {
        dispatch(employeeActions.setIsActiveModalOpen(true));
        dispatch(employeeActions.setIdToBlock(employee.id));
    };

    const handleOpenPasswordModal = () => {
        dispatch(employeeActions.setIsPasswordModalOpen(true));
        dispatch(employeeActions.setIdToBlock(employee.id));
    };

    return (
        <li className={styles.item} style={{ gridTemplateColumns: 'repeat(4, 2fr)' }}>
            <span className={styles.name}>{employee.fullName}</span>
            <span className={styles.name}>{employee.position.name}</span>
            <span className={styles.name}>{renderEmployeeStatus(+employee.status)}</span>
            <div className={styles.buttonWrapper}>
                <EditButton onClick={() => handleEditButtonClick(employee?.id)} />
                <RedButton onClick={() => handleDeleteButtonClick(employee?.id)}>
                    <BlockIcon fontSize="small" />
                </RedButton>
                <Button
                    size="small"
                    variant="contained"
                    color="success"
                    sx={{ minWidth: 0, padding: '5px' }}
                    onClick={handleOpenActiveModal}
                >
                    <CheckIcon fontSize="small" />
                </Button>
                <Button
                    size="small"
                    variant="contained"
                    sx={{ minWidth: 0, padding: '5px' }}
                    onClick={handleOpenPasswordModal}
                >
                    <PasswordIcon fontSize="small" />
                </Button>
            </div>
        </li>
    );
};
