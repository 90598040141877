import React from 'react';
import cn from 'classnames';
import { ButtonProps } from './Button.props';
import styles from './Button.module.css';

export const Button: React.FC<ButtonProps> = ({
    appearance, children, size, className, ...props
}) => (
    <button
        className={cn(styles.button, className, {
            [styles.primary]: appearance === 'primary',
            [styles.outlined]: appearance === 'outlined',
            [styles.small]: size === 's',
        })}
        {...props}
    >
        {children}
    </button>
);
