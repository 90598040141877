import React from 'react';
import { SectionTitle } from '../../../../components/Client/ui/SectionTitle';
import { ReactComponent as EmptyImage } from '../../../../assets/img/emptyTickets.svg';
import styles from './EmptyTickets.module.css';
import { Button } from '../../../../components/Client/ui/Button';

export const EmptyTickets = () => (
    <main className="content">
        <SectionTitle>Заявки</SectionTitle>
        <div className={styles.wrapper}>
            <EmptyImage />
            <h3 className={styles.title}>Мы не смогли ничего найти</h3>
            <p className={styles.subtitle}>Вы еще не создавали заявки</p>
            <Button appearance="primary" className={styles.button}>Создать заявку</Button>
        </div>
    </main>
);
