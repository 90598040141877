import React, { useEffect, useLayoutEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Box from '@mui/material/Box';
import { SelectChangeEvent } from '@mui/material/Select';
import { Stack } from '@mui/material';
import Typography from '@mui/material/Typography';
import { useAppDispatch } from '../../../../redux/store';
import { ErrorsAlert } from '../../../../components/Panel/ErrorAlert';
import {
    getActiveTicketsByAddressLoadingSelector,
    getActiveTicketsByAddressSelector,
    getAddressesSelector,
    getAvailablePrioritiesSelector,
    getCategoriesSelector,
    getCurrentCustomerIdSelector,
    getCustomersSelector,
    getErrorsSelector,
    getIsLoadingSelector,
    getRoomsSelector,
    getStatusSelector,
} from '../../../../redux/tickets/selectors';
import { ButtonPrimary } from '../../../../components/Panel/Buttons/ButtonPrimary';
import {
    AddressI, CategoryI, CustomerI, RoomI,
} from '../../../../interfaces/Tickets';
import {
    createTicket, getActiveTicketsByAddress,
    getAddresses,
    getAvailablePriorities,
    getCategories,
    getCustomers,
    getRooms,
} from '../../../../redux/tickets/asyncThunks';
import { DropDownMenu } from '../../../../components/Panel/DropDownMenu';
import { Textarea } from '../../../../components/Client/ui/Textarea';
import { ModalNewCustomer } from '../../../../components/Panel/ModalNewCustomer';
import { ticketActions } from '../../../../redux/tickets/slice';
import { Uploader } from '../../../../components/Client/ui/Uploader/Uploader';
import { AutoCompleteMenu } from '../../../../components/Panel/AutoCompleteMenu';
import { RadioGroupMenu } from '../../../../components/Client/ui/RadioGroupMenu';
import { uploadFile } from '../../../../redux/uploader/asyncThunks';
import { ToggleGroup } from '../../../../components/Client/ui/ToggleGroup';
import { ticketsToggleData } from '../../../../utils/data/ticketsToggleData';
import { useActionCreators } from '../../../../utils/hooks/useActionCreators';
import { useSuccessNavigate } from '../../../../utils/hooks/useSuccessNavigate';
import { ActiveTicketByAddress } from './ActiveTicketByAddress/ActiveTicketByAddress';
import { Preloader } from '../../../../components/Client/ui/Preloader';

export const TicketForm = () => {
    const dispatch = useAppDispatch();
    const actions = useActionCreators(ticketActions);
    const errors = useSelector(getErrorsSelector);
    const status = useSelector(getStatusSelector);
    const isLoading = useSelector(getIsLoadingSelector);
    const addresses = useSelector(getAddressesSelector);
    const rooms = useSelector(getRoomsSelector);
    const customers = useSelector(getCustomersSelector);
    const categories = useSelector(getCategoriesSelector);
    const currentCustomerId = useSelector(getCurrentCustomerIdSelector);
    const availablePriorities = useSelector(getAvailablePrioritiesSelector);
    const activeTicketsByAddress = useSelector(getActiveTicketsByAddressSelector);
    const activeTicketsByAddressLoading = useSelector(getActiveTicketsByAddressLoadingSelector);
    const defaultPriority = availablePriorities.filter((i) => i.isDefault)[0];
    const [ticketType, setTicketType] = React.useState<string>('customer');
    const [category, setCategory] = React.useState<string>('');
    const [childCategories, setChildCategories] = React.useState<CategoryI[]>([]);
    const [childCategory, setChildCategory] = React.useState<string>('');
    const [priorityId, setPriorityId] = React.useState<string>(availablePriorities.filter((i) => i.isDefault)[0]?.id ?? '');
    const [address, setAddress] = useState<AddressI | null>(null);
    const [room, setRoom] = useState<RoomI | null>(null);
    const [text, setText] = useState<string>('');
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const [customerToEdit, setCustomerToEdit] = useState<CustomerI | null>(null);
    const [files, setFiles] = useState<File[]>([]);
    const parentCategories = categories.filter((i) => !i.parent);
    useSuccessNavigate('/bo/ticket', status);

    // Инициализация адресов, категорий и приоритетов
    useLayoutEffect(() => {
        dispatch(getAvailablePriorities());
        setPriorityId(defaultPriority?.id);
        dispatch(getAddresses());
        dispatch(getCategories());
        actions.resetTicket();
        actions.resetStatus();
    }, []);

    useEffect(() => {
        setPriorityId(defaultPriority?.id);
    }, [defaultPriority]);

    // Динамическое получение квартир, в зависимости от дома
    useEffect(() => {
        if (address) {
            dispatch(getRooms(address.id));
        }
    }, [address]);
    // Динамическое получение жителей, в зависимости от квартиры
    useEffect(() => {
        if (room) {
            dispatch(getCustomers(room.id));
        }
    }, [room]);
    // Получение поодкатегорий, в зависимости от родительской категории
    useEffect(() => {
        if (categories.length > 0 && category) {
            setChildCategories(categories.filter((c) => c?.parent?.id === category));
        }
    }, [parentCategories]);
    // Открытие модалки создания нового жителя, если была выбрана радио-кнопка "создать нового"
    useEffect(() => {
        if (currentCustomerId === 'new') {
            setIsModalOpen(true);
            setCustomerToEdit(null);
        }
    }, [currentCustomerId]);
    const resetForm = () => {
        actions.resetTicket();
        actions.setCurrentCustomerId(null);
    };

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        // Дожидаюсь загрузки всех файлов
        for (let i = 0; i < files.length; i += 1) {
            await dispatch(uploadFile({ file: files[i] }));
        }
        const body = {
            categoryId: childCategory,
            text,
            houseId: address?.id,
            roomId: room?.id,
            customerId: currentCustomerId,
            files: [],
            priorityId,
        };
        actions.setTicket(body);
        dispatch(createTicket(body));
        resetForm();
    };

    const handleCloseModal = () => setIsModalOpen(false);
    const onChangeTicketType = (newType: string) => {
        setTicketType(newType);
    };
    const onChangeRadioCustomers = (event: React.ChangeEvent<HTMLInputElement>) => {
        actions.setCurrentCustomerId((event.target as HTMLInputElement).value);
    };
    const onChangeCategory = (e: SelectChangeEvent) => setCategory(e.target.value);
    const onChangeChildCategory = (e: SelectChangeEvent) => setChildCategory(e.target.value);
    const onChangeTextArea = (e: React.ChangeEvent<HTMLTextAreaElement>) => setText(e.target.value);

    const onEditCustomerClick = (id: string) => {
        const editingCustomer = customers?.find((i) => i.id === id);
        setCustomerToEdit(editingCustomer || null);
        setIsModalOpen(true);
    };

    const onHouseChange = (newValue: AddressI) => {
        setAddress(newValue);
        setRoom(null);
        if (newValue) dispatch(getActiveTicketsByAddress({ houseId: newValue.id }));
    };

    const onRoomChange = (newValue: RoomI) => {
        setRoom(newValue);
        dispatch(getActiveTicketsByAddress({ houseId: address?.id as string, roomId: newValue ? newValue.id : undefined }));
    };

    return (
        <Stack sx={{
            display: 'grid',
            gridTemplateColumns: '2fr 1fr',
            gap: 1,
        }}
        >
            <Box
                component="form"
                onSubmit={handleSubmit}
                className="form"
                noValidate
                sx={{ pt: '30px', pl: '30px', pb: '30px' }}
            >
                {errors.length > 0 && <ErrorsAlert errors={errors} />}
                <ToggleGroup value={ticketType} buttonData={ticketsToggleData} onChange={onChangeTicketType} />
                <AutoCompleteMenu value={address} options={addresses} setter={onHouseChange} label="Дом" isAddress />
                {ticketType === 'customer'
                    && (
                        <>
                            <AutoCompleteMenu value={room} options={rooms} setter={onRoomChange} label="Квартира" />
                            {room?.id && (
                                <RadioGroupMenu
                                    label="Жители"
                                    value={currentCustomerId}
                                    onChange={onChangeRadioCustomers}
                                    onEditClick={onEditCustomerClick}
                                    items={customers}
                                    isCustomers
                                />
                            )}
                        </>
                    )}
                <DropDownMenu
                    label="Категория"
                    items={parentCategories}
                    value={category}
                    handleChange={onChangeCategory}
                />
                {category && (
                    <DropDownMenu
                        label="Подкатегория"
                        items={childCategories}
                        value={childCategory}
                        handleChange={onChangeChildCategory}
                    />
                )}
                <DropDownMenu
                    value={priorityId ?? ''}
                    handleChange={(e) => setPriorityId(e.target.value)}
                    items={availablePriorities}
                    label="Приоритет"
                />
                <Textarea placeHolder="Расскажите о вашей проблеме" value={text} onChange={onChangeTextArea} />
                <Uploader files={files} setFiles={setFiles} />
                <ButtonPrimary isLoading={isLoading} isEditing={false} />
            </Box>
            {isModalOpen && room && (
                <ModalNewCustomer
                    roomId={room.id}
                    isOpen={isModalOpen}
                    onClose={handleCloseModal}
                    isLoading={isLoading}
                    customerToEdit={customerToEdit}
                />
            )}
            {activeTicketsByAddressLoading ? <Preloader />
                : (
                    <Stack
                        component="ul"
                        sx={{
                            gap: 2,
                            overflow: 'scroll',
                            pt: '30px',
                            pr: '30px',
                            pl: '20px',
                            pb: '30px',
                            minHeight: '100%',
                            maxHeight: 'calc(100vh - 60px)',
                        }}
                    >
                        <Typography fontWeight={500} fontSize="18px" textAlign="center">Активные заявки по адресу</Typography>
                        {activeTicketsByAddress.map((i) => (
                            <ActiveTicketByAddress ticket={i} key={i.id} />
                        ))}
                    </Stack>
                )}
        </Stack>
    );
};
