import React, {
    FormEvent, useEffect, useLayoutEffect, useState,
} from 'react';
import { Modal, ModalClose, ModalDialog } from '@mui/joy';
import Box from '@mui/material/Box';
import { useSelector } from 'react-redux';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { ruRU } from '@mui/x-date-pickers/locales';
import { ButtonPrimary } from '../Buttons/ButtonPrimary';
import { useAppDispatch } from '../../../redux/store';
import { changePriority, getAvailablePriorities } from '../../../redux/tickets/asyncThunks';
import {
    getStatusSelector,
    getAvailablePrioritiesSelector, getIsLoadingSelector, getErrorsSelector,
} from '../../../redux/tickets/selectors';
import { StatusEnum } from '../../../interfaces/StatusEnum';
import { DropDownMenu } from '../DropDownMenu';
import { Preloader } from '../../Client/ui/Preloader';
import { ErrorsAlert } from '../ErrorAlert';
import { TicketI } from '../../../interfaces/Tickets';
import { formatDateToUtc } from '../../../utils/helpers/formatDate';

type componentProps = {
    isOpen: boolean;
    onClose: () => void;
    ticketId: string;
    currentTicket: TicketI;
}

export const ModalEditPriority = ({
    isOpen, onClose, ticketId, currentTicket,
}: componentProps) => {
    const dispatch = useAppDispatch();
    const isLoading = useSelector(getIsLoadingSelector);
    const availablePriorities = useSelector(getAvailablePrioritiesSelector);
    const errors = useSelector(getErrorsSelector);
    const status = useSelector(getStatusSelector);

    const [priorityId, setPriorityId] = useState<string>();
    const [plannedEndedAt, setPlannedEndedAt] = useState<any>(null);
    // const [plannedFinishedAt, setPlannedFinishedAt] = useState<any>(null);

    useEffect(() => {
        if (status === StatusEnum.SUCCESS) {
            onClose();
            setPriorityId('');
            // setPlannedFinishedAt(null);
            setPlannedEndedAt(null);
        }
    }, [status]);

    useLayoutEffect(() => {
        dispatch(getAvailablePriorities());
        setPriorityId(currentTicket.priority?.id || '');
        // setPlannedFinishedAt(currentTicket.plannedFinishedAt);
        setPlannedEndedAt(currentTicket.plannedEndedAt);
    }, []);

    const handleCloseModal = () => {
        onClose();
    };

    const handleSubmit = (e: FormEvent) => {
        e.preventDefault();
        // const plannedFinishedAtFormatted = dayjs(plannedFinishedAt.$d).format('YYYY-MM-DDTHH:mm:ss');
        const plannedEndedAtFormatted = dayjs(plannedEndedAt).format('YYYY-MM-DDTHH:mm:ss');
        if (priorityId && plannedEndedAtFormatted) {
            dispatch(changePriority({
                id: ticketId,
                priorityId,
                // plannedFinishedAt: plannedFinishedAtFormatted,
                plannedEndedAt: formatDateToUtc(plannedEndedAtFormatted),
            }));
        }
    };

    return (
        <Modal open={isOpen} onClose={handleCloseModal}>
            <ModalDialog sx={{ backgroundColor: 'white' }}>
                <ModalClose sx={{ top: -40, right: -20 }} />
                <Box
                    component="form"
                    onSubmit={handleSubmit}
                    className="form"
                    noValidate
                >
                    {errors?.length > 0 && <ErrorsAlert errors={errors} />}
                    {isLoading ? <Preloader />
                        : (
                            <>
                                <DropDownMenu
                                    value={priorityId ?? ''}
                                    handleChange={(e) => setPriorityId(e.target.value)}
                                    items={availablePriorities}
                                    label="Приоритет"
                                />
                                <LocalizationProvider
                                    dateAdapter={AdapterDayjs}
                                    adapterLocale="ru"
                                    localeText={ruRU.components.MuiLocalizationProvider.defaultProps.localeText}
                                >
                                    <DateTimePicker
                                        ampm={false}
                                        format="DD.MM.YYYY HH:mm"
                                        label="Срок для исполнителя"
                                        value={dayjs(plannedEndedAt)}
                                        onChange={(newValue) => setPlannedEndedAt(newValue)}
                                    />
                                    {/* <DateTimePicker */}
                                    {/*    ampm={false} */}
                                    {/*    label="Срок для жителя" */}
                                    {/*    value={dayjs(plannedFinishedAt)} */}
                                    {/*    onChange={(newValue) => setPlannedFinishedAt(newValue)} */}
                                    {/* /> */}
                                </LocalizationProvider>
                                <ButtonPrimary isLoading={isLoading} isEditing />
                            </>
                        )}
                </Box>
            </ModalDialog>
        </Modal>
    );
};
