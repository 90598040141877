import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { NewsFull } from './NewsFull/NewsFull';
import { NewsPage } from './NewsPage/NewsPage';

export const News = () => (
    <Routes>
        <Route
            path="/"
            element={<NewsPage />}
        />
        <Route path="/fullNews" element={<NewsFull />} />
    </Routes>

);
