import React, { useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import { useSelector } from 'react-redux';

import { useParams } from 'react-router-dom';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { useAppDispatch } from '../../../../redux/store';
import { ErrorsAlert } from '../../ErrorAlert';
import { ButtonPrimary } from '../../Buttons/ButtonPrimary';
import { useSuccessNavigate } from '../../../../utils/hooks/useSuccessNavigate';
import { DropDownMenu } from '../../DropDownMenu';
import {
    getStatusSelector, getIsLoadingSelector, getHouseSelector, getFormErrorsSelector,
} from '../../../../redux/houses/selectors';
import { getStreetsSelector } from '../../../../redux/streets/selectors';
import { getDepartmentsSelector } from '../../../../redux/departments/selectors';
import { House } from '../../../../redux/houses/types';
import { createHouse, getHouseById, updateHouse } from '../../../../redux/houses/asyncThunks';
import { Preloader } from '../../../Client/ui/Preloader';
import { getDepartments } from '../../../../redux/departments/asyncThunks';
import { getStreets } from '../../../../redux/streets/asyncThunks';

export const HouseForm = () => {
    const { id } = useParams();
    const isEditing = Boolean(id);
    const errors = useSelector(getFormErrorsSelector);
    const status = useSelector(getStatusSelector);
    const isLoading = useSelector(getIsLoadingSelector);
    const house = useSelector(getHouseSelector);
    const departments = useSelector(getDepartmentsSelector);
    const streets = useSelector(getStreetsSelector);
    const dispatch = useAppDispatch();
    useSuccessNavigate('/bo/geo/house', status);
    const [houseValues, setHouseValues] = useState<House>({
        streetId: null,
        name: null,
        isApartmentHouse: null,
        departmentId: null,
        latitude: null,
        longitude: null,
    });

    useEffect(() => {
        if (isEditing && id) {
            dispatch(getHouseById(id));
        }
        dispatch(getDepartments());
        dispatch(getStreets());
    }, []);

    useEffect(() => {
        if (isEditing) {
            setHouseValues(house);
        }
    }, [house]);

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const body = {
            streetId: houseValues.streetId,
            name: houseValues.name,
            isApartmentHouse: houseValues.isApartmentHouse,
            departmentId: houseValues.departmentId,
            latitude: Number(houseValues.latitude),
            longitude: Number(houseValues.longitude),
        };
        if (isEditing && id) {
            dispatch(updateHouse({ id, body }));
        } else {
            dispatch(createHouse(body));
        }
    };

    if (isEditing && !house.id) {
        return <Preloader />;
    }

    return (
        <Box
            component="form"
            onSubmit={handleSubmit}
            className="form"
            noValidate
        >
            {errors.length > 0 && <ErrorsAlert errors={errors} />}
            <DropDownMenu
                label="Выберите улицу"
                required
                value={houseValues.streetId || ''}
                handleChange={(e) => setHouseValues({ ...houseValues, streetId: e.target.value })}
                items={streets}
            />

            <TextField
                id="name"
                label="Имя"
                name="name"
                required
                variant="standard"
                value={houseValues.name || ''}
                onChange={(e) => setHouseValues({ ...houseValues, name: e.target.value })}
            />
            <FormControlLabel
                control={(
                    <Checkbox
                        required
                        color="success"
                        checked={houseValues.isApartmentHouse || false}
                        onChange={(e) => setHouseValues({ ...houseValues, isApartmentHouse: e.target.checked })}
                    />
                )}
                id="isApartmentHouse"
                name="isApartmentHouse"
                label="Многоквартирный дом"
            />
            <DropDownMenu
                label="Выберите департамент"
                required
                value={houseValues.departmentId || ''}
                handleChange={(e) => setHouseValues({ ...houseValues, departmentId: e.target.value })}
                items={departments}
            />
            <TextField
                id="latitude"
                label="Широта"
                name="latitude"
                variant="standard"
                type="number"
                value={houseValues.latitude}
                onChange={(e) => setHouseValues({ ...houseValues, latitude: e.target.value as unknown as number })}
            />
            <TextField
                id="longitude"
                label="Долгота"
                name="longitude"
                variant="standard"
                type="number"
                value={houseValues.longitude}
                onChange={(e) => setHouseValues({ ...houseValues, longitude: e.target.value as unknown as number })}
            />
            <ButtonPrimary isLoading={isLoading} isEditing={isEditing} />
        </Box>
    );
};
