import { createAsyncThunk } from '@reduxjs/toolkit';
import { Street } from './types';
import axios from '../../axios';
import { streetActions } from './slice';

type DataI = {
  id: string;
  body: Street;
}

export const getStreets = createAsyncThunk<Street[]>(
    'getStreets',
    async (body, { dispatch }) => {
        await dispatch(streetActions.resetStreet());
        const res = await axios.get('geo/street');
        return res.data.data;
    },
);

export const createStreet = createAsyncThunk<Street, Street>(
    'createStreet',
    async (body, { rejectWithValue }) => {
        try {
            const res = await axios.post('geo/street', body);
            return res.data;
        } catch (err: any) {
            return rejectWithValue(err.response.data);
        }
    },
);

export const deleteStreet = createAsyncThunk<string, string | null>(
    'deleteStreet',
    async (id, { dispatch, rejectWithValue }) => {
        try {
            const res = await axios.delete(`geo/street/${id}`);
            await dispatch(getStreets());
            return res.data.data;
        } catch (err: any) {
            return rejectWithValue(err.response.data);
        }
    },
);

export const updateStreet = createAsyncThunk<Street, DataI>(
    'updateStreet',
    async ({ id, body }, { rejectWithValue }) => {
        try {
            const res = await axios.patch(`geo/street/${id}`, body);
            return await res.data.data;
        } catch (err: any) {
            return rejectWithValue(err.response.data);
        }
    },
);
export const getStreetById = createAsyncThunk<Street, string>(
    'getStreetById',
    async (id) => {
        const res = await axios.get(`geo/street/${id}`);
        return res.data.data;
    },
);
