import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { initialStateI } from './types';
import { StatusEnum } from '../../interfaces/StatusEnum';
import {
    createRoom, deleteRoom, getRoomById, getRooms, updateRoom,
} from './asyncThunks';

const initialState: initialStateI = {
    room: {
        houseId: null,
        name: null,
        id: null,
    },
    rooms: [],
    status: null,
    errors: [],
    formErrors: [],
    isLoading: false,
    roomIdToDelete: null,
};

export const roomSlice = createSlice({
    name: 'roomSlice',
    initialState,
    reducers: {
        setIdToDelete(state, action) {
            state.roomIdToDelete = action.payload;
        },
        resetRoom(state) {
            state.room.id = null;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getRooms.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(getRooms.fulfilled, (state, action) => {
            state.rooms = action.payload;
            state.errors = [];
            state.isLoading = false;
            state.status = null;
        });
        builder.addCase(getRooms.rejected, (state) => {
            state.isLoading = false;
        });
        builder.addCase(createRoom.pending, (state) => {
            state.status = StatusEnum.PENDING;
            state.isLoading = true;
        });
        builder.addCase(createRoom.fulfilled, (state) => {
            state.status = StatusEnum.SUCCESS;
            state.formErrors = [];
            state.isLoading = false;
        });
        builder.addCase(createRoom.rejected, (state, action: PayloadAction<any>) => {
            state.status = StatusEnum.ERROR;
            state.formErrors = action.payload.errors;
            state.isLoading = false;
        });
        builder.addCase(updateRoom.pending, (state) => {
            state.status = StatusEnum.PENDING;
            state.isLoading = true;
        });
        builder.addCase(updateRoom.fulfilled, (state, action) => {
            state.status = StatusEnum.SUCCESS;
            state.room = action.payload;
            state.isLoading = false;
            state.formErrors = [];
        });
        builder.addCase(updateRoom.rejected, (state, action: PayloadAction<any>) => {
            state.status = StatusEnum.ERROR;
            state.formErrors = action.payload.errors;
            state.isLoading = false;
        });
        builder.addCase(getRoomById.fulfilled, (state, action: PayloadAction<any>) => {
            state.room.houseId = action.payload.house.id;
            state.room.name = action.payload.name;
            state.room.id = action.payload.id;
        });
        builder.addCase(getRoomById.rejected, (state) => {
            state.status = StatusEnum.ERROR;
        });
        builder.addCase(deleteRoom.pending, (state) => {
            state.status = StatusEnum.PENDING;
        });
        builder.addCase(deleteRoom.fulfilled, (state) => {
            state.status = StatusEnum.SUCCESS;
            state.errors = [];
        });
        builder.addCase(deleteRoom.rejected, (state, action: PayloadAction<any>) => {
            state.status = StatusEnum.ERROR;
            state.errors = action.payload.errors;
        });
    },
});

export const { reducer: roomReducer, actions: roomActions } = roomSlice;
