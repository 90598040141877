import React from 'react';
import { useNavigate } from 'react-router-dom';
import { PositionProps } from './Position.props';
import { useAppDispatch } from '../../../../redux/store';
import { positionActions } from '../../../../redux/positions/slice';
import { getPositionById } from '../../../../redux/positions/asyncThunks';
import { directoryActions } from '../../../../redux/directories/slice';
import { DirectoryItem } from '../DirectoryItem';

export const Position: React.FC<PositionProps> = ({ position }) => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const handleDeleteButtonClick = (id?: string | null) => {
        dispatch(directoryActions.setIsModalOpen());
        dispatch(positionActions.setIdToDelete(id));
    };

    const handleEditButtonClick = (id? : string | null) => {
        if (id) {
            navigate(`${id}/update`);
        }
    };

    return (
        <DirectoryItem
            item={position}
            handleEditButtonClick={handleEditButtonClick}
            handleDeleteButtonClick={handleDeleteButtonClick}
        />
    );
};
