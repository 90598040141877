import { createAsyncThunk } from '@reduxjs/toolkit';
import { Position } from './types';
import axios from '../../axios';
import { positionActions } from './slice';

type DataI = {
  id: string;
  body: Position;
}

export const getPositions = createAsyncThunk<Position[]>(
    'getPositions',
    async (body, { dispatch }) => {
        await dispatch(positionActions.resetPosition());
        const res = await axios.get('employee/position');
        return res.data.data;
    },
);

export const createPosition = createAsyncThunk<Position, Position>(
    'createPosition',
    async (body, { rejectWithValue }) => {
        try {
            const res = await axios.post('employee/position', body);
            return res.data;
        } catch (err: any) {
            return rejectWithValue(err.response.data);
        }
    },
);

export const deletePosition = createAsyncThunk<string, string | null>(
    'deletePosition',
    async (id, { dispatch, rejectWithValue }) => {
        try {
            const res = await axios.delete(`employee/position/${id}`);
            dispatch(getPositions());
            return res.data.data;
        } catch (err: any) {
            return rejectWithValue(err.response.data);
        }
    },
);

export const updatePosition = createAsyncThunk<Position, DataI>(
    'updatePosition',
    async ({ id, body }, { rejectWithValue }) => {
        try {
            const res = await axios.patch(`employee/position/${id}`, body);
            return await res.data.data;
        } catch (err: any) {
            return rejectWithValue(err.response.data);
        }
    },
);
export const getPositionById = createAsyncThunk<Position, string>(
    'getPositionById',
    async (id) => {
        const res = await axios.get(`employee/position/${id}`);
        return res.data.data;
    },
);
