import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
    createPriority, deletePriority, getPriorities, getPriorityById, updatePriority,
} from './asyncThunks';
import { initialStateI } from './types';
import { StatusEnum } from '../../interfaces/StatusEnum';
import { ErrorI } from '../../interfaces/ErrorI';

export interface ErrorData {
    success: boolean;
    errors: ErrorI[];
}

const initialState: initialStateI = {
    priority: {
        name: null,
        term: null,
        id: null,
        weight: null,
        backgroundColor: '',
        textColor: '',
        isDefault: false,
    },
    priorities: [],
    status: null,
    errors: [],
    formErrors: [],
    isLoading: false,
    priorityIdToDelete: null,
};

export const prioritySlice = createSlice({
    name: 'priorities',
    initialState,
    reducers: {
        setIdToDelete(state, action) {
            state.priorityIdToDelete = action.payload;
        },
        resetPriority(state) {
            state.priority.id = null;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getPriorities.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(getPriorities.fulfilled, (state, action) => {
            state.priorities = action.payload;
            state.errors = [];
            state.isLoading = false;
            state.status = null;
        });
        builder.addCase(getPriorities.rejected, (state, action: PayloadAction<any>) => {
            state.isLoading = false;
            state.errors = action.payload.errors;
        });
        builder.addCase(createPriority.pending, (state) => {
            state.status = StatusEnum.PENDING;
            state.isLoading = true;
        });
        builder.addCase(createPriority.fulfilled, (state) => {
            state.status = StatusEnum.SUCCESS;
            state.formErrors = [];
            state.isLoading = false;
        });
        builder.addCase(createPriority.rejected, (state, action: PayloadAction<any>) => {
            state.status = StatusEnum.ERROR;
            state.formErrors = action.payload.errors;
            state.isLoading = false;
        });
        builder.addCase(updatePriority.pending, (state) => {
            state.status = StatusEnum.PENDING;
            state.isLoading = true;
        });
        builder.addCase(updatePriority.fulfilled, (state, action) => {
            state.status = StatusEnum.SUCCESS;
            state.priority = action.payload;
            state.isLoading = false;
            state.formErrors = [];
        });
        builder.addCase(updatePriority.rejected, (state, action: PayloadAction<any>) => {
            state.status = StatusEnum.ERROR;
            state.formErrors = action.payload.errors;
            state.isLoading = false;
        });
        builder.addCase(getPriorityById.fulfilled, (state, action) => {
            state.priority = action.payload;
            state.errors = [];
        });
        builder.addCase(getPriorityById.rejected, (state, action: PayloadAction<any>) => {
            state.status = StatusEnum.ERROR;
            state.errors = action.payload.errors;
        });
        builder.addCase(deletePriority.pending, (state) => {
            state.status = StatusEnum.PENDING;
        });
        builder.addCase(deletePriority.fulfilled, (state) => {
            state.status = StatusEnum.SUCCESS;
            state.errors = [];
        });
        builder.addCase(deletePriority.rejected, (state, action: PayloadAction<any>) => {
            state.status = StatusEnum.ERROR;
            state.errors = action.payload.errors;
        });
    },
});

export const { reducer: priorityReducer, actions: priorityActions } = prioritySlice;
