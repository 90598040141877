import { createAsyncThunk } from '@reduxjs/toolkit';
import {
    AvailableDepartment, AvailablePosition, AvailableRole, EmployeeI, ExtendedEmployeeI,
} from './types';
import axios from '../../axios';
import { employeeActions } from './slice';
import { EmployeeInputData } from '../../interfaces/EmployeeInputData';
import { directoryActions } from '../directories/slice';

type DataI = {
  id: string;
  body: EmployeeInputData;
}

export const getEmployees = createAsyncThunk<EmployeeI[], '1' | null | undefined>(
    'getEmployees',
    async (onlyExecutors) => {
        const res = await axios.get('ba/employee', {
            params: {
                onlyExecutors,
            },
        });
        return res.data.data;
    },
);

export const createEmployee = createAsyncThunk<EmployeeI, EmployeeInputData>(
    'createEmployee',
    async (body, { rejectWithValue }) => {
        try {
            const res = await axios.post('ba/employee', body);
            return res.data;
        } catch (err: any) {
            return rejectWithValue(err.response.data);
        }
    },
);

export const getEmployeeById = createAsyncThunk<EmployeeI, string>(
    'getEmployeeById',
    async (id) => {
        const res = await axios.get(`ba/employee/${id}`);
        return res.data.data;
    },
);

export const updateEmployee = createAsyncThunk<ExtendedEmployeeI, DataI>(
    'updateEmployee',
    async ({ id, body }, { rejectWithValue }) => {
        try {
            const res = await axios.patch(`ba/employee/${id}`, body);
            return await res.data.data;
        } catch (err: any) {
            return rejectWithValue(err.response.data);
        }
    },
);

export const blockEmployee = createAsyncThunk<string, string | null>(
    'blockEmployee',
    async (id, { dispatch, rejectWithValue }) => {
        try {
            const res = await axios.post(`ba/employee/${id}/block`);
            dispatch(getEmployees());
            dispatch(directoryActions.setIsModalClose());
            return res.data.data;
        } catch (err: any) {
            return rejectWithValue(err.response.data);
        }
    },
);

export const activeEmployee = createAsyncThunk<string, string | null>(
    'activeEmployee',
    async (id, { dispatch, rejectWithValue }) => {
        try {
            const res = await axios.post(`ba/employee/${id}/active`);
            dispatch(getEmployees());
            dispatch(employeeActions.setIsActiveModalOpen(false));
            return res.data.data;
        } catch (err: any) {
            return rejectWithValue(err.response.data);
        }
    },
);

export const changePassword = createAsyncThunk<string, {id: string, password: string}>(
    'changePassword',
    async ({ id, password }, { dispatch, rejectWithValue }) => {
        try {
            const res = await axios.post(`ba/employee/${id}/change-password`, {
                password,
            });
            dispatch(getEmployees());
            return res.data.data;
        } catch (err: any) {
            return rejectWithValue(err.response.data);
        }
    },
);

export const getAvailableDepartments = createAsyncThunk<AvailableDepartment[]>(
    'getAvailableDepartments',
    async (_, { rejectWithValue }) => {
        try {
            const res = await axios.get('ba/department/available');
            return await res.data.data;
        } catch (err: any) {
            return rejectWithValue(err.response.data);
        }
    },
);

export const getAvailablePositions = createAsyncThunk<AvailablePosition[]>(
    'getAvailablePositions',
    async (_, { rejectWithValue }) => {
        try {
            const res = await axios.get('ba/employee/position/available');
            return await res.data.data;
        } catch (err: any) {
            return rejectWithValue(err.response.data);
        }
    },
);

export const getAvailableRoles = createAsyncThunk<AvailableRole[]>(
    'getAvailableRoles',
    async (_, { rejectWithValue }) => {
        try {
            const res = await axios.get('ba/auth/role/available');
            return await res.data.data;
        } catch (err: any) {
            return rejectWithValue(err.response.data);
        }
    },
);
