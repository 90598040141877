import React from 'react';
import { useNavigate } from 'react-router-dom';
import DeleteIcon from '@mui/icons-material/Delete';
import cn from 'classnames';
import { PriorityProps } from './Priority.props';
import { useAppDispatch } from '../../../../redux/store';
import { getPriorityById } from '../../../../redux/priorities/asyncThunks';
import { priorityActions } from '../../../../redux/priorities/slice';
import { directoryActions } from '../../../../redux/directories/slice';
import styles from '../DirectoryItem/DirectoryItem.module.css';
import { EditButton } from '../../Buttons/EditButton';
import { RedButton } from '../../Buttons/RedButton';

export const Priority: React.FC<PriorityProps> = ({ priority }) => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const handleDeleteButtonClick = (id?: string | null) => {
        dispatch(directoryActions.setIsModalOpen());
        dispatch(priorityActions.setIdToDelete(id));
    };

    const handleEditButtonClick = (id? : string | null) => {
        if (id) {
            navigate(`${id}/update`);
        }
    };

    return (
        <li className={styles.item}>
            <span
                className={cn(styles.name, styles.priority)}
                style={{ backgroundColor: priority.backgroundColor, color: priority.textColor }}
            >
                {priority?.name || ''}
            </span>
            <div className={styles.buttonWrapper}>
                <EditButton onClick={() => handleEditButtonClick(priority?.id)} />
                <RedButton onClick={() => handleDeleteButtonClick(priority?.id)}>
                    <DeleteIcon fontSize="small" />
                </RedButton>
            </div>
        </li>
    );
};
