import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { AvailableRole, ExtendedEmployeeI, initialStateI } from './types';
import { StatusEnum } from '../../interfaces/StatusEnum';
import {
    getEmployees,
    createEmployee,
    updateEmployee,
    getEmployeeById,
    getAvailableDepartments,
    getAvailablePositions,
    getAvailableRoles,
    changePassword,
} from './asyncThunks';

const initialState: initialStateI = {
    employee: {
        id: '',
        firstName: '',
        lastName: '',
        status: '',
        position: {
            id: '',
            name: '',
        },
        department: {
            id: '',
            name: '',
        },
        user: {
            phone: '',
            username: '',
            roles: [],
        },
    },
    employees: [],
    status: null,
    errors: [],
    formErrors: [],
    isLoading: false,
    employeeIdToDelete: null,
    availableDepartments: [],
    availablePositions: [],
    availableRoles: [],
    isActiveModalOpen: false,
    isPasswordModalOpen: false,
};

export const employeeSlice = createSlice({
    name: 'employeeSlice',
    initialState,
    reducers: {
        setIdToBlock(state, action) {
            state.employeeIdToDelete = action.payload;
        },
        setEmployee(state, action: PayloadAction<ExtendedEmployeeI>) {
            state.employee = action.payload;
        },
        setIsActiveModalOpen(state, action: PayloadAction<boolean>) {
            state.isActiveModalOpen = action.payload;
        },
        setIsPasswordModalOpen(state, action: PayloadAction<boolean>) {
            state.isPasswordModalOpen = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getEmployees.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(getEmployees.fulfilled, (state, action) => {
            state.employees = action.payload;
            state.errors = [];
            state.isLoading = false;
            state.status = null;
            state.employee = {
                id: '',
                firstName: '',
                lastName: '',
                status: '',
                position: {
                    id: '',
                    name: '',
                },
                department: {
                    id: '',
                    name: '',
                },
                user: {
                    phone: '',
                    username: '',
                    roles: [],
                },
            };
        });
        builder.addCase(getEmployees.rejected, (state) => {
            state.isLoading = false;
        });
        builder.addCase(createEmployee.pending, (state) => {
            state.status = StatusEnum.PENDING;
            state.isLoading = true;
        });
        builder.addCase(createEmployee.fulfilled, (state) => {
            state.status = StatusEnum.SUCCESS;
            state.formErrors = [];
            state.isLoading = false;
        });
        builder.addCase(createEmployee.rejected, (state, action: PayloadAction<any>) => {
            state.status = StatusEnum.ERROR;
            state.formErrors = action.payload.errors;
            state.isLoading = false;
        });
        builder.addCase(updateEmployee.pending, (state) => {
            state.status = StatusEnum.PENDING;
            state.isLoading = true;
        });
        builder.addCase(updateEmployee.fulfilled, (state) => {
            state.status = StatusEnum.SUCCESS;
            state.isLoading = false;
            state.formErrors = [];
        });
        builder.addCase(updateEmployee.rejected, (state, action: PayloadAction<any>) => {
            state.status = StatusEnum.ERROR;
            state.isLoading = false;
            state.formErrors = action.payload.errors;
        });
        builder.addCase(getEmployeeById.fulfilled, (state, action: PayloadAction<any>) => {
            state.employee = { ...action.payload, user: { ...action.payload.user, roles: action.payload.user.roles.map((i: AvailableRole) => i.id) } };
        });
        builder.addCase(getEmployeeById.rejected, (state) => {
            state.status = StatusEnum.ERROR;
        });
        builder.addCase(getAvailableDepartments.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(getAvailableDepartments.fulfilled, (state, action) => {
            state.isLoading = false;
            state.availableDepartments = action.payload;
            state.errors = [];
        });
        builder.addCase(getAvailableDepartments.rejected, (state, action: PayloadAction<any>) => {
            state.errors = action.payload.errors;
            state.isLoading = false;
        });
        builder.addCase(getAvailablePositions.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(getAvailablePositions.fulfilled, (state, action) => {
            state.isLoading = false;
            state.availablePositions = action.payload;
            state.errors = [];
        });
        builder.addCase(getAvailablePositions.rejected, (state, action: PayloadAction<any>) => {
            state.errors = action.payload.errors;
            state.isLoading = false;
        });
        builder.addCase(getAvailableRoles.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(getAvailableRoles.fulfilled, (state, action) => {
            state.isLoading = false;
            state.availableRoles = action.payload;
            state.errors = [];
        });
        builder.addCase(getAvailableRoles.rejected, (state, action: PayloadAction<any>) => {
            state.errors = action.payload.errors;
            state.isLoading = false;
        });
        builder.addCase(changePassword.pending, (state) => {
            state.isLoading = true;
            state.status = StatusEnum.PENDING;
        });
        builder.addCase(changePassword.fulfilled, (state) => {
            state.isLoading = false;
            state.status = StatusEnum.SUCCESS;
            state.errors = [];
        });
        builder.addCase(changePassword.rejected, (state, action: PayloadAction<any>) => {
            state.errors = action.payload.errors;
            state.status = StatusEnum.ERROR;
            state.isLoading = false;
        });
    },
});

export const { reducer: employeeReducer, actions: employeeActions } = employeeSlice;
