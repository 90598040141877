import React from 'react';
import { ReactComponent as CrossIcon } from '../../../assets/img/header/cross.svg';
import styles from './NotificationsMenu.module.css';
import { NotificationsMenuProps } from './NotificationsMenu.props';

export const NotificationItem = ({ title, status, date }: any) => (
    <li className={styles.notification}>
        <div className={styles.flexWrapper}>
            <p className={styles.title}>{title}</p>
            <p className={styles.date}>{date}</p>
        </div>
        <div className={styles.status}>{status}</div>
    </li>
);

export const NotificationsMenu: React.FC<NotificationsMenuProps> = ({ handleClose }) => (
    <div className={styles.wrapper}>
        <div className={styles.heading}>
            Уведомления
            <button onClick={handleClose}>
                <CrossIcon />
            </button>
        </div>
        <ul className={styles.notificationsList}>
            <NotificationItem title="Новая квитанция" status="Квитанция за февраль" date="Только что" />
            <NotificationItem title="Новая квитанция" status="Квитанция за февраль" date="Только что" />
            <NotificationItem title="Заявка № 12313" status="Статус по заявке обновлен" date="Вчера" />
            <NotificationItem title="Заявка № 12313" status="Статус по заявке обновлен" date="Вчера" />
        </ul>
    </div>
);
