import React from 'react';
import { useNavigate } from 'react-router-dom';
import { StreetProps } from './Street.props';
import { useAppDispatch } from '../../../../redux/store';
import { getStreetById } from '../../../../redux/streets/asyncThunks';
import { streetActions } from '../../../../redux/streets/slice';
import { directoryActions } from '../../../../redux/directories/slice';
import { DirectoryItem } from '../DirectoryItem';

export const Street: React.FC<StreetProps> = ({ street }) => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const handleDeleteButtonClick = (id?: string | null) => {
        dispatch(directoryActions.setIsModalOpen());
        dispatch(streetActions.setIdToDelete(id));
    };

    const handleEditButtonClick = (id? : string | null) => {
        if (id) {
            navigate(`${id}/update`);
        }
    };

    return (
        <DirectoryItem
            item={street}
            handleEditButtonClick={handleEditButtonClick}
            handleDeleteButtonClick={handleDeleteButtonClick}
        />
    );
};
