import React from 'react';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import { DocumentResponseItem } from '../../../../redux/documents/types';
import css from './Document.module.css';

interface DocumentProps {
    document: DocumentResponseItem;
}
export const Document: React.FC<DocumentProps> = ({ document }) => {
    const navigate = useNavigate();

    const handleEditButtonClick = (id?: string | null) => {
        if (id) {
            navigate(`${id}/update`);
        }
    };

    const getStatus = (status: 100 | 200) => (status === 100 ? 'Активный' : 'Архив');
    const getType = (type: 100 | 200) => (type === 100 ? 'Телефонограмма' : 'Информация');

    return (
        <li className={css.doc} onClick={() => handleEditButtonClick(document.id)}>
            <div className={css.header}>
                <div className={css.top}>
                    <div className={css.number}>
                        №
                        {document.number}
                    </div>
                    <div className={css.status} style={{ backgroundColor: document.status === 100 ? '#F9DFE3' : '#6c757d', color: document.status === 100 ? '#CF0627' : 'white' }}>{getStatus(document.status)}</div>
                    <div className={css.author}>{`${document.author.fullName} (${document.author.position.name})`}</div>
                </div>
                <div className={css.type}>
                    <span className={css.label}>Тип: </span>
                    {getType(document.type)}
                </div>
                <div className={css.source}>
                    <span className={css.label}>Источник: </span>
                    {document.source}
                </div>
                <div className={css.date}>
                    <span className={css.label}>Дата начала: </span>
                    {dayjs(document.dateStart).format('DD.MM.YYYY  HH:mm')}
                </div>
                <div className={css.date}>
                    <span className={css.label}>Дата окончания: </span>
                    {dayjs(document.dateEnd).format('DD.MM.YYYY HH:mm')}
                </div>
                <div className={css.date}>
                    <span className={css.label}>Адреса: </span>
                    {document.houses.map((house, index, arr) => `${house.addressString}${index < arr.length - 1 ? ', ' : ''}`)}
                </div>
            </div>
            <div className={css.text}>{document.text}</div>
        </li>
    );
};
