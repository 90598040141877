import { createAsyncThunk } from '@reduxjs/toolkit';
import { Department } from './types';
import axios from '../../axios';
import { departmentActions } from './slice';

type DataI = {
  id: string;
  body: Department;
}

export const getDepartments = createAsyncThunk<Department[]>(
    'getDepartments',
    async (body, { dispatch }) => {
        await dispatch(departmentActions.resetDepartment());
        const res = await axios.get('department');
        return res.data.data;
    },
);

export const createDepartment = createAsyncThunk<Department, Department>(
    'createDepartment',
    async (body, { rejectWithValue }) => {
        try {
            const res = await axios.post('department', body);
            return res.data;
        } catch (err: any) {
            return rejectWithValue(err.response.data);
        }
    },
);

export const deleteDepartment = createAsyncThunk<string, string | null>(
    'deleteDepartment',
    async (id, { dispatch, rejectWithValue }) => {
        try {
            const res = await axios.delete(`department/${id}`);
            dispatch(getDepartments());
            return res.data.data;
        } catch (err: any) {
            return rejectWithValue(err.response.data);
        }
    },
);

export const updateDepartment = createAsyncThunk<Department, DataI>(
    'updateDepartment',
    async ({ id, body }, { rejectWithValue }) => {
        try {
            const res = await axios.patch(`department/${id}`, body);
            return await res.data.data;
        } catch (err: any) {
            return rejectWithValue(err.response.data);
        }
    },
);
export const getDepartmentById = createAsyncThunk<Department, string>(
    'getDepartmentById',
    async (id) => {
        const res = await axios.get(`department/${id}`);
        return res.data.data;
    },
);
