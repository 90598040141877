import React from 'react';
import styles from './Alert.module.css';
import { ReactComponent as PlusIcon } from '../../../../assets/img/plus.svg';

interface AlertProps {
  heading: string;
  text: string;
  withButton?: boolean;
}
export const Alert: React.FC<AlertProps> = ({ heading, text, withButton = false }) => (
    <div className={styles.wrapper}>
        <div className={styles.borderWrapper}>
            <p className={styles.heading}>{heading}</p>
            <p className={styles.text}>{text}</p>
            {withButton && (
                <button className={styles.button}>
                    <PlusIcon />
                    Создать обращение
                </button>
            )}
        </div>
    </div>
);
