import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from '../../../redux/store';
import { getDashboardItems } from '../../../redux/dashboard/asyncThunks';
import {
    getDashboardDocumentsSelector,
    getDashboardTicketsSelector,
    getIsLoadingSelector,
} from '../../../redux/dashboard/selectors';
import { DirectoryTopInfo } from '../../../components/Panel/DirectoryTopInfo/DirectoryTopInfo';
import { EmptyTable } from '../../../components/Panel/EmptyTable';
import { Preloader } from '../../../components/Client/ui/Preloader';
import css from '../Documents/Documents.module.css';
import { DashboardTicket } from './DashboardTicket/DashboardTicket';
import { DashboardDocument } from './DashboardDocument/DashboardDocument';

export const Dashboard = () => {
    const dispatch = useAppDispatch();
    const tickets = useSelector(getDashboardTicketsSelector);
    const documents = useSelector(getDashboardDocumentsSelector);
    const telephonograms = documents.filter((doc) => doc.type === 100);
    const informationDocs = documents.filter((doc) => doc.type === 200);
    const isLoading = useSelector(getIsLoadingSelector);
    useEffect(() => {
        dispatch(getDashboardItems());
    }, []);

    return (
        <main className="content">
            <DirectoryTopInfo
                title="Dashboard"
                withoutButton
            />
            { isLoading
                ? <Preloader />
                : (
                    <div className={css.content}>
                        {telephonograms.length > 0 && (
                            <>
                                <h3>Телефонограммы</h3>
                                <ul className={css.cards}>
                                    {telephonograms.map((i) => <DashboardDocument key={i.id} doc={i} />)}
                                </ul>
                            </>
                        )}
                        {informationDocs.length > 0 && (
                            <>
                                <h3>Информация</h3>
                                <ul className={css.cards}>
                                    {informationDocs.map((i) => <DashboardDocument key={i.id} doc={i} />)}
                                </ul>
                            </>
                        )}
                        {tickets.length > 0 && (
                            <>
                                <h3>Последние обновления</h3>
                                <ul className={css.cards}>
                                    {tickets.map((i) => <DashboardTicket key={i.id} item={i} />)}
                                </ul>
                            </>
                        )}
                        {!documents.length && !tickets.length && <EmptyTable />}
                    </div>
                )}
        </main>
    );
};
