import React, { useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import { useSelector } from 'react-redux';

import { useParams } from 'react-router-dom';
import { useAppDispatch } from '../../../../redux/store';
import { ErrorsAlert } from '../../ErrorAlert';
import { ButtonPrimary } from '../../Buttons/ButtonPrimary';
import { useSuccessNavigate } from '../../../../utils/hooks/useSuccessNavigate';
import {
    getStatusSelector, getIsLoadingSelector, getPositionSelector, getFormErrorsSelector,
} from '../../../../redux/positions/selectors';
import { createPosition, getPositionById, updatePosition } from '../../../../redux/positions/asyncThunks';
import { Position } from '../../../../redux/positions/types';
import { Preloader } from '../../../Client/ui/Preloader';

export const PositionForm = () => {
    const { id } = useParams();
    const errors = useSelector(getFormErrorsSelector);
    const status = useSelector(getStatusSelector);
    const isLoading = useSelector(getIsLoadingSelector);
    const position = useSelector(getPositionSelector);
    const dispatch = useAppDispatch();
    useSuccessNavigate('/bo/employee/position', status);
    const isEditing = Boolean(id);
    const [positionValues, setPositionValues] = useState<Position>({
        name: null,
    });

    useEffect(() => {
        if (id) {
            dispatch(getPositionById(id));
        }
    }, []);

    useEffect(() => {
        if (isEditing) {
            setPositionValues(position);
        }
    }, [position]);

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const body = {
            name: positionValues.name,
        };
        if (isEditing && id) {
            dispatch(updatePosition({ id, body }));
        } else {
            dispatch(createPosition(body));
        }
    };

    if (isEditing && !position.id) {
        return <Preloader />;
    }

    return (
        <Box
            component="form"
            onSubmit={handleSubmit}
            className="form"
            noValidate
        >
            {errors.length > 0 && <ErrorsAlert errors={errors} />}
            <TextField
                id="name"
                label="Имя"
                name="name"
                required
                variant="standard"
                value={positionValues.name || ''}
                onChange={(e) => setPositionValues({ ...positionValues, name: e.target.value })}
            />
            <ButtonPrimary isLoading={isLoading} isEditing={isEditing} />
        </Box>
    );
};
