import React, { useCallback, useLayoutEffect } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from '../../../../redux/store';
import { deletePriority, getPriorities } from '../../../../redux/priorities/asyncThunks';
import {
    getPrioritiesSelector,
    getIsLoadingSelector,
    getIdToDeleteSelector,
    getErrorsSelector, getStatusSelector,
} from '../../../../redux/priorities/selectors';
import { EmptyTable } from '../../EmptyTable';
import { Preloader } from '../../../Client/ui/Preloader';
import { Priority } from './Priority';
import { DirectoryPage } from '../DirectoryPage';

const prioritiesInfo = {
    path: '/bo/directories/priorities/create',
    title: 'Приоритеты',
    text: 'приоритет',
};

const prioritiesHeadings = ['Имя', 'Действия'];

export const Priorities: React.FC = () => {
    const dispatch = useAppDispatch();
    const priorities = useSelector(getPrioritiesSelector);
    const isLoading = useSelector(getIsLoadingSelector);
    const idToDelete = useSelector(getIdToDeleteSelector);
    const errors = useSelector(getErrorsSelector);
    const status = useSelector(getStatusSelector);

    useLayoutEffect(() => {
        dispatch(getPriorities());
    }, []);

    const modalHandler = useCallback(() => {
        dispatch(deletePriority(idToDelete));
    }, [idToDelete]);

    return (
        <DirectoryPage
            headings={prioritiesHeadings}
            info={prioritiesInfo}
            modalHandler={modalHandler}
            errors={errors}
            status={status}
        >
            {isLoading
                ? <Preloader />
                : (
                    <ul>
                        {priorities.length > 0
                            ? priorities.map((i) => <Priority key={i.id} priority={i} />)
                            : <EmptyTable />}
                    </ul>
                )}
        </DirectoryPage>

    );
};
