import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from '../../../../components/Client/ui/Button';
import { ReactComponent as ClipIcon } from '../../../../assets/img/news/clip.svg';
import { NewsItem } from '../../Home/NewsItem/NewsItem';
import mockImg from './news-full-mock.png';
import styles from './NewsFull.module.css';
import { BreadCrumbs } from '../../../../components/Client/BreadCrumbs';

export const NewsFull = () => (
    <main className="content">
        <BreadCrumbs />
        <h1 className={styles.title}>
            Уведомление о проведении профилактических работ
        </h1>
        <p className={styles.date}>
            20 сентября
        </p>
        <div className={styles.gridWrapper}>
            <div>
                <img className={styles.image} src={mockImg} alt="Изображение новости" />
                <div className={styles.textWrapper}>
                    <p className={styles.text}>
                        Уважаемые собственники домов по адресам: Воронцовский б-р, 20 к.1,20 к.2, 20 к.3, ул. Графская, 8, ул. Шувалова, 23!
                    </p>
                    <p className={styles.text}>
                        Во исполнение п. 31 постановления Правительства РФ от 13.08.2006 № 416 и в соответствии с п. 4.6 Договора управления МКД,
                        п. 3.3 Договора управления автостоянки управляющая организация ООО «УК «Диал» уведомляет Вас об изменении размера платы за
                        содержание и текущий ремонт общего имущества МКД методом индексирования данных цен в соответствии с изменением уровня потребительских
                        цен за истекший 2021 год в форме базового индекса потребительских цен, рассчитанного территориальным органом Росстата в соответствии
                        с основными положениями определения индекса потребительских цен.
                    </p>
                </div>
                <div className={styles.separator} />
            </div>
            <div className={styles.share}>
                <p className={styles.shareText}>
                    Расскажите об этой новости
                </p>
                <Button className={styles.button} size="s" appearance="outlined">
                    <ClipIcon />
                    Скопировать ссылку
                </Button>
            </div>
        </div>
        <div>
            <div className={styles.otherNewsWrapper}>
                <h2 className={styles.otherNewsTitle}>Другие новости</h2>
                <Link className={styles.allNews} to="/news">
                    <Button className={styles.button} appearance="outlined">Все новости</Button>
                </Link>
            </div>
            <ul className={styles.newsList}>
                <NewsItem />
                <NewsItem isChecked />
            </ul>
            <Link className={styles.allNewsBottom} to="/news">
                <Button className={styles.button} appearance="outlined">Все новости</Button>
            </Link>
        </div>
    </main>
);
