import React, { SyntheticEvent, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';

import { useParams } from 'react-router-dom';
import Textarea from '@mui/joy/Textarea';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { ruRU } from '@mui/x-date-pickers/locales';
import { useAppDispatch } from '../../../redux/store';
import { ErrorsAlert } from '../../../components/Panel/ErrorAlert';
import {
    getStatusSelector,
    getIsLoadingSelector,
    getFormErrorsSelector, getDocumentSelector,
} from '../../../redux/documents/selectors';

import { ButtonPrimary } from '../../../components/Panel/Buttons/ButtonPrimary';
import { useSuccessNavigate } from '../../../utils/hooks/useSuccessNavigate';
import { ToggleGroup } from '../../../components/Client/ui/ToggleGroup';
import { docTypeToggleData } from '../../../utils/data/docTypeToggleData';
import { DropDownMenu } from '../../../components/Panel/DropDownMenu';
import { docStatusDropDownItems } from '../../../utils/data/docStatusDropDownItems';
import { MultipleAutoComplete } from '../../../components/Panel/MultipleAutoCompleteMenu/MultipleAutoComplete';
import { getAddresses } from '../../../redux/tickets/asyncThunks';
import { getAddressesSelector } from '../../../redux/tickets/selectors';
import { AddressI } from '../../../interfaces/Tickets';
import { createDocument, getDocumentById, updateDocument } from '../../../redux/documents/asyncThunks';
import { Preloader } from '../../../components/Client/ui/Preloader';
import { formatDateToUtc } from '../../../utils/helpers/formatDate';

type DocValues = {
    type: string,
    source: string,
    text: string,
    dateStart: any | null,
    dateEnd: any | null,
    status: string,
    houses: {id: string, addressString: string}[]
}

export const DocumentForm = () => {
    const { id } = useParams();
    const houses = useSelector(getAddressesSelector);
    const errors = useSelector(getFormErrorsSelector);
    const status = useSelector(getStatusSelector);
    const isLoading = useSelector(getIsLoadingSelector);
    const document = useSelector(getDocumentSelector);
    const isEditing = Boolean(id);
    const dispatch = useAppDispatch();
    useSuccessNavigate('/bo/document', status);
    const [docValues, setDocValues] = useState<DocValues>({
        type: '100',
        source: '',
        text: '',
        dateStart: dayjs(new Date()),
        dateEnd: dayjs(new Date()),
        status: '100',
        houses: [],
    });

    useEffect(() => {
        if (id) {
            dispatch(getDocumentById(id));
        }
        dispatch(getAddresses());
    }, []);

    useEffect(() => {
        if (isEditing) {
            setDocValues({
                type: String(document?.type),
                source: document?.source || '',
                dateStart: dayjs(document?.dateStart),
                dateEnd: dayjs(document?.dateEnd),
                text: document?.text || '',
                status: String(document?.status),
                houses: document?.houses.map((i) => ({ id: i.id, addressString: i.addressString })) || [],
            });
        }
    }, [document]);

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const body = {
            ...docValues,
            type: +docValues.type,
            status: +docValues.status,
            dateStart: docValues.dateStart ? formatDateToUtc(dayjs(docValues.dateStart.$d).format('YYYY-MM-DDTHH:mm:ss')) : null,
            dateEnd: docValues.dateEnd ? formatDateToUtc(dayjs(docValues.dateEnd.$d).format('YYYY-MM-DDTHH:mm:ss')) : null,
            houses: docValues.houses.map((i) => i?.id),
        };

        if (isEditing && id) {
            dispatch(updateDocument({ id, body }));
        } else {
            dispatch(createDocument(body));
        }
    };

    const onChangeDocType = (newType: string) => {
        if (newType !== null) {
            setDocValues({ ...docValues, type: newType });
        }
    };

    const handleChangeHouses = (_: SyntheticEvent<Element, Event>, newSelectedItems: any[]) => {
        setDocValues({ ...docValues, houses: newSelectedItems });
    };

    if (isLoading) {
        return <Preloader />;
    }

    return (
        <main className="content">
            <Box
                component="form"
                onSubmit={handleSubmit}
                className="form"
                noValidate
            >
                {errors.length > 0 && <ErrorsAlert errors={errors} />}
                <ToggleGroup value={docValues.type} buttonData={docTypeToggleData} onChange={onChangeDocType} />
                <TextField
                    id="source"
                    label="Источник"
                    name="source"
                    required
                    variant="standard"
                    value={docValues.source || ''}
                    onChange={(e) => setDocValues({ ...docValues, source: e.target.value })}
                />
                <Textarea
                    required
                    placeholder="Напишите что-нибудь здесь…"
                    value={docValues.text}
                    onChange={(e) => setDocValues({ ...docValues, text: e.target.value })}
                    minRows={3}
                />
                <LocalizationProvider
                    dateAdapter={AdapterDayjs}
                    adapterLocale="ru"
                    localeText={ruRU.components.MuiLocalizationProvider.defaultProps.localeText}
                >
                    <DateTimePicker
                        format="DD.MM.YYYY HH:mm"
                        label="Дата начала"
                        value={docValues.dateStart}
                        onChange={(newValue) => setDocValues({ ...docValues, dateStart: newValue })}
                        ampm={false}
                    />
                    <DateTimePicker
                        format="DD.MM.YYYY HH:mm"
                        label="Дата окончания"
                        value={docValues.dateEnd}
                        onChange={(newValue) => setDocValues({ ...docValues, dateEnd: newValue })}
                        ampm={false}
                    />
                    <DropDownMenu
                        required
                        label="Статус"
                        value={docValues.status}
                        handleChange={(e) => setDocValues({ ...docValues, status: e.target.value })}
                        items={docStatusDropDownItems}
                    />
                </LocalizationProvider>
                <MultipleAutoComplete required items={houses} value={docValues.houses} handleChange={handleChangeHouses} />
                <ButtonPrimary isLoading={isLoading} isEditing={isEditing} />
            </Box>
        </main>
    );
};
