import React from 'react';
import { SectionTitle } from '../../../../components/Client/ui/SectionTitle';
import { YearsFilter } from '../../../../components/Client/ui/YearsFilter/YearsFilter';
import { NewsItem } from '../../Home/NewsItem/NewsItem';
import styles from './NewsPage.module.css';

export const NewsPage = () => (
    <main className="content">
        <SectionTitle>Новости</SectionTitle>
        <YearsFilter className={styles.filter} />
        <ul className={styles.newsList}>
            <NewsItem isPinned />
            <NewsItem isChecked />
            <NewsItem />
            <NewsItem />
            <NewsItem />
            <NewsItem />
            <NewsItem />
        </ul>
    </main>
);
