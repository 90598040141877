import React from 'react';
import styles from './EmptyInfo.module.css';
import { EmptyInfoProps } from './EmptyInfo.props';

export const EmptyInfo: React.FC<EmptyInfoProps> = ({ item }) => (
    <div className={styles.wrapper}>
        Выберите&nbsp;
        {item === 'directory' ? 'справочник' : 'заявку'}
    </div>
);
