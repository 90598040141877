import React, { useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import { useSelector } from 'react-redux';

import { useParams } from 'react-router-dom';
import { useAppDispatch } from '../../../../redux/store';
import { ErrorsAlert } from '../../ErrorAlert';
import { ButtonPrimary } from '../../Buttons/ButtonPrimary';
import { useSuccessNavigate } from '../../../../utils/hooks/useSuccessNavigate';
import {
    getStreetSelector, getIsLoadingSelector, getStatusSelector, getFormErrorsSelector,
} from '../../../../redux/streets/selectors';
import { Street } from '../../../../redux/streets/types';
import { createStreet, getStreetById, updateStreet } from '../../../../redux/streets/asyncThunks';
import { DropDownMenu } from '../../DropDownMenu';
import { getSettlementsSelector } from '../../../../redux/settlements/selectors';
import { Preloader } from '../../../Client/ui/Preloader';
import { getSettlements } from '../../../../redux/settlements/asyncThunks';

export const StreetForm = () => {
    const { id } = useParams();
    const errors = useSelector(getFormErrorsSelector);
    const status = useSelector(getStatusSelector);
    const isLoading = useSelector(getIsLoadingSelector);
    const street = useSelector(getStreetSelector);
    const settlements = useSelector(getSettlementsSelector);
    const dispatch = useAppDispatch();
    useSuccessNavigate('/bo/geo/street', status);
    const isEditing = Boolean(id);
    const [streetValues, setStreetValues] = useState<Street>({
        settlementId: null,
        name: null,
    });

    useEffect(() => {
        dispatch(getSettlements());
        if (id) {
            dispatch(getStreetById(id));
        }
    }, []);

    useEffect(() => {
        if (isEditing) {
            setStreetValues(street);
        }
    }, [street]);

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const body = {
            settlementId: streetValues.settlementId,
            name: streetValues.name,
        };
        if (isEditing && id) {
            dispatch(updateStreet({ id, body }));
        } else {
            dispatch(createStreet(body));
        }
    };

    if (isEditing && !street.id) {
        return <Preloader />;
    }

    return (
        <Box
            component="form"
            onSubmit={handleSubmit}
            className="form"
            noValidate
        >
            {errors.length > 0 && <ErrorsAlert errors={errors} />}
            <DropDownMenu
                required
                label="Выберите населенный пункт"
                value={streetValues.settlementId || ''}
                handleChange={(e) => setStreetValues({ ...streetValues, settlementId: e.target.value })}
                items={settlements}
            />

            <TextField
                id="name"
                label="Имя"
                name="name"
                required
                variant="standard"
                value={streetValues.name || ''}
                onChange={(e) => setStreetValues({ ...streetValues, name: e.target.value })}
            />
            <ButtonPrimary isLoading={isLoading} isEditing={isEditing} />
        </Box>
    );
};
