import { configureStore } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';
import { authReducer } from './auth/slice';
import { directoryReducer } from './directories/slice';
import { priorityReducer } from './priorities/slice';
import { categoryReducer } from './categories/slice';
import { positionReducer } from './positions/slice';
import { settlementReducer } from './settlements/slice';
import { streetReducer } from './streets/slice';
import { departmentReducer } from './departments/slice';
import { houseReducer } from './houses/slice';
import { roomReducer } from './rooms/slice';
import { roleReducer } from './role/slice';
import { ticketReducer } from './tickets/slice';
import { employeeReducer } from './employees/slice';
import { ruleReducer } from './assignmentRules/slice';
import { photoZoomReducer } from './photoZoomer/slice';
import { documentReducer } from './documents/slice';
import { dashboardReducer } from './dashboard/slice';
import { customerReducer } from './customer/slice';

export const store = configureStore({
    reducer: {
        authReducer,
        directoryReducer,
        priorityReducer,
        categoryReducer,
        positionReducer,
        settlementReducer,
        streetReducer,
        departmentReducer,
        houseReducer,
        roomReducer,
        roleReducer,
        ticketReducer,
        employeeReducer,
        ruleReducer,
        photoZoomReducer,
        documentReducer,
        dashboardReducer,
        customerReducer,
    },
});

export type RootState = ReturnType<typeof store.getState>;

type AppDispatch = typeof store.dispatch;

export const useAppDispatch = () => useDispatch<AppDispatch>();
