import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { initialStateI } from './types';
import { StatusEnum } from '../../interfaces/StatusEnum';
import {
    createHouse, deleteHouse, getHouseById, getHouses, updateHouse,
} from './asyncThunks';

const initialState: initialStateI = {
    house: {
        streetId: null,
        name: null,
        isApartmentHouse: null,
        departmentId: null,
        id: null,
        latitude: null,
        longitude: null,
    },
    houses: [],
    status: null,
    errors: [],
    formErrors: [],
    isLoading: false,
    houseIdToDelete: null,
};

export const houseSlice = createSlice({
    name: 'houseSlice',
    initialState,
    reducers: {
        setIdToDelete(state, action) {
            state.houseIdToDelete = action.payload;
        },
        resetHouse(state) {
            state.house.id = null;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getHouses.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(getHouses.fulfilled, (state, action) => {
            state.houses = action.payload;
            state.errors = [];
            state.isLoading = false;
            state.status = null;
        });
        builder.addCase(getHouses.rejected, (state) => {
            state.isLoading = false;
        });
        builder.addCase(createHouse.pending, (state) => {
            state.status = StatusEnum.PENDING;
            state.isLoading = true;
        });
        builder.addCase(createHouse.fulfilled, (state) => {
            state.status = StatusEnum.SUCCESS;
            state.formErrors = [];
            state.isLoading = false;
        });
        builder.addCase(createHouse.rejected, (state, action: PayloadAction<any>) => {
            state.status = StatusEnum.ERROR;
            state.formErrors = action.payload.errors;
            state.isLoading = false;
        });
        builder.addCase(updateHouse.pending, (state) => {
            state.status = StatusEnum.PENDING;
            state.isLoading = true;
        });
        builder.addCase(updateHouse.fulfilled, (state, action) => {
            state.status = StatusEnum.SUCCESS;
            state.house = action.payload;
            state.isLoading = false;
            state.formErrors = [];
        });
        builder.addCase(updateHouse.rejected, (state, action: PayloadAction<any>) => {
            state.status = StatusEnum.ERROR;
            state.formErrors = action.payload.errors;
            state.isLoading = false;
        });
        builder.addCase(getHouseById.fulfilled, (state, action: PayloadAction<any>) => {
            state.house.streetId = action.payload.street.id;
            state.house.name = action.payload.name;
            state.house.isApartmentHouse = action.payload.isApartmentHouse;
            state.house.departmentId = action.payload.department.id;
            state.house.id = action.payload.id;
            state.house.latitude = action.payload.latitude;
            state.house.longitude = action.payload.longitude;
        });
        builder.addCase(deleteHouse.pending, (state) => {
            state.status = StatusEnum.PENDING;
        });
        builder.addCase(deleteHouse.fulfilled, (state) => {
            state.status = StatusEnum.SUCCESS;
            state.errors = [];
        });
        builder.addCase(deleteHouse.rejected, (state, action: PayloadAction<any>) => {
            state.status = StatusEnum.ERROR;
            state.errors = action.payload.errors;
        });
    },
});

export const { reducer: houseReducer, actions: houseActions } = houseSlice;
