import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { groupBy } from 'lodash';
import { InitialStateI } from './types';

import { getDashboardItems } from './asyncThunks';

const initialState: InitialStateI = {
    isLoading: false,
    tickets: [],
    documents: [],
};

export const dashboardSlice = createSlice({
    name: 'dashboardSlice',
    initialState,
    reducers: {
    },
    extraReducers: (builder) => {
        builder.addCase(getDashboardItems.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(getDashboardItems.rejected, (state, action: PayloadAction<any>) => {
            state.isLoading = false;
        });
        builder.addCase(getDashboardItems.fulfilled, (state, action: PayloadAction<any>) => {
            state.isLoading = false;
            state.tickets = action.payload.tickets.map((t: any) => {
                const groupObject = groupBy(t.lastLogs, (log) => log.author.fullName + log.createdAt);
                const logs = Object.keys(groupObject).map((key: string) => groupObject[key]);
                return { ...t, lastLogs: logs };
            });
            state.documents = action.payload.documents;
        });
    },
});

export const { reducer: dashboardReducer, actions: dashboardActions } = dashboardSlice;
