import { NavLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Box } from '@mui/material';
import { getTicketNumberSelector } from '../../../../redux/tickets/selectors';
import { useAppDispatch } from '../../../../redux/store';
import { ticketActions } from '../../../../redux/tickets/slice';

export const TicketSuccess = () => {
    const dispatch = useAppDispatch();
    const ticketNumber = useSelector(getTicketNumberSelector);
    const backToTickets = () => {
        dispatch(ticketActions.resetSuccessCreated());
    };

    return (
        <Box sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            minHeight: '50vh',
            flexDirection: 'column',
            gap: '10px',
        }}
        >
            <h1>
                Заявка успешно создана

            </h1>
            <p style={{ fontSize: '36px' }}>
                Номер заявки:&nbsp;
                {ticketNumber}
            </p>

            <NavLink style={{ marginTop: 100, color: 'var(--green)' }} to="/bo/ticket" onClick={backToTickets}> &larr; К заявкам</NavLink>
        </Box>
    );
};
