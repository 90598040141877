import React from 'react';
import cn from 'classnames';
import { Link } from 'react-router-dom';
import { NewsItemProps } from './NewsItem.props';
import { ReactComponent as PinIcon } from '../../../../assets/img/news/pin.svg';
import styles from './NewsItem.module.css';

export const NewsItem: React.FC<NewsItemProps> = ({ isChecked = false, isPinned = false, className }) => (
    <li className={cn(styles.wrapper, className, {
        [styles.checked]: isChecked,
    })}
    >
        <Link to="fullNews">
            {isPinned && (
                <div className={styles.pinIconWrapper}>
                    <PinIcon className={styles.pinIcon} />
                </div>
            )}
            <p className={styles.date}>20 сентября</p>
            <h3 className={styles.heading}>
                Уведомление о проведении
                профилактических работ
            </h3>
            <p className={styles.text}>
                Котельная ФГПУ ВНИИПО МЧС России будет остановлена
                с 11.07.2022 г. по 24.07.2002 г. Котельная ФГПУ ВНИИПО
                МЧС России будет остановлена с 11.07.2022 г. по 24.07.2002 г.
                Котельная ФГПУ ВНИИПО МЧС России будет остановлена с 11.07.2022 г. по 24.07.2002 г.
                Котельная ФГПУ ВНИИПО МЧС России будет остановлена с 11.07.2022 г. по 24.07.2002 г.
            </p>
        </Link>
    </li>
);
