import dayjs from 'dayjs';
import { LogItem, LogTypeEnum } from '../../interfaces/LogItem';

export const renderLogText = (log: LogItem) => {
    switch (log.type) {
    case LogTypeEnum.ATTACHMENT:
        return '';
    case LogTypeEnum.COMMENT:
        return `Оставил комментарий: ${log.text}`;
    case LogTypeEnum.PRIORITY:
        return `Приоритет: ${log.textPrev ? `${log.textPrev} -> ` : ''}${log.text}`;
    case LogTypeEnum.PLANNED_ENDED_AT:
        return `Срок: ${log.textPrev ? `${dayjs(log.textPrev).format('YYYY-MM-DD HH:MM')}->` : ''}${dayjs(log.text).format('YYYY-MM-DD HH:MM')}`;
    case LogTypeEnum.PLANNED_FINISHED_AT:
        return `Срок для жителя: ${log.textPrev ? `${dayjs(log.textPrev).format('YYYY-MM-DD HH:MM')}->` : ''}${dayjs(log.text).format('YYYY-MM-DD HH:MM')}`;
    case LogTypeEnum.STATUS:
        return `Статус: ${log.textPrev ? `${log.textPrev} -> ` : ''}${log.text}`;
    case LogTypeEnum.ASSIGN_EXECUTOR:
        return `Исполнитель: ${log.textPrev ? `${log.textPrev} -> ` : ''}${log.text}`;
    case LogTypeEnum.CHANGE_CATEGORY:
        return `Категория: ${log.textPrev ? `${log.textPrev} -> ` : ''}${log.text}`;
    default:
        return log.text;
    }
};
