import React from 'react';

const availableDigits = ['0', '1', '2', '3', '4', '5', '6', '8', '9'];
export const getInputNumbersValue = (input: any) => input.value.replace(/\D/g, '');
export const onPhonePaste = (e: React.ClipboardEvent) => {
    const input = e.target as HTMLInputElement;
    const inputNumbersValue = getInputNumbersValue(input);
    const pasted = e.clipboardData;
    if (pasted) {
        const pastedText = pasted.getData('Text');
        if (/\D/g.test(pastedText)) {
            // при попытке ввода не числа
            input.value = inputNumbersValue;
        }
    }
};

export const onPhoneInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const input = e.target;
    let inputNumbersValue = getInputNumbersValue(input);
    const { selectionStart } = input;
    let formattedInputValue = '';

    if (!inputNumbersValue) {
        input.value = '';
        return input.value;
    }

    if (input.value.length !== selectionStart) {
        // Редактирование в центре инпута
        // @ts-ignore
        if (e.data && /\D/g.test(e.data)) {
        // при попытке ввода не числа
            input.value = inputNumbersValue;
        }
        return input.value;
    }

    if (availableDigits.includes(inputNumbersValue[0])) inputNumbersValue = `7${inputNumbersValue}`;
    const firstSymbols = '+7';
    formattedInputValue = input.value = `${firstSymbols} `;
    if (inputNumbersValue.length > 1) {
        formattedInputValue += `(${inputNumbersValue.substring(1, 4)}`;
    }
    if (inputNumbersValue.length >= 5) {
        formattedInputValue += `) ${inputNumbersValue.substring(4, 7)}`;
    }
    if (inputNumbersValue.length >= 8) {
        formattedInputValue += `-${inputNumbersValue.substring(7, 9)}`;
    }
    if (inputNumbersValue.length >= 10) {
        formattedInputValue += `-${inputNumbersValue.substring(9, 11)}`;
    }

    input.value = formattedInputValue;
    return input.value;
};
export const onPhoneKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    // Очистка первого символа
    const input = e.target as HTMLInputElement;
    const inputValue = input.value.replace(/\D/g, '');
    if (e.keyCode === 8 && inputValue.length === 1) {
        input.value = '';
    }
};
