import React, { useLayoutEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import PersonIcon from '@mui/icons-material/Person';
import StairsIcon from '@mui/icons-material/Stairs';
import RefreshIcon from '@mui/icons-material/Refresh';
import HomeIcon from '@mui/icons-material/Home';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import EditIcon from '@mui/icons-material/Edit';
import PhoneIcon from '@mui/icons-material/Phone';
import { Button } from '@mui/material';
import cn from 'classnames';
import { format } from 'date-fns';
import styles from './TicketFull.module.css';
import {
    getLogsSelector,
    getStatusSelector,
    getTicketIsLoadingSelector,
    getTicketSelector,
} from '../../../../redux/tickets/selectors';
import { Preloader } from '../../../../components/Client/ui/Preloader';
import { formatDate } from '../../../../utils/helpers/formatDate';
import { useAppDispatch } from '../../../../redux/store';
import {
    changeCustomer,
    getLogs,
    getTicketById,
    returnStatus,
    sendComment,
    startStatus,
} from '../../../../redux/tickets/asyncThunks';
import { HistoryItem } from './HistoryItem';
import { ModalEditExecutor } from '../../../../components/Panel/ModalEditExecutor';
import { CommentBox } from '../../../../components/Panel/CommentBox';
import { ModalEditCategory } from '../../../../components/Panel/ModalEditCategory';
import { ModalEditPriority } from '../../../../components/Panel/ModalEditPriority';
import { ModalConfirm } from '../../../../components/Panel/ModalConfirm';
import { TicketActionsEnum } from '../../../../interfaces/TicketActionsEnum';
import { ModalByAction } from '../../../../components/Panel/ModalByAction';
import { formatPhoneNumber } from '../../../../utils/helpers/formatPhoneNumber';
import { photoZoomActions } from '../../../../redux/photoZoomer/slice';
import { ModalNewCustomer } from '../../../../components/Panel/ModalNewCustomer';

export const TicketFull: React.FC = () => {
    const { id } = useParams();
    const dispatch = useAppDispatch();

    const currentTicket = useSelector(getTicketSelector);
    const isLoading = useSelector(getTicketIsLoadingSelector);
    const logs = useSelector(getLogsSelector);
    const status = useSelector(getStatusSelector);
    const [isExecutorModalOpen, setIsExecutorModalOpen] = useState<boolean>(false);
    const [isCustomerModalOpen, setIsCustomerModalOpen] = useState<boolean>(false);
    const [isCategoryModalOpen, setIsCategoryModalOpen] = useState<boolean>(false);
    const [isPriorityModalOpen, setIsPriorityModalOpen] = useState<boolean>(false);
    const [modalByAction, setModalByAction] = useState<{isOpen: boolean, action: TicketActionsEnum | null}>({ isOpen: false, action: null });

    const [isReturnConfirmOpen, setIsReturnConfirmOpen] = useState<boolean>(false);

    const getTicketData = () => {
        if (!id) return;
        dispatch(getTicketById(id));
        dispatch(getLogs(id));
    };

    useLayoutEffect(() => {
        getTicketData();
    }, []);

    if (isLoading || !currentTicket) {
        return (
            <div className={styles.wrapper}>
                <Preloader />
            </div>
        );
    }

    const onOpenExecutorModal = () => {
        setIsExecutorModalOpen(true);
    };

    const onOpenCustomerModal = () => {
        setIsCustomerModalOpen(true);
    };

    const onCloseExecutorModal = () => {
        setIsExecutorModalOpen(false);
    };

    const onCloseCustomerModal = () => {
        setIsCustomerModalOpen(false);
    };

    const onOpenCategoryModal = () => {
        setIsCategoryModalOpen(true);
    };

    const onCloseCategoryModal = () => {
        setIsCategoryModalOpen(false);
    };

    const onOpenPriorityModal = () => {
        setIsPriorityModalOpen(true);
    };

    const onClosePriorityModal = () => {
        setIsPriorityModalOpen(false);
    };

    const onCloseModalByAction = () => {
        setModalByAction({ isOpen: false, action: null });
    };

    const onSubmitComment = (comment: string) => {
        dispatch(sendComment({ id: currentTicket.id, comment }));
    };

    const returnTicket = () => {
        dispatch(returnStatus(currentTicket.id));
    };

    const startTicket = () => {
        dispatch(startStatus(currentTicket.id));
    };

    const handleZoom = (src: string) => {
        dispatch(photoZoomActions.setPhotoInZoom(src));
    };

    return (
        <div className={styles.wrapper}>
            <h1>
                Заявка №&nbsp;
                {currentTicket?.number}
            </h1>
            <div className={styles.blockWrapper}>
                <span className={styles.blockLabel}>Основная информация</span>
                <div className={styles.categoryWrapper}>
                    <h3>{currentTicket.category.name}</h3>
                    {currentTicket.actions.indexOf(TicketActionsEnum.CHANGE_CATEGORY) > -1
                        && (
                            <Button onClick={() => onOpenCategoryModal()}>
                                Изменить категорию
                            </Button>
                        )}
                </div>
                <span>{currentTicket.text}</span>
            </div>
            <div className={styles.blockWrapper}>
                <div className={styles.infoWrapper}>
                    <HomeIcon />
                    <div className={styles.flexWrapper}>
                        <span className={styles.blockLabel}>Адрес</span>
                        <span>{currentTicket.addressString}</span>
                    </div>
                </div>
                {currentTicket.customer
                && (
                    <>
                        <div className={styles.infoWrapper}>
                            <PersonIcon />
                            <div className={styles.flexWrapper}>
                                <span className={styles.blockLabel}>Житель</span>
                                <span>{currentTicket?.customer.fullName}</span>
                            </div>
                            <button className={styles.button} onClick={onOpenCustomerModal}>
                                <EditIcon />
                            </button>
                        </div>
                        <div className={styles.infoWrapper}>
                            <PhoneIcon />
                            <div className={styles.flexWrapper}>
                                <span className={styles.blockLabel}>Номер телефона</span>
                                <span>{formatPhoneNumber(currentTicket.customer.phone)}</span>
                            </div>
                        </div>
                    </>
                )}
            </div>
            <div className={styles.blockWrapper}>
                <div className={styles.infoWrapper}>
                    <PersonIcon />
                    <div className={styles.flexWrapper}>
                        <span>{currentTicket.executor?.fullName || 'Исполнителя нет'}</span>
                        <span className={styles.blockLabel}>Исполнитель</span>
                    </div>
                    {currentTicket.actions.indexOf(TicketActionsEnum.ASSIGN_EXECUTOR) > -1
                      && (
                          <button className={styles.button} onClick={onOpenExecutorModal}>
                              <EditIcon />
                          </button>
                      )}
                </div>
                <div className={styles.infoWrapper}>
                    <StairsIcon />
                    <div className={styles.flexWrapper}>
                        <span className={styles.priority} style={{ backgroundColor: currentTicket.priority.backgroundColor, color: currentTicket.priority.textColor }}>{currentTicket.priority.name}</span>
                        <span className={styles.blockLabel}>Приоритет</span>
                    </div>
                    {currentTicket.actions.indexOf(TicketActionsEnum.CHANGE_PRIORITY) > -1
                      && (
                          <button className={styles.button} onClick={onOpenPriorityModal}>
                              <EditIcon />
                          </button>
                      )}
                </div>
                <div className={styles.infoWrapper}>
                    <RefreshIcon />
                    <div className={styles.flexWrapper}>
                        <span
                            className={styles.status}
                            style={{ backgroundColor: currentTicket.status.backgroundColor, color: currentTicket.status.textColor }}
                        >
                            {currentTicket.status.name}
                        </span>
                        <span className={cn(styles.blockLabel, styles.statusLabel)}>Статус</span>
                    </div>
                    <div className={styles.buttonWrapper}>
                        {/* <Button onClick={() => setIsStartConfirmOpen(true)}> */}
                        {/*    Взять в работу */}
                        {/* </Button> */}
                        {/* <Button onClick={() => setIsEndModalOpen(true)}> */}
                        {/*    Завершить работу */}
                        {/* </Button> */}
                        {currentTicket.actions.indexOf(TicketActionsEnum.DONE) > -1
                          && (
                              <Button onClick={() => setModalByAction({ isOpen: true, action: TicketActionsEnum.DONE })}>
                                  Завершить
                              </Button>
                          )}
                        {currentTicket.actions.indexOf(TicketActionsEnum.CANCEL) > -1
                          && (
                              <Button onClick={() => setModalByAction({ isOpen: true, action: TicketActionsEnum.CANCEL })}>
                                  Отменить
                              </Button>
                          )}
                        {currentTicket.actions.indexOf(TicketActionsEnum.RETURN) > -1
                          && (
                              <Button onClick={() => setIsReturnConfirmOpen(true)}>
                                  Вернуть в работу
                              </Button>
                          )}
                        {currentTicket.actions.indexOf(TicketActionsEnum.DELAY) > -1
                          && (
                              <Button onClick={() => setModalByAction({ isOpen: true, action: TicketActionsEnum.DELAY })}>
                                  Отложить
                              </Button>
                          )}
                        {currentTicket.actions.indexOf(TicketActionsEnum.RETURN_IN_WORK) > -1
                          && (
                              <Button onClick={() => setModalByAction({ isOpen: true, action: TicketActionsEnum.RETURN_IN_WORK })}>
                                  Вернуть в работу
                              </Button>
                          )}
                    </div>
                </div>
            </div>
            {
                currentTicket.attachments.length > 0 && (
                    <>
                        <h3 className={styles.blockTitle}>Вложения</h3>
                        <ul className={styles.images}>
                            {currentTicket.attachments.map((item) => (
                                <li className={styles.imageItem} key={item.id}>
                                    <img
                                        onClick={() => handleZoom(item.file.url)}
                                        className={styles.image}
                                        src={item.file.url}
                                        alt={item.file.id}
                                    />
                                </li>
                            ))}
                        </ul>
                    </>
                )
            }
            {/* <div className={styles.blockWrapper}> */}
            {/*    <div className={styles.infoWrapper}> */}
            {/*        <AssignmentIcon /> */}
            {/*        <div className={styles.flexWrapper}> */}
            {/*            <span>Документ №1234</span> */}
            {/*            <span className={styles.blockLabel}>От 30.05.2023</span> */}
            {/*        </div> */}
            {/*        <button className={styles.button}> */}
            {/*            <DownloadIcon /> */}
            {/*        </button> */}
            {/*    </div> */}
            {/*    <div className={styles.infoWrapper}> */}
            {/*        <AssignmentIcon /> */}
            {/*        <div className={styles.flexWrapper}> */}
            {/*            <span>Документ №1234</span> */}
            {/*            <span className={styles.blockLabel}>От 30.05.2023</span> */}
            {/*        </div> */}
            {/*        <button className={styles.button}> */}
            {/*            <DownloadIcon /> */}
            {/*        </button> */}
            {/*    </div> */}
            {/* </div> */}
            <div className={styles.blockWrapper}>
                <div className={styles.infoWrapper}>
                    <CalendarTodayIcon />
                    <div className={styles.flexWrapper}>
                        <span className={styles.blockLabel}>Дата создания</span>
                        <span>{format(Date.parse(currentTicket.createdAt), 'dd.MM.yyyy в kk:mm')}</span>
                    </div>
                </div>
                <div className={styles.infoWrapper}>
                    <CalendarTodayIcon />
                    <div className={styles.flexWrapper}>
                        <span className={styles.blockLabel}>Срок для исполнителя</span>
                        <span>{formatDate(currentTicket.plannedEndedAt)}</span>
                    </div>
                </div>
                {/* <div className={styles.infoWrapper}> */}
                {/*    <CalendarTodayIcon /> */}
                {/*    <div className={styles.flexWrapper}> */}
                {/*        <span className={styles.blockLabel}>Срок для жителя</span> */}
                {/*        <span>{formatDate(currentTicket.plannedFinishedAt)}</span> */}
                {/*    </div> */}
                {/* </div> */}
            </div>
            {
                logs.length > 0 && (
                    <>
                        <h3 className={styles.blockTitle}>История</h3>
                        <div className={styles.blockWrapper}>
                            {
                                logs.map((log) => (
                                    <div key={log[0].id} className={styles.infoWrapper}>
                                        <HistoryItem logs={log} />
                                    </div>
                                ))
                            }
                        </div>
                    </>
                )
            }
            <h3 className={styles.blockTitle}>Оставить комментарий</h3>
            <CommentBox handleSubmit={onSubmitComment} />
            {isExecutorModalOpen && (
                <ModalEditExecutor
                    isOpen={isExecutorModalOpen}
                    onClose={onCloseExecutorModal}
                    ticketId={currentTicket.id}
                    currentExecutor={currentTicket.executor}
                />
            )}
            {isCategoryModalOpen && (
                <ModalEditCategory
                    isOpen={isCategoryModalOpen}
                    onClose={onCloseCategoryModal}
                    ticketId={currentTicket.id}
                    currentTicket={currentTicket}
                />
            )}
            {isPriorityModalOpen && (
                <ModalEditPriority
                    isOpen={isPriorityModalOpen}
                    onClose={onClosePriorityModal}
                    ticketId={currentTicket.id}
                    currentTicket={currentTicket}
                />
            )}
            {modalByAction.isOpen && modalByAction.action && (
                <ModalByAction
                    isOpen={modalByAction.isOpen}
                    onClose={onCloseModalByAction}
                    ticketId={currentTicket.id}
                    action={modalByAction.action}
                />
            )}
            {isReturnConfirmOpen && (
                <ModalConfirm
                    handler={returnTicket}
                    isOpen={isReturnConfirmOpen}
                    onClose={() => setIsReturnConfirmOpen(false)}
                    status={status}
                />
            )}
            {isCustomerModalOpen && (
                <ModalNewCustomer
                    roomId={currentTicket.room.id}
                    isOpen={isCustomerModalOpen}
                    onClose={onCloseCustomerModal}
                    isLoading={isLoading}
                    customerToEdit={currentTicket.customer}
                    updater={getTicketData}
                />
            )}
            {/* {isStartConfirmOpen && ( */}
            {/*    <ModalConfirm */}
            {/*        handler={startTicket} */}
            {/*        isOpen={isStartConfirmOpen} */}
            {/*        onClose={() => setIsStartConfirmOpen(false)} */}
            {/*        status={status} */}
            {/*    /> */}
            {/* )} */}
        </div>
    );
};
