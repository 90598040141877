import React, { useCallback, useLayoutEffect } from 'react';
import { useSelector } from 'react-redux';

import { useAppDispatch } from '../../../../redux/store';
import { EmptyTable } from '../../EmptyTable';
import { Preloader } from '../../../Client/ui/Preloader';
import { Street } from './Street';

import { DirectoryPage } from '../DirectoryPage';
import { deleteStreet, getStreets } from '../../../../redux/streets/asyncThunks';
import {
    getStreetsSelector,
    getIdToDeleteSelector,
    getIsLoadingSelector,
    getStatusSelector, getErrorsSelector,
} from '../../../../redux/streets/selectors';
import { getSettlements } from '../../../../redux/settlements/asyncThunks';

const prioritiesInfo = {
    path: '/bo/directories/streets/create',
    title: 'Улицы',
    text: 'улицу',
};

const prioritiesHeadings = ['Имя', 'Действия'];

export const Streets: React.FC = () => {
    const dispatch = useAppDispatch();
    const streets = useSelector(getStreetsSelector);
    const isLoading = useSelector(getIsLoadingSelector);
    const idToDelete = useSelector(getIdToDeleteSelector);
    const status = useSelector(getStatusSelector);
    const errors = useSelector(getErrorsSelector);

    useLayoutEffect(() => {
        dispatch(getSettlements());
        dispatch(getStreets());
    }, []);

    const modalHandler = useCallback(() => {
        dispatch(deleteStreet(idToDelete));
    }, [idToDelete]);

    return (
        <DirectoryPage
            headings={prioritiesHeadings}
            info={prioritiesInfo}
            modalHandler={modalHandler}
            errors={errors}
            status={status}
        >
            {isLoading
                ? <Preloader />
                : (
                    <ul>
                        {streets.length > 0
                            ? streets.map((i) => <Street key={i.id} street={i} />)
                            : <EmptyTable />}
                    </ul>
                )}
        </DirectoryPage>

    );
};
