import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../../axios';

export const login = createAsyncThunk<Record<string, string>, Record<string, string>>(
    'sign-in',
    async (body, { rejectWithValue }) => {
        try {
            const res = await axios.post('auth/login-by-username', body);
            return res.data.data;
        } catch (err: any) {
            return rejectWithValue(err.response.data);
        }
    },
);

export const getMe = createAsyncThunk(
    'getMe',
    async (body, { rejectWithValue }) => {
        try {
            const res = await axios.get('user/state');
            return res.data.data;
        } catch (err: any) {
            return rejectWithValue(err.response.data);
        }
    },
);
