import * as React from 'react';
import Box from '@mui/joy/Box';
import FormControl from '@mui/joy/FormControl';
import Textarea from '@mui/joy/Textarea';
import Button from '@mui/material/Button';
import SendIcon from '@mui/icons-material/Send';
import { ChangeEvent, useState } from 'react';

type componentProps = {
  handleSubmit: (value: string) => void;
}
export function CommentBox({ handleSubmit }: componentProps) {
    const onSendClick = () => {
        handleSubmit(value);
        setValue('');
    };

    const onChangeValue = (e: ChangeEvent<HTMLTextAreaElement>) => setValue(e.target.value);
    const [value, setValue] = useState<string>('');
    return (
        <FormControl>
            <Textarea
                placeholder="Напишите что-нибудь здесь…"
                value={value}
                onChange={onChangeValue}
                minRows={3}
                endDecorator={(
                    <Box
                        sx={{
                            display: 'flex',
                            gap: 'var(--Textarea-paddingBlock)',
                            pt: 'var(--Textarea-paddingBlock)',
                            borderTop: '1px solid',
                            borderColor: 'divider',
                            flex: 'auto',
                        }}
                    >

                        <Button variant="contained" sx={{ ml: 'auto' }} onClick={onSendClick}>
                            <SendIcon />
                        </Button>
                    </Box>
                )}
                sx={{
                    minWidth: 300,
                }}
            />
        </FormControl>
    );
}
