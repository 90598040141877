import React, { useState } from 'react';
import cn from 'classnames';
import styles from './Paginator.module.css';
import { PaginatorProps } from './Paginator.props';
import { ReactComponent as MoreIcon } from '../../../../assets/img/moreIcon.svg';

export const Paginator: React.FC<PaginatorProps> = ({
    totalItems = 1,
    totalPages = 1,
    pageSize = 10,
    currentPage = 1,
    setCurrentPage,
    portionSize = 10,
}) => {
    const pagesCount = totalPages || Math.ceil(totalItems / pageSize);
    const pages = [];
    for (let i = 1; i < pagesCount + 1; i += 1) {
        pages.push(i);
    }

    const portionCount = Math.ceil(pagesCount / portionSize);
    const [portionNumber, setPortionNumber] = useState(1);
    const leftPortionNumber = (portionNumber - 1) * portionSize + 1;
    const rightPortionNumber = portionNumber * portionSize;

    return (
        <div className={styles.pages}>
            <button
                onClick={() => setPortionNumber(portionNumber - 1)}
                className={cn(styles.prevButton, {
                    [styles.disabled]: portionNumber <= 1,
                })}
                disabled={portionNumber <= 1}
            >
                <MoreIcon className={styles.prevIcon} />
            </button>
            {pages
                .filter((page) => page >= leftPortionNumber && page <= rightPortionNumber)
                .map((page) => (
                    <span
                        key={page}
                        className={cn(styles.page, {
                            [styles.selectedPage]: page === currentPage,
                        })}
                        onClick={() => setCurrentPage(page)}
                    >
                        {page}
                    </span>
                ))}
            <button
                onClick={() => setPortionNumber(portionNumber + 1)}
                className={cn(styles.nextButton, {
                    [styles.disabled]: portionCount <= portionNumber,
                })}
                disabled={portionCount <= portionNumber}
            >
                <MoreIcon className={styles.nextIcon} />
            </button>
        </div>
    );
};
