import React, { useCallback, useLayoutEffect } from 'react';
import { useSelector } from 'react-redux';

import { useAppDispatch } from '../../../../redux/store';
import { EmptyTable } from '../../EmptyTable';
import { Preloader } from '../../../Client/ui/Preloader';
import { Settlement } from './Settlement';

import { DirectoryPage } from '../DirectoryPage';
import {
    getSettlementsSelector,
    getIsLoadingSelector,
    getIdToDeleteSelector,
    getErrorsSelector, getStatusSelector,
} from '../../../../redux/settlements/selectors';
import { deleteSettlement, getSettlements } from '../../../../redux/settlements/asyncThunks';

const prioritiesInfo = {
    path: 'create',
    title: 'Населённые пункты',
    text: 'пункт',
};

const prioritiesHeadings = ['Имя', 'Действия'];

export const Settlements: React.FC = () => {
    const dispatch = useAppDispatch();
    const settlements = useSelector(getSettlementsSelector);
    const isLoading = useSelector(getIsLoadingSelector);
    const idToDelete = useSelector(getIdToDeleteSelector);
    const errors = useSelector(getErrorsSelector);
    const status = useSelector(getStatusSelector);

    useLayoutEffect(() => {
        dispatch(getSettlements());
    }, []);

    const modalHandler = useCallback(() => {
        dispatch(deleteSettlement(idToDelete));
    }, [idToDelete]);

    return (
        <DirectoryPage
            headings={prioritiesHeadings}
            info={prioritiesInfo}
            modalHandler={modalHandler}
            errors={errors}
            status={status}
        >
            {isLoading
                ? <Preloader />
                : (
                    <ul>
                        {settlements.length > 0
                            ? settlements.map((i) => <Settlement key={i.id} settlement={i} />)
                            : <EmptyTable />}
                    </ul>
                )}
        </DirectoryPage>

    );
};
