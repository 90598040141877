import React, { useState } from 'react';
import cn from 'classnames';
import { SortButton } from '../../../../components/Panel/Buttons/SortButton';
import { Button } from '../../../../components/Client/ui/Button';
import { SectionTitle } from '../../../../components/Client/ui/SectionTitle';
import { ReactComponent as PlusIcon } from '../../../../assets/img/plus.svg';
import styles from './TicketsPage.module.css';
import { Ticket } from '../Ticket/Ticket';
import { Paginator } from '../../../../components/Client/ui/Paginator/Paginator';
import { EmptyTickets } from '../EmptyTickets/EmptyTickets';
import { Modal } from '../../../../components/Client/ui/Modal/Modal';
import { NewTicketModal } from '../NewTicketModal/NewTicketModal';
import { TicketModal } from '../TicketModal/TicketModal';

export const TicketsPage: React.FC = () => {
    const [isActiveBtn, setIsActiveBtn] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [isEmpty, setIsEmpty] = useState<boolean>(false);

    const onSortButtonClick = () => {
        setIsActiveBtn((prevState) => !prevState);
    };

    if (isEmpty) {
        return <EmptyTickets />;
    }

    return (
        <main className="content">
            <div className={styles.buttonWrapper}>
                <SectionTitle>Заявки</SectionTitle>
                <Button appearance="primary" className={styles.button}>
                    <PlusIcon className={styles.plusIcon} />
                    Создать заявку
                </Button>
            </div>
            <div className={styles.table}>
                <ul className={styles.headings}>
                    <li className={styles.heading}>
                        № Заявки
                    </li>
                    <li className={styles.heading}>
                        Дата
                        <SortButton
                            isActive={isActiveBtn}
                            onClick={onSortButtonClick}
                        />
                    </li>
                    <li className={styles.heading}>
                        Статус
                        <SortButton />
                    </li>
                    <li className={cn(styles.heading, styles.headingAppeal)}>
                        Обращение
                        <SortButton />
                    </li>
                </ul>
                <ul className={styles.tickets}>
                    {/* <Ticket id="1" number="12314254" date="12.09.2022" status="Выполнено" appeal="Уже очень долго не работает лифт в 3 подъезде Уже очень долговато" /> */}
                    {/* <Ticket id="2" number="12314254" date="12.09.2022" status="Выполнено" appeal="Уже очень долго не работает лифт в 3 подъезде" /> */}
                    {/* <Ticket id="3" number="12314254" date="12.09.2022" status="Выполнено" appeal="Уже очень долго не работает лифт в 3 подъезде Уже очень долговато" /> */}
                    {/* <Ticket id="4" number="12314254" date="12.09.2022" status="Выполнено" appeal="Уже очень долго не работает лифт в 3 подъезде" /> */}
                    {/* <Ticket id="5" number="12314254" date="12.09.2022" status="Выполнено" appeal="Уже очень долго не работает лифт в 3 подъезде" /> */}
                    {/* <Ticket id="6" number="12314254" date="12.09.2022" status="Выполнено" appeal="Уже очень долго не работает лифт в 3 подъезде Уже очень долговато" /> */}
                    {/* <Ticket id="7" number="12314254" date="12.09.2022" status="Выполнено" appeal="Уже очень долго не работает лифт в 3 подъезде Уже очень долговато" /> */}
                </ul>
                <Paginator currentPage={currentPage} pageSize={10} setCurrentPage={setCurrentPage} totalItems={150} />
            </div>
            {/* <Modal> */}
            {/*    <NewTicketModal title="Новая заявка" text="Выберите тему обращения" /> */}
            {/* </Modal> */}
            {/* <Modal> */}
            {/*    <TicketModal /> */}
            {/* </Modal> */}
        </main>
    );
};
