import { createAsyncThunk } from '@reduxjs/toolkit';
import { Category } from './types';
import axios from '../../axios';
import { categoryActions } from './slice';

type Data = {
  id: string;
  body: Category;
}
export const getCategories = createAsyncThunk<Category[]>(
    'getCategories',
    async (body, { dispatch }) => {
        await dispatch(categoryActions.resetCategory());
        const res = await axios.get('helpdesk/category');
        return res.data.data;
    },
);

export const createCategory = createAsyncThunk<Category, Category>(
    'createCategory',
    async (body, { rejectWithValue }) => {
        try {
            const res = await axios.post('helpdesk/category', body);
            return res.data;
        } catch (err: any) {
            return rejectWithValue(err.response.data);
        }
    },
);

export const deleteCategory = createAsyncThunk<string, string | null>(
    'deleteCategory',
    async (id, { dispatch, rejectWithValue }) => {
        try {
            const res = await axios.delete(`helpdesk/category/${id}`);
            dispatch(getCategories());
            return res.data;
        } catch (err: any) {
            return rejectWithValue(err.response.data);
        }
    },
);

export const updateCategory = createAsyncThunk<Category, Data>(
    'updateCategory',
    async ({ id, body }, { rejectWithValue }) => {
        try {
            const res = await axios.patch(`helpdesk/category/${id}`, body);
            return await res.data.data;
        } catch (err: any) {
            return rejectWithValue(err.response.data);
        }
    },
);
export const getCategoryById = createAsyncThunk<Category, string>(
    'getCategoryById',
    async (id) => {
        const res = await axios.get(`helpdesk/category/${id}`);
        return res.data.data;
    },
);
