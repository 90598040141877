import HomeIcon from '@mui/icons-material/Home';
import { format } from 'date-fns';
import { ru } from 'date-fns/locale';
import { CalendarMonth } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import PersonIcon from '@mui/icons-material/Person';
import { ActiveTicketByAddressItem } from '../../../../../redux/tickets/types';
import css from './ActiveTicketByAddress.module.css';
import { formatPhoneNumber } from '../../../../../utils/helpers/formatPhoneNumber';

interface ActiveTicketByAddressProps {
  ticket: ActiveTicketByAddressItem;
}

export const ActiveTicketByAddress = ({ ticket }: ActiveTicketByAddressProps) => (
    <li>
        <Link to={`/bo/ticket/${ticket.id}`} className={css.item} target="_blank">
            <div className={css.number}>
                Номер заявки:
                {' '}
                {ticket.number}
            </div>
            <div className={css.category}>{ticket.category.name}</div>
            <div className={css.parentCategory}>{ticket.category.parent?.name}</div>
            <div
                className={css.priority}
                style={{ backgroundColor: ticket.priority.backgroundColor, color: ticket.priority.textColor }}
            >
                {ticket.priority.name}
            </div>
            <div
                className={css.status}
                style={{ backgroundColor: ticket.status.backgroundColor, color: ticket.status.textColor }}
            >
                {ticket.status.name}
            </div>
            <div className={css.address}>
                <HomeIcon />
                {ticket.addressString}
            </div>
            <div className={css.date}>
                <CalendarMonth />
                {format((Date.parse(ticket.createdAt)), 'dd MMMM, hh:mm', { locale: ru })}
            </div>
            {ticket.customer && (
                <div className={css.customer}>
                    <PersonIcon />
                    {`${ticket.customer?.fullName}, ${formatPhoneNumber(ticket.customer.phone)}`}
                </div>
            )}
        </Link>
    </li>
);
