import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { initialStateI } from './types';
import { StatusEnum } from '../../interfaces/StatusEnum';
import {
    createSettlement, deleteSettlement, getSettlementById, getSettlements, updateSettlement,
} from './asyncThunks';

const initialState: initialStateI = {
    settlement: {
        name: null,
        id: null,
    },
    settlements: [],
    status: null,
    errors: [],
    formErrors: [],
    isLoading: false,
    settlementIdToDelete: null,
};

export const settlementSlice = createSlice({
    name: 'settlements',
    initialState,
    reducers: {
        setIdToDelete(state, action) {
            state.settlementIdToDelete = action.payload;
        },
        resetSettlement(state) {
            state.settlement.id = null;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getSettlements.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(getSettlements.fulfilled, (state, action) => {
            state.settlements = action.payload;
            state.errors = [];
            state.isLoading = false;
            state.status = null;
        });
        builder.addCase(getSettlements.rejected, (state) => {
            state.isLoading = false;
        });
        builder.addCase(createSettlement.pending, (state) => {
            state.status = StatusEnum.PENDING;
            state.isLoading = true;
        });
        builder.addCase(createSettlement.fulfilled, (state) => {
            state.status = StatusEnum.SUCCESS;
            state.formErrors = [];
            state.isLoading = false;
        });
        builder.addCase(createSettlement.rejected, (state, action: PayloadAction<any>) => {
            state.status = StatusEnum.ERROR;
            state.formErrors = action.payload.errors;
            state.isLoading = false;
        });
        builder.addCase(updateSettlement.pending, (state) => {
            state.status = StatusEnum.PENDING;
            state.isLoading = true;
        });
        builder.addCase(updateSettlement.fulfilled, (state, action) => {
            state.status = StatusEnum.SUCCESS;
            state.settlement = action.payload;
            state.isLoading = false;
            state.formErrors = [];
        });
        builder.addCase(updateSettlement.rejected, (state, action: PayloadAction<any>) => {
            state.status = StatusEnum.ERROR;
            state.formErrors = action.payload.errors;
            state.isLoading = false;
        });
        builder.addCase(getSettlementById.fulfilled, (state, action) => {
            state.settlement = action.payload;
        });
        builder.addCase(getSettlementById.rejected, (state) => {
            state.status = StatusEnum.ERROR;
        });
        builder.addCase(deleteSettlement.pending, (state) => {
            state.status = StatusEnum.PENDING;
        });
        builder.addCase(deleteSettlement.fulfilled, (state) => {
            state.status = StatusEnum.SUCCESS;
            state.errors = [];
        });
        builder.addCase(deleteSettlement.rejected, (state, action: PayloadAction<any>) => {
            state.status = StatusEnum.ERROR;
            state.errors = action.payload.errors;
        });
    },
});

export const { reducer: settlementReducer, actions: settlementActions } = settlementSlice;
