import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { TicketsPage } from './TicketsPage/TicketsPage';
import { TicketFull } from './TicketFull/TicketFull';

export const Tickets = () => (
    <Routes>
        <Route path="/" element={<TicketsPage />} />
        <Route path="/fullTicket" element={<TicketFull />} />
    </Routes>
);
