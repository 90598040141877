import React from 'react';
import { useNavigate } from 'react-router-dom';
import DeleteIcon from '@mui/icons-material/Delete';
import { DepartmentProps } from './Department.props';
import { useAppDispatch } from '../../../../redux/store';
import { directoryActions } from '../../../../redux/directories/slice';
import { departmentActions } from '../../../../redux/departments/slice';
import { getDepartmentById } from '../../../../redux/departments/asyncThunks';
import { EditButton } from '../../Buttons/EditButton';
import { RedButton } from '../../Buttons/RedButton';
import styles from '../DirectoryItem/DirectoryItem.module.css';

export const Department: React.FC<DepartmentProps> = ({ department }) => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const handleDeleteButtonClick = (id?: string | null) => {
        dispatch(directoryActions.setIsModalOpen());
        dispatch(departmentActions.setIdToDelete(id));
    };

    const handleEditButtonClick = (id? : string | null) => {
        if (id) {
            navigate(`${id}/update`);
        }
    };

    return (
        <li className={styles.item}>
            <span className={styles.name}>{department?.fullName || ''}</span>
            <div className={styles.buttonWrapper}>
                <EditButton onClick={() => handleEditButtonClick(department?.id)} />
                <RedButton onClick={() => handleDeleteButtonClick(department?.id)}>
                    <DeleteIcon fontSize="small" />
                </RedButton>
            </div>
        </li>
    );
};
