import { useSelector } from 'react-redux';
import { Grow } from '@mui/material';
import { getIsActiveZoomerSelector, getSrcZoomerSelector } from '../../../redux/photoZoomer/selectors';
import css from './ImageZoomer.module.css';
import { useAppDispatch } from '../../../redux/store';
import { photoZoomActions } from '../../../redux/photoZoomer/slice';

export const ImageZoomer = () => {
    const dispatch = useAppDispatch();
    const isActive = useSelector(getIsActiveZoomerSelector);
    const src = useSelector(getSrcZoomerSelector);

    const handleClose = () => {
        dispatch(photoZoomActions.resetZoom());
    };

    if (isActive) {
        return (
            <Grow in={isActive}>
                <div className={css.overlay} onClick={handleClose}>
                    <img src={src} alt="" className={css.img} onClick={(e) => e.stopPropagation()} />
                </div>
            </Grow>
        );
    }

    return <></>;
};
