import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper';
import { SectionTitle } from '../../../components/Client/ui/SectionTitle';
import { CategoryWithImage } from '../../../components/Client/CategoryWithImage/CategoryWithImage';
import BulbImage from '../../../assets/img/categories/bulb.svg';
import AirConditionerImage from '../../../assets/img/categories/air-conditioner.svg';
import PlumbingImage from '../../../assets/img/categories/plumbing.svg';
import KeyImage from '../../../assets/img/categories/key.svg';
import FurnitureImage from '../../../assets/img/categories/furniture.svg';
import { Category } from '../../../components/Client/Category/Category';
import { Service } from '../../../components/Client/Service/Service';
import { Ticket } from '../../../components/Client/Ticket/Ticket';
import { Button } from '../../../components/Client/ui/Button';
import { ReactComponent as MoreIcon } from '../../../assets/img/moreIcon.svg';
import styles from './Services.module.css';
import 'swiper/css';
import 'swiper/css/pagination';

const tickets = [1, 1, 1, 4]; // временно для проверки верстки

export const Services = () => (
    <main className="content">
        <SectionTitle>Услуги</SectionTitle>
        {tickets.length > 0 && (
            <>
                <h2 className={styles.categoriesTitle}>Мои заявки</h2>
                <ul className={styles.ticketsList}>
                    <Ticket title="Смена ванн" date="22.09.2022" text="Демонтаж старой ванны, монтаж новой ванны" badgeText="Ожидание" />
                    <Ticket title="Смена ванн" date="22.09.2022" text="Демонтаж старой ванны, монтаж новой ванны" badgeText="Выполняется" />
                    <Ticket title="Смена ванн" date="22.09.2022" text="Демонтаж старой ванны, монтаж новой ванны" badgeText="Выполнено" />
                </ul>
                {tickets.length > 3 && (
                    <Button appearance="outlined" className={styles.moreButton}>
                        Загрузить еще
                        <MoreIcon />
                    </Button>
                )}
            </>
        )}
        <Swiper
            pagination={{
                clickable: true,
            }}
            spaceBetween={8}
            slidesPerView={4}
            modules={[Pagination]}
            className={styles.swiper}
        >
            <SwiperSlide>
                <Service title="Замена счетчиков воды" />
            </SwiperSlide>
            <SwiperSlide>
                <Service title="Замена счетчиков воды" />
            </SwiperSlide>
            <SwiperSlide>
                <Service title="Замена счетчиков воды" />
            </SwiperSlide>
            <SwiperSlide>
                <Service title="Замена счетчиков воды" />
            </SwiperSlide>
            <SwiperSlide>
                <Service title="Замена счетчиков воды" />
            </SwiperSlide>
            <SwiperSlide>
                <Service title="Замена счетчиков воды" />
            </SwiperSlide>
            <SwiperSlide>
                <Service title="Замена счетчиков воды" />
            </SwiperSlide>
            <SwiperSlide>
                <Service title="Замена счетчиков воды" />
            </SwiperSlide>
        </Swiper>
        <h2 className={styles.categoriesTitle}>Популярные категории</h2>
        <ul className={styles.popularList}>
            <CategoryWithImage
                title="Электрика"
                text="Проводка, свет, выключатели и многое другое"
                image={BulbImage}
            />
            <CategoryWithImage
                title="Кондиционер"
                text="Установка и обслуживание систем"
                image={AirConditionerImage}
            />
            <CategoryWithImage
                title="Сантехника"
                text="Подтеки воды или установка новой стиральной машины и др."
                image={PlumbingImage}
            />
            <CategoryWithImage
                title="Ключи"
                text="Изготовление"
                image={KeyImage}
            />
            <CategoryWithImage
                title="Сборка мебели"
                text="Поможем собрать новую мебель"
                image={FurnitureImage}
            />
        </ul>
        <h2 className={styles.categoriesTitle}>Другое</h2>
        <ul className={styles.otherList}>
            <Category title="Санитарно технические работы" text="Приборы, трубопровод и др." />
            <Category title="Электрика" text="Проводка, освещение, розетки и др." />
            <Category title="Общестроительные работы" text="Малярные, штукатурные работы и др." />
            <Category title="Общестроительные работы" text="Малярные, штукатурные работы и др." />
        </ul>
    </main>
);
