import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { HomeProps } from './Home.props';
import { Account } from './Account/Account';
import { NewsSection } from './NewsSection/NewsSection';
import { Profile } from './Profile/Profile';
// import styles from './Home.module.css';

export const Home: React.FC<HomeProps> = () => (
    <Routes>
        <Route
            path="/"
            element={(
                <>
                    <Account />
                    <NewsSection />
                </>
            )}
        />
        <Route
            path="/profile"
            element={(
                <Profile />
            )}
        />
    </Routes>

);
