import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../../axios';
import { FileI } from '../categories/types';

interface DataI {
  file: File
}
export const uploadFile = createAsyncThunk<FileI, DataI>(
    'uploadFile',
    async (body, { rejectWithValue }) => {
        try {
            const res = await axios.post('storage/file/upload', body, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            return res.data.data;
        } catch (err: any) {
            return rejectWithValue(err.response.data);
        }
    },
);
