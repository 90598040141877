import React from 'react';
import cn from 'classnames';
import EditIcon from '@mui/icons-material/Edit';
import { EditButtonProps } from './EditButton.props';
import styles from './EditButton.module.css';

export const EditButton: React.FC<EditButtonProps> = ({ className, ...props }) => (
    <button
        type="button"
        className={cn(styles.button, className)}
        {...props}
    >
        <EditIcon fontSize="small" />
    </button>
);
