import React from 'react';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import { Link } from 'react-router-dom';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Drawer from '@mui/material/Drawer';
import { sideBarGeoLinks } from '../../../utils/data/sideBar/sideBarGeoLinks';
import { sideBarEmployeeLinks } from '../../../utils/data/sideBar/sideBarEmployeeLinks';
import { sideBarSettingsLinks } from '../../../utils/data/sideBar/sideBarSettingsLinks';

const drawerWidth = 210;

type componentProps = {
  location: 'geo' | 'employee' | 'settings';
}

export const Sidebar = ({ location }: componentProps) => {
    const sideBarData = location === 'geo' ? sideBarGeoLinks : location === 'employee' ? sideBarEmployeeLinks : sideBarSettingsLinks;
    return (
        <nav>
            <Drawer
                sx={{
                    width: drawerWidth,
                    flexShrink: 0,
                    '& .MuiDrawer-paper': {
                        width: drawerWidth,
                        boxSizing: 'border-box',
                    },
                }}
                variant="permanent"
                anchor="left"
            >
                <Toolbar />
                <List>
                    {sideBarData.map((i) => (
                        <Link to={i.path} key={i.name}>
                            <ListItem disablePadding>
                                <ListItemButton>
                                    <ListItemIcon>
                                        {i.icon}
                                    </ListItemIcon>
                                    <ListItemText primary={i.name} />
                                </ListItemButton>
                            </ListItem>
                        </Link>
                    ))}
                </List>
            </Drawer>
        </nav>
    );
};
