import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { useSelector } from 'react-redux';

import { useParams } from 'react-router-dom';
import { useAppDispatch } from '../../../../redux/store';
import { ErrorsAlert } from '../../ErrorAlert';
import { ButtonPrimary } from '../../Buttons/ButtonPrimary';
import { useSuccessNavigate } from '../../../../utils/hooks/useSuccessNavigate';
import { DropDownMenu } from '../../DropDownMenu';
import {
    getStatusSelector, getIsLoadingSelector, getRuleSelector, getFormErrorsSelector,
} from '../../../../redux/assignmentRules/selectors';
import { getDepartmentsSelector } from '../../../../redux/departments/selectors';
import { Preloader } from '../../../Client/ui/Preloader';
import { getDepartments } from '../../../../redux/departments/asyncThunks';
import { getCategories } from '../../../../redux/categories/asyncThunks';
import { getCategoriesSelector } from '../../../../redux/categories/selectors';
import { createRule } from '../../../../redux/assignmentRules/asyncThunks';

export const AssignmentRuleForm = () => {
    const { id } = useParams();
    const isEditing = Boolean(id);
    const errors = useSelector(getFormErrorsSelector);
    const status = useSelector(getStatusSelector);
    const isLoading = useSelector(getIsLoadingSelector);
    const rule = useSelector(getRuleSelector);
    const departments = useSelector(getDepartmentsSelector);
    const categories = useSelector(getCategoriesSelector);
    const dispatch = useAppDispatch();
    useSuccessNavigate('/bo/settings/ticket-assignment-rule', status);
    const [ruleValues, setRuleValues] = useState<Record<string, string | null>>({
        departmentId: null,
        categoryId: null,
    });

    useEffect(() => {
        // if (isEditing && id) {
        //     dispatch(getRuleById(id));
        // }
        dispatch(getDepartments());
        dispatch(getCategories());
    }, []);

    // useEffect(() => {
    //     if (isEditing) {
    //         setRuleValues(rule);
    //     }
    // }, [rule]);

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const body = {
            categoryId: ruleValues.categoryId,
            departmentId: ruleValues.departmentId,
        };
        // if (isEditing && id) {
        //     dispatch(updateHouse({ id, body }));
        // } else {
        dispatch(createRule(body));
        // }
    };

    if (isEditing && !rule.id) {
        return <Preloader />;
    }

    return (
        <Box
            component="form"
            onSubmit={handleSubmit}
            className="form"
            noValidate
        >
            {errors.length > 0 && <ErrorsAlert errors={errors} />}
            <DropDownMenu
                label="Выберите категорию"
                required
                value={ruleValues.categoryId || ''}
                handleChange={(e) => setRuleValues({ ...ruleValues, categoryId: e.target.value })}
                items={categories?.filter((i) => i.parentId)}
            />
            <DropDownMenu
                label="Выберите департамент"
                required
                value={ruleValues.departmentId || ''}
                handleChange={(e) => setRuleValues({ ...ruleValues, departmentId: e.target.value })}
                items={departments}
            />
            <ButtonPrimary isLoading={isLoading} isEditing={isEditing} />
        </Box>
    );
};
