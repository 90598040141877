import React, { useCallback, useLayoutEffect } from 'react';
import { useSelector } from 'react-redux';

import { useAppDispatch } from '../../../../redux/store';
import { EmptyTable } from '../../EmptyTable';
import { Preloader } from '../../../Client/ui/Preloader';
import { Department } from './Department';

import { DirectoryPage } from '../DirectoryPage';
import {
    getDepartmentsSelector,
    getIsLoadingSelector,
    getIdToDeleteSelector,
    getErrorsSelector, getStatusSelector,
} from '../../../../redux/departments/selectors';
import { deleteDepartment, getDepartments } from '../../../../redux/departments/asyncThunks';

const departmentsInfo = {
    path: '/bo/directories/departments/create',
    title: 'Департаменты',
    text: 'департамент',
};

const departmentsHeadings = ['Имя', 'Действия'];

export const Departments: React.FC = () => {
    const dispatch = useAppDispatch();
    const departments = useSelector(getDepartmentsSelector);
    const isLoading = useSelector(getIsLoadingSelector);
    const idToDelete = useSelector(getIdToDeleteSelector);
    const errors = useSelector(getErrorsSelector);
    const status = useSelector(getStatusSelector);

    useLayoutEffect(() => {
        dispatch(getDepartments());
    }, []);

    const modalHandler = useCallback(() => {
        dispatch(deleteDepartment(idToDelete));
    }, [idToDelete]);

    return (
        <DirectoryPage
            headings={departmentsHeadings}
            info={departmentsInfo}
            modalHandler={modalHandler}
            errors={errors}
            status={status}
        >
            {isLoading
                ? <Preloader />
                : (
                    <ul>
                        {departments.length > 0
                            ? departments.map((i) => <Department key={i.id} department={i} />)
                            : <EmptyTable />}
                    </ul>
                )}
        </DirectoryPage>

    );
};
